import axiosInstance from "../services/AxiosInstance";
import axiosRootInstance from "../services/AxiosRootInstance";
import swal from "sweetalert";

export function getDevice() {
  return axiosInstance.get("/agentassignlist");
}
export function Get_other_office_list_Action() {
  return axiosInstance.get("/get_other_office_list");
}
export function Get_manager_office_list_Action() {
  return axiosInstance.get("/get_manager_office_list");
}
export function salessummaryaction(param) {
  return axiosInstance.get("/manager_sales_report?page=" + param.page);
}
export function Detailsummaryaction(param) {
  return axiosRootInstance.get(
    `/api/v1/detailed_inventory_report?keyword=${
      param.search_text ?? ""
    }&retailer_id=${param.retailer_id ?? ""}&agent_id=${
      param.agent_id ?? ""
    }&office_id=${param.office_id ?? ""}&status=${
      param.status ?? ""
    }&date_range_start=${param.date_range_start ?? ""}&date_range_end=${
      param.date_range_end ?? ""
    }&sort=${param.sort ?? ""}&sort_by=${param.sort_by ?? ""}&page=${
      param.page ?? 1
    }`
  );
}
export function salesagentaction() {
  return axiosInstance.get("/sales_by_agents");
}
export function salesofficeaction() {
  return axiosInstance.get("/sales_by_office");
}

export function InventoryOrderlist(params) {
  const url = `/get_inventory_request_order_list?office_id=${
    params.office_id ?? ""
  }&status=${params.status ?? ""}&approval_status=${
    params.approval_status ?? ""
  }&filter=${params.sort ?? ""}&sort_by=${params.sort_by ?? ""}&sort=${
    params.sort ?? ""
  }&keyword=${params.search_text ?? ""}&page=${params.page}`;
  console.log("url", url);
  return axiosInstance.get(url);
}
export function exportInventoryOrder(params) {
  const url = `/api/v1/inventory_request_orders_export?office_id=${
    params.office_id ?? ""
  }&status=${params.status ?? ""}&approval_status=${
    params.approval_status ?? ""
  }&keyword=${params.search_text ?? ""}`;
  console.log("url", url);
  return axiosRootInstance.get(url);
}
export function agent_list_for_office(postData) {
  return axiosInstance.post("/agent_list_for_office", postData);
}
export function inventoryorder(postData) {
  return axiosInstance.post("/create_inventory_request_order", postData);
}
export function inventoryorderlog(postData) {
  return axiosRootInstance.get(
    `/api/v1/inventory_request_order_log/${postData}`
  );
}
export function rmaRequestAccept(postData) {
  return axiosInstance.post("/accept_reject_rma_device", postData);
}
export function transfer_devices_to_other_office(postData) {
  return axiosInstance.post("/transfer_devices_to_other_office", postData);
}
export function assigndevice(postData) {
  return axiosInstance.post("/assigndevice", postData);
}
export function getTransaction() {
  return axiosInstance.get("/transaction_history");
}
export function RMARequest() {
  return axiosInstance.get("/get_pending_rma_device_list");
}
export function ApprovedRMARequest() {
  return axiosInstance.get("/get_approved_rma_device_list");
}
export function agent_list() {
  return axiosInstance.get("/manager_agents_list");
}

export function acceptDevice(postData) {
  return axiosInstance.post("/accept", postData);
}

export function transferDevice(postData) {
  return axiosInstance.post("/transfer_device", postData);
}
export function transferRequest() {
  return axiosInstance.get("/transfer_request");
}
export function receiveDeviceStatus(postData) {
  return axiosInstance.post("/managerdevicelist", postData);
}
export function receiveDeviceStatusUpdate(postData) {
  return axiosInstance.post("/manager_accept_selected_cartons", postData);
}
export function transferRequestAccept(postData) {
  return axiosInstance.post("/accept_transfer", postData);
}

export function scanDevice(postData) {
  return axiosInstance.post("/agentscandevices", postData);
}

export function pickupDevice(postData) {
  return axiosInstance.post("/agentpickup", postData);
}

export function inventoryDevice(params) {
  var url = `/managerinventorylist_optimisation`;
  return axiosInstance.get(url);
}

export function rmaInitiate(params) {
  console.log(params);
  var url = `/get_device_list_to_initiate_rma?page=${params.page}`;
  return axiosInstance.get(url);
}

export function rmaPending(params) {
  console.log(params);
  var url = `/get_pending_rma_device_list?page=${params.page}&filter=${
    params.sort ?? ""
  }&sort_by=${params.sort_by ?? ""}&sort=${params.sort ?? ""}&keyword=${
    params.search_text ?? ""
  }&office_id=${params.office_id ?? ""}`;
  if (
    params !== undefined &&
    params !== null &&
    params.status !== undefined &&
    params.status !== null
  ) {
    url += `&status=${params?.status}`;
  }
  console.log(`url`, url);
  return axiosInstance.get(url);
  // return axiosInstance.get("/get_pending_rma_device_list?page=" + params.page);
}

export function exportRmaPending(params) {
  console.log(params);
  var url = `/api/v1/manager/pending_rma_device_list_export
?office_id=${params.office_id ?? ""}`;
  console.log(`url`, url);
  return axiosRootInstance.get(url);
  // return axiosInstance.get("/get_pending_rma_device_list?page=" + params.page);
}

export function rmaSubmit(params) {
  console.log(params);
  var url = `/get_approved_rma_device_list?page=${params.page}&filter=${
    params.sort ?? ""
  }&sort_by=${params.sort_by ?? ""}&sort=${params.sort ?? ""}&keyword=${
    params.search_text ?? ""
  }`;
  if (
    params !== undefined &&
    params !== null &&
    params.office_id !== undefined &&
    params.office_id !== null
  ) {
    url += `&office_id=${params?.office_id}`;
  }
  console.log(`url`, url);
  return axiosInstance.get(url);
  // return axiosInstance.get("/get_approved_rma_device_list?page=" + params.page);
}

export function exportRMA(params) {
  console.log(params);
  var url = `/api/v1/manager/approved_rma_device_list_export?&office_id=${
    params?.office_id ?? ""
  }`;
  return axiosRootInstance.get(url);
}

export function rmaApproved(params) {
  var url = `/get_rma_orders_list?status=${
    params.status ?? ""
    // }&ibo_marketer_id=${params.ibo_marketer_id ?? ""}&retailer_id=${params.retailer_id ?? ""
  }&filter=${params.sort ?? ""}&sort_by=${params.sort_by ?? ""}&sort=${
    params.sort ?? ""
  }&keyword=${params.search_text ?? ""}&page=${params.page}`;
  console.log("url", url);
  return axiosInstance.get(url);
}

export function exportRmaApproved(params) {
  var url = `/api/v1/get_rma_orders_export?status=${params.status ?? ""}`;
  console.log("url", url);
  return axiosRootInstance.get(url);
}

export function rmaReasons() {
  return axiosRootInstance.get("/api/v1/reference/rma_reasons");
}

export function iboMarketers(retailer) {
  return axiosRootInstance.get("/api/v1/reference/ibo_marketers/" + retailer);
}

export function RMADeviceSend(postData) {
  return axiosInstance.post("/rma_device_request", postData);
}

export function AcceptRejectRMASend(postData) {
  return axiosInstance.post("/accept_reject_rma_device", postData);
}

export function SubmitRMASend(postData) {
  return axiosInstance.post("/submit_rma_order", postData);
}

export function RmaReportDevice(params) {
  var url = `/get_rma_orders_list?filter=${params.sort ?? ""}&sort_by=${
    params.sort_by ?? ""
  }&sort=${params.sort ?? ""}&keyword=${params.search_text ?? ""}`;
  if (
    params !== undefined &&
    params !== null &&
    params.status !== undefined &&
    params.status !== null
  ) {
    url += `&status=${params?.status}`;
  }
  console.log(`url`, url);
  return axiosInstance.get(url);
}

export function exportRmaReportDevice(params) {
  var url = `/api/v1/manager/get_rma_orders_export?&status=${
    params?.status ?? ""
  }`;
  return axiosRootInstance.get(url);
}

export function OrderApprovalStatus() {
  return axiosRootInstance.get(
    "/api/v1/reference/inventory_order_approval_status"
  );
}

export function OrderStatus() {
  return axiosRootInstance.get("/api/v1/reference/inventory_order_status");
}

export function RmaOrderStatus() {
  return axiosRootInstance.get("/api/v1/reference/rma_order_status");
}

export function RmaDeviceStatus() {
  return axiosRootInstance.get("/api/v1/reference/rma_device_status");
}

export function receiveinventoryDevice() {
  return axiosInstance.get("/managerjob");
}
export function AgentList(device) {
  return axiosInstance.get(
    `/agentlist_with_pagination?page=${device.page}&agent_id=${
      device.agent_id ?? ""
    }`
  );
}
export function exportAgentList(agent_id) {
  return axiosRootInstance.get(
    `/api/v1/manager_inventory_summary_reports_export/agent?agent_id=${
      agent_id ?? ""
    }`
  );
}
export function OfficeList(device) {
  console.log(device);
  return axiosInstance.get(
    `/inventory_summary_for_manager_with_pagination?page=${
      device.page
    }&office_id=${device.office_id ?? ""}`
  );
}
export function exportOfficeList(office_id) {
  console.log(office_id);
  return axiosRootInstance.get(
    `/api/v1/manager_inventory_summary_reports_export/office?office_id=${
      office_id ?? ""
    }`
  );
}
export function documentDevice(postData) {
  return axiosInstance.get("/get_documents", postData);
}
export function documentPostDevice(postData) {
  return axiosInstance.post("/save_document_data", postData);
}
export function RMAimageDevice(postData) {
  return axiosInstance.post("/upload_rma_device_image", postData);
}

export function imageDevice() {
  return axiosInstance.post("/device_image");
}

export function changepasswordaction(password, newpassword) {
  const postData = {
    password,
    newpassword,
  };
  return axiosInstance.get("/password_update", postData);
}

export function formatError(errorResponse, bool) {
  switch (bool) {
    case true:
      //return 'Email already exists';
      swal("Woohoo!", errorResponse, "success");
      break;
    case false:
      //return 'Email not found';
      swal("Oops", errorResponse, "error", { button: "Try Again!" });
      break;

    default:
      return "";
  }
}

export function swalalert(errorResponse, bool) {
  switch (bool) {
    case true:
      //return 'Email already exists';
      swal("Hooray !", errorResponse, "success");
      break;
    case false:
      //return 'Email already exists';
      swal("Oops", errorResponse, "error");
      break;

    default:
  }
}
