import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Select,
  Button,
  Input,
  Form,
  message,
  InputNumber,
  Radio,
  Row,
  Col,
  Spin,
} from "antd";
import _ from "lodash";
import {
  getretailers,
  getibomarketlist,
  getRolelist,
  getMarketerslist,
  getofficelistname,
  editEmployee,
} from "../../../store/actions/documentActions";

import moment from "moment";
const { Option } = Select;

class EditEmployee extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    // state
    this.state = {
      offices: [],
      officeview: false,
      disableoffice: true,
      roles: [],
      filteredroles: [],
      marketers: [],
      filteredmarketers: [],
      loading: false,
      disable: false,
      marketview: false,
      distributor: [],
      master: [],
      retailer: [],
      masterview: false,
      distributorview: false,
      retailerrole: false,
      marketrole: false,
      superrole: false,
      superadmin: false,
      retailerview: false,
      agentview: false,
      officeuser: false,
      iboofficeview: false,
      rolefinfo: [],
    };
  }

  onChange(value) {
    console.log(`selected ${value}`);
  }

  mobilevalidation = (value) => {
    console.log(value);
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    if (value.test(phoneno)) {
      console.log(value.match(phoneno));
      this.props.form.setFieldsValue({
        MobileNo: value,
      });
    }
  };

  componentWillMount() {
    if (this.props.location.employee === undefined) {
      this.props.history.push("/employee");
    } else {
      console.log(this.props.location.employee);
      var role_filter = this.props.location.employee.role_info.filter(
        (person) => person.role_id !== 2
      );
      this.setState({ rolefinfo: role_filter });

      this.getMarketerslistoff();
      this.getOfficelist(this.props.location.employee.ibo_marketer_id);
      this.getRolelist();

      const Retailer = this.props.user.role_id.filter((word) => word === 12);
      const marketer = this.props.user.role_id.filter((word) => word === 3);
      const ibpmarketer = this.props.user.role_id.filter((word) => word === 11);
      const office_manager = this.props.location.employee.role_info.filter(
        (person) => person.role_id === 3
      );
      const agent_user = this.props.location.employee.role_info.filter(
        (person) => person.role_id === 2
      );
      console.log(office_manager, agent_user);
      if (ibpmarketer.length !== 0) {
        this.setState({
          iboofficeview: true,
        });
      }
      if (agent_user.length !== 0) {
        this.setState({
          agentview: true,
        });
      }

      if (Retailer.length !== 0) {
        this.setState({
          retailerrole: true,
        });
      }
      if (marketer.length !== 0) {
        this.setState({
          marketrole: true,
        });
      }
      if (office_manager.length !== 0) {
        this.setState({
          officeuser: true,
          officeview: true,
          disableoffice: true,
        });
      }
      var retaileusers = this.props.location.employee.role_info.filter(
        (role) =>
          role.role_name === "Office Manager" ||
          role.role_name === "IBO Manager"
      );
      console.log(retaileusers);

      if (retaileusers.length !== 0) {
        this.setState({
          retailerview: true,
        });
      } else {
        this.setState({
          retailerview: false,
        });
      }
    }
  }

  genderOnChange = (e) => {
    var fdesgnd = _.filter(this.state.gender, ["Gender", e]);
    this.setState({
      filteredgender: fdesgnd,
    });
    console.log(e, fdesgnd);
  };
  disabledDOB(current) {
    return moment().add(0, "days") <= current;
  }
  disabledDOJ(current) {
    return moment().add(0, "days") <= current;
  }

  handleSubmit = (values) => {
    console.log(`values`, values);
    // if (this.state.emailexists) {
    // if (this.state.vehicleexists) {

    this.setState({
      loading: true,
    });
    var employee = {
      _method: "PUT",
      first_name: values.first_name,
      middle_name: values?.middle_name ?? "",
      last_name: values.last_name,
      rad: values.rad,
      promo_code: values.promo_code,
      excess_code: values.excess_code,
      mobile_number: values.mobile_number.toString(),
      gender_id: values.gender_id, //this.state.filteredgender.id,
      office_id:
        values.role_id === 14
          ? this.props.location.employee.role_info.length === 2 &&
            values.role_id === 14
            ? [this.props.location.employee.office_id]
            : values?.office_id ?? []
          : values?.office_id ?? [],

      ibo_marketer_id:
        values.ibo_marketer_id !== null && values.ibo_marketer_id !== undefined
          ? values.ibo_marketer_id
          : this.props.location.employee?.ibo_marketer_id ?? "",
      dob: moment(this.state.dob).format("YYYY-MM-DD"),
      application_date: moment(this.state.application_date).format(
        "YYYY-MM-DD"
      ),
      address: values.address !== undefined ? values.address : "",
      city: values.city !== undefined ? values.city : "",
      state: values.state !== undefined ? values.state : "",
      zip:
        values.zip === undefined || values.zip === null
          ? ""
          : values.zip.toString(),
      email: values.email,
      role_id:
        values.role_id === 14
          ? this.props.location.employee.role_info.length === 2 &&
            values.role_id === 14
            ? ["2"]
            : [values.role_id]
          : [values.role_id], //[9, 2],
      retailer_id: values.RetailerName !== undefined ? values.RetailerName : "",
      distributor_id:
        values.DistributorName !== undefined ? values.DistributorName : "",
      master_agent_id: values.Master !== undefined ? values.Master : "",
    };
    console.log(`employee`, employee);

    this.props
      .editEmployee(employee, this.props.location.employee.id)
      .then((res) => {
        console.log("res", res);
        message.success("User edited successfully");
        this.formRef.current.resetFields();
        this.setState({
          // fileList: [],
          loading: false,
          // pwdstatus: "",
        });
        this.props.history.push("/employee");
      })
      .catch((err) => {
        message.error(err);
        this.setState({
          // fileList: [],
          loading: false,
          // pwdstatus: "",
        });
        console.log(err);
      });
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  renderOffice() {
    return _.map(this.state.offices, (dep, index) => {
      return (
        <Option value={dep.id} key={index}>
          {dep.name}
        </Option>
      );
    });
  }

  renderGender() {
    return _.map(this.state.gender, (dep, index) => {
      return (
        <Option value={dep.id} key={index}>
          {dep.name}
        </Option>
      );
    });
  }

  renderRole() {
    // console.log('this.state.roles', this.state.roles)
    return _.map(this.state.roles, (dep, index) => {
      if (this.state.iboofficeview === true) {
        if (dep.id === 14 || dep.id === 3) {
          return (
            <Col span={24} className="mb-3">
              <Radio value={dep.id}>{dep.name}</Radio>
            </Col>
          );
        }
      }
      if (this.state.retailerrole === true) {
        if (dep.id === 3 || dep.id === 11 || dep.id === 14) {
          return (
            <Col span={24} className="mb-3">
              <Radio value={dep.id}>{dep.name}</Radio>
            </Col>
          );
        }
      }
    });
  }

  renderMarketers() {
    return _.map(this.state.marketers, (dep, index) => {
      return (
        <Option value={dep.id} key={index}>
          {dep.name}
        </Option>
      );
    });
  }

  onChangeRole = (e) => {
    var checkedValues = [e.target.value];
    console.log("checkvalue", checkedValues);
    console.log("checked = ", checkedValues);
    if (e.target.value === 3 || e.target.value === 11) {
      this.getMarketerslistoff(true);
    }
    if (e.target.value === 3) {
      this.setState({
        officeview: true,
        disableoffice: true,
      });
    } else {
      this.setState({
        officeview: false,
      });
    }
    var OffManagerIndex = this.state.roles.findIndex(
      (role) => role.name === "Office Manager"
    );
    var IBOManagerIndex = this.state.roles.findIndex(
      (role) => role.name === "IBO Manager"
    );
    var retaileusers = checkedValues.filter(
      (obj) =>
        obj === this.state.roles[OffManagerIndex].id ||
        this.state.roles[IBOManagerIndex].id
    );

    console.log(retaileusers);

    if (retaileusers.length !== 0) {
      this.setState({
        retailerview: true,
      });
    } else {
      this.setState({
        retailerview: false,
      });
    }
  };

  officeOnChange = (e) => {
    var fdesgnd = _.filter(this.state.offices, ["Offices", e]);
    this.setState({
      filteredoffices: fdesgnd,
    });
    console.log(e, fdesgnd);
  };

  roleOnChange = (e) => {
    var fdesgnd = _.filter(this.state.roles, ["Role", e]);
    this.setState({
      filteredroles: fdesgnd,
    });
    console.log(e, fdesgnd);
  };

  marketerOnChange = (e) => {
    this.setState({
      loading: true,
    });
    console.log(e);
    this.props
      .getofficelistname(e)
      .then((res) => {
        console.log("marketers", res);

        this.setState({
          offices: res.data,
          disableoffice: false,
          loading: false,
        });
      })
      .catch((err) => {
        this.formRef.current.setFieldsValue({
          office_id: [],
        });
        console.log(err);
        this.setState({
          offices: [],
          disableoffice: true,
          loading: false,
        });
      });
  };

  getMarketerslist(role, e) {
    console.log(role);
    this.props
      .getMarketerslist(role === 4 ? "M" : "IM", 0)
      .then((res) => {
        if (e === true) {
          this.formRef.current.setFieldsValue({
            ibo_marketer_id: [],
          });
        }

        console.log("marketers", res);
        this.setState({
          marketers: res,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          marketers: [],
        });
      });
  }

  getMarketerslistoff(e) {
    this.setState({
      loading: true,
    });
    this.props
      .getibomarketlist()
      .then((res) => {
        // if (e === true) {
        //   this.formRef.current.setFieldsValue({
        //     ibo_marketer_id: [],
        //   });
        // }
        console.log("marketers", res);
        this.setState({
          marketers: res.data,
          loading: false,
        });
        // nprogress.done();
      })
      .catch((err) => {
        this.setState({
          marketers: [],
          loading: false,
        });
        // nprogress.done();
      });
  }

  getOfficelist(e) {
    if (this.state.offices === undefined || this.state.offices.length <= 0) {
      // nprogress.start();
      this.props
        .getofficelistname(e)
        .then((res) => {
          console.log(res);
          this.setState({
            offices: res.data,
            loading: false,
          });
          // nprogress.done();
        })
        .catch((err) => {
          this.setState({
            offices: [],
          });
          // nprogress.done();
        });
    }
  }

  getRolelist() {
    if (this.state.roles === undefined || this.state.roles.length <= 0) {
      // nprogress.start();
      this.props
        .getRolelist()
        .then((res) => {
          console.log("getRolelist", res);
          this.setState({
            roles: _.sortBy(res.data.items, (o) => o.level),
          });
          // nprogress.done();
        })
        .catch((err) => {
          this.setState({
            roles: [],
          });
          // nprogress.done();
        });
    }
  }
  renderMaster() {
    return _.map(this.state.master, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderDistributor() {
    return _.map(this.state.distributor, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderRetailer() {
    return _.map(this.state.retailer, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  render() {
    // eslint-disable-next-line

    const { loading } = this.state;

    return (
      <Spin spinning={loading}>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  <strong>Edit User</strong>
                </h4>
                <Link to="/employee" className="float-right">
                  <Button type="default">Back</Button>
                </Link>
              </div>
              <div className="card-body">
                <Form
                  name="basic"
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  onFinish={this.handleSubmit}
                  onFinishFailed={this.onFinishFailed}
                  ref={this.formRef}
                  className="addbookform row"
                  // initialValues={{
                  //   first_name:
                  //     this.props?.location?.employee?.first_name ?? "",
                  //   middle_name:
                  //     this.props?.location?.employee?.middle_name ?? "",
                  //   last_name: this.props?.location?.employee?.last_name ?? "",
                  //   email: this.props?.location?.employee?.email ?? "",
                  //   mobile_number: this.props.location?.employee?.mobile_number,
                  //   role_id: this.state.rolefinfo[0]?.role_id ?? "",
                  //   ibo_marketer_id:
                  //     this.props.location.employee.ibo_marketer_id,
                  //   address: this.props.location.employee.address,
                  //   city: this.props.location.employee.city,
                  //   state: this.props.location.employee.state,
                  //   zip: this.props.location.employee.zip,
                  // }}
                >
                  <Form.Item
                    name="first_name"
                    label="First Name"
                    className="col-md-4 col-sm-6 col-xs-12"
                    initialValue={
                      this.props?.location?.employee?.first_name ?? ""
                    }
                    rules={[
                      {
                        required: true,
                        pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                        message: "Please input first name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter First Name" />
                  </Form.Item>
                  <Form.Item
                    name="middle_name"
                    label="Middle Name"
                    className="col-md-4 col-sm-6 col-xs-12"
                    initialValue={
                      this.props?.location?.employee?.middle_name ?? ""
                    }
                    rules={[
                      {
                        required: false,
                        pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                        message: "Please input middle name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Middle Name" />
                  </Form.Item>
                  <Form.Item
                    name="last_name"
                    label="Last Name"
                    className="col-md-4 col-sm-6 col-xs-12"
                    initialValue={
                      this.props?.location?.employee?.last_name ?? ""
                    }
                    rules={[
                      {
                        required: true,
                        pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                        message: "Please input last name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Last Name" />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label="Email"
                    className="col-md-4 col-sm-6 col-xs-12 mt-2"
                    initialValue={this.props?.location?.employee?.email ?? ""}
                    rules={[
                      {
                        required: true,
                        pattern: new RegExp(
                          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                        ),
                        message: "Enter valid email address",
                      },
                    ]}
                  >
                    <Input
                      type="email"
                      placeholder="Enter Email Address"
                      autoComplete="new-password"
                      // onBlur={(e) => this.checkEmail(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    name="mobile_number"
                    label="Mobile Number"
                    className="col-md-4 col-sm-6 col-xs-12 mt-2"
                    initialValue={this.props.location?.employee?.mobile_number}
                    rules={[
                      {
                        required: true,
                        pattern: new RegExp(/^[0-9]{10,10}$/),
                        message: "Please input mobile number",
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      placeholder="Enter Mobile Number"
                      minLength={10}
                      maxLength={10}
                      min={0}
                      step={0}
                      autoComplete="new-password"
                    />
                  </Form.Item>

                  <Form.Item
                    name="role_id"
                    label="Role"
                    className="col-md-12 col-sm-6 col-xs-12 mt-2"
                    initialValue={this.state?.rolefinfo[0]?.role_id ?? ""}
                    rules={[
                      {
                        required: true,
                        message: "Please input Role",
                      },
                    ]}
                  >
                    <Radio.Group onChange={this.onChangeRole} defaultChecked>
                      <Row> {this.renderRole()}</Row>
                    </Radio.Group>
                  </Form.Item>

                  {this.state.retailerview && !this.state.marketrole ? (
                    <Form.Item
                      name="ibo_marketer_id"
                      label="IBO / Market"
                      className="col-md-6 col-sm-6 col-xs-12"
                      initialValue={
                        this.props.location.employee.ibo_marketer_id === null
                          ? ""
                          : this.props.location.employee.ibo_marketer_id
                      }
                      rules={[
                        {
                          required: true,
                          message: "Please input IBO / Market",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select IBO / Market"
                        onChange={this.marketerOnChange}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        disabled
                      >
                        {this.renderMarketers()}
                      </Select>
                    </Form.Item>
                  ) : null}
                  {this.state.retailerview &&
                  this.state.officeview &&
                  !this.state.marketrole ? (
                    <Form.Item
                      name="office_id"
                      label="Office"
                      className="col-md-6 col-sm-6 col-xs-12"
                      initialValue={
                        this.state.officeuser === true
                          ? this.props.location.employee.office_mapping_info.map(
                              (market) => market.office_id
                            )
                          : this.state.agentview === true
                          ? [this.props.location.employee.office_id]
                          : [this.props.location.employee.office_id]
                      }
                      rules={[
                        {
                          required: true,
                          message: "Please select office",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        mode="multiple"
                        style={{ width: "100%" }}
                        placeholder="Select office"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.renderOffice()}
                      </Select>
                    </Form.Item>
                  ) : null}
                  <Form.Item
                    name="address"
                    label="Street Address"
                    className="col-md-3 col-sm-6 col-xs-12"
                    initialValue={this.props.location?.employee?.address ?? ""}
                    rules={[
                      { required: false, message: "Please input Address" },
                    ]}
                  >
                    <Input placeholder="Address" />
                  </Form.Item>
                  <Form.Item
                    name="city"
                    label="City"
                    className="col-md-3 col-sm-6 col-xs-12"
                    initialValue={this.props.location?.employee?.cit ?? ""}
                    rules={[{ required: false, message: "Please input 	City" }]}
                  >
                    <Input placeholder="City" />
                  </Form.Item>
                  <Form.Item
                    name="state"
                    label="State"
                    className="col-md-3 col-sm-6 col-xs-12"
                    initialValue={this.props.location?.employee?.sta ?? ""}
                    rules={[{ required: false, message: "Please input 	State" }]}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select State"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="AL">Alabama</Option>
                      <Option value="AK">Alaska</Option>
                      <Option value="AZ">Arizona</Option>
                      <Option value="AR">Arkansas</Option>
                      <Option value="CA">California</Option>
                      <Option value="CO">Colorado</Option>
                      <Option value="CT">Connecticut</Option>
                      <Option value="DE">Delaware</Option>
                      <Option value="DC">District Of Columbia</Option>
                      <Option value="FL">Florida</Option>
                      <Option value="GA">Georgia</Option>
                      <Option value="HI">Hawaii</Option>
                      <Option value="ID">Idaho</Option>
                      <Option value="IL">Illinois</Option>
                      <Option value="IN">Indiana</Option>
                      <Option value="IA">Iowa</Option>
                      <Option value="KS">Kansas</Option>
                      <Option value="KY">Kentucky</Option>
                      <Option value="LA">Louisiana</Option>
                      <Option value="ME">Maine</Option>
                      <Option value="MD">Maryland</Option>
                      <Option value="MA">Massachusetts</Option>
                      <Option value="MI">Michigan</Option>
                      <Option value="MN">Minnesota</Option>
                      <Option value="MS">Mississippi</Option>
                      <Option value="MO">Missouri</Option>
                      <Option value="MT">Montana</Option>
                      <Option value="NE">Nebraska</Option>
                      <Option value="NV">Nevada</Option>
                      <Option value="NH">New Hampshire</Option>
                      <Option value="NJ">New Jersey</Option>
                      <Option value="NM">New Mexico</Option>
                      <Option value="NY">New York</Option>
                      <Option value="NC">North Carolina</Option>
                      <Option value="ND">North Dakota</Option>
                      <Option value="OH">Ohio</Option>
                      <Option value="OK">Oklahoma</Option>
                      <Option value="OR">Oregon</Option>
                      <Option value="PA">Pennsylvania</Option>
                      <Option value="RI">Rhode Island</Option>
                      <Option value="SC">South Carolina</Option>
                      <Option value="SD">South Dakota</Option>
                      <Option value="TN">Tennessee</Option>
                      <Option value="TX">Texas</Option>
                      <Option value="UT">Utah</Option>
                      <Option value="VT">Vermont</Option>
                      <Option value="VA">Virginia</Option>
                      <Option value="WA">Washington</Option>
                      <Option value="WV">West Virginia</Option>
                      <Option value="WI">Wisconsin</Option>
                      <Option value="WY">Wyoming</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="zip"
                    label="ZIP"
                    className="col-md-3 col-sm-6 col-xs-12"
                    initialValue={this.props.location?.employee?.zip ?? ""}
                    rules={[
                      {
                        required: false,
                        pattern: new RegExp(/^[0-9]+$/),
                        message: "Please input 	ZIP",
                      },
                    ]}
                  >
                    <Input maxLength={5} placeholder="ZIP" />
                  </Form.Item>
                  <br />
                  <br />
                  <div className="col-md-12 col-sm-12 col-xs-12 padd15">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      loading={loading}
                    >
                      Update User
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.auth.auth, data: state.data };
}

export default connect(mapStateToProps, {
  getretailers,
  getibomarketlist,
  getRolelist,
  getMarketerslist,
  getofficelistname,
  editEmployee,
})(EditEmployee);
