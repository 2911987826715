import React, { useEffect, useState, useMemo, useRef } from "react";

import { Table, Tag, Button, Select, Input, message, Empty, Spin } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import {
  SalesSummaryOfficeReport,
  getofficelistname,
  manageragentslist,
  searchofficesaleslist,
  exportofficesaleslist,

  detailed_sales_report_office_search,
} from "../../../store/actions/DeviceActions";
import { connect, useDispatch } from "react-redux";
import "antd/dist/antd.min.css";
import _ from "lodash";
import axiosInstance from "../../../services/AxiosAdmin";

const { Option } = Select;

const SalesByOffice = () => {
  const fetchRef = useRef(0);
  const [data, setData] = useState();
  const [staus, setStaus] = useState();
  const [loading, setLoading] = useState(false);
  const [onboarding, setonboarding] = useState([]);
  const [officeList, setOfficeList] = useState([]);
  const [btnloading, setbtnLoading] = useState(false);
  const [loadingEPbtn, setLoadingEPbtn] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const [selectedItems, setselactedItems] = useState([]);
  const [fdata, setfdata] = useState([]);
  const [fetching, setfetching] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
    dispatch(manageragentslist()).then((res) => {
      console.log(res);
      setOfficeList(res.data);
    });
    dispatch(getofficelistname())
      .then((res) => {
        console.log(res.data);
        setonboarding(res.data);
      })
      .catch((err) => {});
  }, []);

  const columns = [
    {
      title: "Office",
      dataIndex: "office_name",
      key: "office_name",
      sorter: true,
    },
    {
      title: "Today",
      dataIndex: "today",
      key: "today",
      sorter: true,
    },
    {
      title: "WTD",
      dataIndex: "WTD",
      key: "WTD",
      sorter: true,
    },
    {
      title: "MTD",
      dataIndex: "MTD",
      key: "MTD",
      sorter: true,
    },
    {
      title: "7 Days",
      dataIndex: "last_7_days",
      key: "last_7_days",
      sorter: true,
    },
    {
      title: "30 Days",
      dataIndex: "last_30_days",
      key: "last_30_days",
      sorter: true,
    },
  ];
  const getRandomuserParams = (params) => ({
    results: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
  });
  const fetchData = (param) => {
    console.log(param)
    setLoading(true);
    dispatch(SalesSummaryOfficeReport(param))
      .then((res) => {
        setLoading(false);
        console.log(res.data.data);
        console.log(res.data.data.total)
        setData(res.data.data.data);
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.data.total,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    setTimeout(() => {
      searchonboardingreport(" ", {
        pagination,
        filters,
        ...sorter,
      });
    }, 100);
  };
  const onChangeSelect = (value) => {
    console.log(`selected ${value}`);
    setStaus(value);
  };

  // const renderoffice = () => {
  //   return _.map(officeList.data, (office, index) => {
  //     console.log(office);
  //     // agent.map((genderData) => {
  //     return (
  //       <Option value={office.office_id} key={index}>
  //         {office.office_number}
  //       </Option>
  //     );
  //     // });
  //   });
  // };
  const searchonboardingreport = (reset, params) => {
    setLoading(true);
    setbtnLoading(true);
    // var sort_by = sortOrder;
    console.log(reset, params);
    console.log(selectedItems.key)

    var office = selectedItems.key;

    var sort = params?.order === undefined ? "" : params?.field;
    var sort_by =
      params?.order === "ascend"
        ? "ASC"
        : params?.order === undefined
        ? ""
        : "DESC";

    var page = reset === "reset" ? 1 : params?.pagination?.current ?? 1;

    dispatch(
      // searchofficesaleslist({
        detailed_sales_report_office_search({
        page: page,
        sort,
        sort_by,
        office:office,
      })
    )
      .then((res) => {
        console.log('searchofficesaleslist',res.data);
        setData(res.data.items.data);
        console.log(res.data.items.total)
        setLoading(false);
        setbtnLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.items.total,
            current: res.data.items.current_page,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setbtnLoading(false);
      });
  };
  const clearSearch = () => {
    setStaus("");
    fetchData();
    setselactedItems([]);
    setfdata([]);
  };
  const getexportbtn = () => {
    setLoading(true);
    setLoadingEPbtn(true);
    const office_id = selectedItems.key;
    console.log(office_id);
    dispatch(exportofficesaleslist(office_id))
      .then((res) => {
        setLoading(false);
        setLoadingEPbtn(false);
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingEPbtn(false);
      });
  };
  const onchangeRole = (selectedItems) => {
    console.log(selectedItems);
    setselactedItems(selectedItems);
  };
  const fetchUser = (value) => {
    console.log(fetching);
    console.log("fetching user", value);
    setfetching(false);
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    setfdata([]);
    setfetching(true);
    axiosInstance
      .get("/api/v1/reference/offices?term=" + value)
      .then((response) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        console.log(response.data);
        const fdata1 = response.data.map((user) => ({
          text: user.name,
          value: user.id,
        }));
        // console.log(this);
        // this.setState({ fdata, fetching: false });
        setfdata(fdata1);
        setfetching(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">
              {/* <strong>by Offices</strong> */} by Offices{" "}
              {tableParams.pagination.total &&
                `(${tableParams.pagination.total})`}
            </h4>
            <div className="col-sm-2 text-right">
              <Button
                type="primary"
                className="mr5"
                loading={loadingEPbtn}
                onClick={getexportbtn}
              >
                <UploadOutlined style={{ fontSize: "14px" }} /> Export Report
              </Button>
            </div>
          </div>
          <div className="row mt-2" style={{ padding: "15px" }}>
            <div className="col-4">
              <label>Select Office</label>
              <Select
                showSearch
                style={{ width: "100%" }}
                labelInValue
                placeholder="Type 2 characters to search..!"
                notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
                filterOption={false}
                onSearch={fetchUser}
                defaultActiveFirstOption={false}
                value={selectedItems}
                onChange={onchangeRole}
              >
                {fdata?.map((d) => (
                  <Option key={d.value}>{d.text}</Option>
                ))}
              </Select>
              {/* <Select
                value={staus}
                style={{ width: "100%" }}
                showSearch
                placeholder="Select office"
                optionFilterProp="children"
                onChange={onChangeSelect}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={(onboarding || []).map((d) => ({
                  value: d.id,
                  label: d.name,
                }))}
              ></Select> */}
            </div>

            <div className="col-4 mt-6">
              <Button
                type="primary"
                className="mr5"
                loading={btnloading}
                onClick={() => searchonboardingreport("reset")}
              >
                Search
              </Button>
              <Button
                type="default"
                style={{ marginLeft: "10px" }}
                onClick={() => clearSearch()}
              >
                Clear
              </Button>
            </div>
          </div>
          <div className="card-body">
            <Table
              columns={columns}
              rowKey={(record) => record.key}
              dataSource={data}
              pagination={tableParams.pagination}
              loading={loading}
              onChange={handleTableChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SalesByOffice;
