/* eslint-disable react-hooks/exhaustive-deps */
import {
  Table,
  Tag,
  Button,
  DatePicker,
  Input,
  Select,
  message,
  Empty,
  Spin,
  Modal,
  List,
  Divider,
  Tooltip,
  Drawer,
  Form,
  Row,
  Col,
  Avatar,
} from "antd";
import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  useLocation,
  Link,
} from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import {
  onboarding_email_status,
  resendinviteusernew,
  resendreminderuser,
  onboardinglog,
  onboarding_summary_report,
  onboarding_status,
  searchonboardingreportlist,
  resendinviteuser,
  getofficelistname,
  exportsearchonboardingreportlist,
  viewcomments_user_Onboarding_candidates_action,
  send_custom_email_user,
} from "../../../store/actions/DeviceActions";
import { connect, useDispatch } from "react-redux";
import {
  UploadOutlined,
  DownloadOutlined,
  EyeOutlined,
  NotificationOutlined,
  EditOutlined,
  MailOutlined,
  MobileOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "antd/dist/antd.min.css";
import axiosInstance from "../../../services/AxiosAdmin";

const { RangePicker } = DatePicker;
const { Option } = Select;

const OnboardingReport = () => {
  let location = useLocation();
  const [form] = Form.useForm();
  const [office, setoffice] = useState();
  const [emailstatus, setemailstatus] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [btnloading, setbtnLoading] = useState(false);
  const [selectedstatus, setselectedstatus] = useState([]);
  const [selectedoffice, setselectedoffice] = useState([]);
  const [keyword, setkeyword] = useState("");
  const [startValue, setstartValue] = useState("");
  const [endValue, setendValue] = useState("");
  const [onboarding, setonboarding] = useState([]);
  const [loadingEPbtn, setLoadingEPbtn] = useState(false);
  const [loadingSBTbtn, setloadingSBTbtn] = useState(false);
  const [selectedItems, setselactedItems] = useState([]);
  const [fdata, setfdata] = useState([]);
  const [fetching, setfetching] = useState(false);
  const [cvisible, setcvisible] = useState(false);
  const [lvisible, setlvisible] = useState(false);
  const [Listdata, setListdata] = useState([]);
  const [Listdata1, setListdata1] = useState([]);
  const [sendmailview, setsendmailview] = useState(false);
  const [activeedit, setactiveedit] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pickerKey, setPickerKey] = useState(0);

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(location);
    const urlString = location?.state?.urlpath ?? "";
    const urlParams = urlString
      ?.split("?")[1]
      ?.split("&")
      ?.map((v) => v?.split("="));
    const paramsobj = {};
    urlParams?.forEach((element) => {
      paramsobj[element[0]] = element[1];
    });
    console.log(paramsobj["redirect_key"]);

    setselectedstatus(parseInt(paramsobj["redirect_key"]) || []);

    dispatch(onboarding_status()).then((res) => {
      console.log(res);
      setonboarding(res.data);
    });
    fetchData({
      url: urlString ?? "",
      results: 10,
      page: 1,
    });
    dispatch(getofficelistname())
      .then((res) => {
        console.log(res.data);
        setoffice(res.data);
      })
      .catch((err) => {});
    dispatch(onboarding_email_status())
      .then((res) => {
        console.log(res.data);
        setemailstatus(res.data);
      })
      .catch((err) => {});
  }, []);

  const resendoption = (params) => {
    setLoading(true);
    dispatch(resendinviteuser(params))
      .then((res) => {
        console.log(res);
        message.success(res.data.message);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.data.message);
        setLoading(false);
      });
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal = (params) => {
    console.log(params.id);
    setLoading(true);
    dispatch(viewcomments_user_Onboarding_candidates_action(params.id))
      .then((res) => {
        const resp = res.data.items;
        console.log(res.data);
        setListdata(resp);
        setIsModalOpen(true);
        // message.success(res.data.message);
        setcvisible(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.data.message);
        setLoading(false);
      });
  };

  const sendemailuser = (params) => {
    setsendmailview(true);
    setactiveedit(params.id);
  };
  const onCloseEmail = () => {
    setsendmailview(false);
  };

  const resendoptionnew = (params) => {
    setLoading(true);
    dispatch(resendinviteusernew(params))
      .then((res) => {
        console.log(res);
        message.success(res.data.message);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.data.message);
        setLoading(false);
      });
  };

  const resendreminder = (params) => {
    setLoading(true);
    dispatch(resendreminderuser(params))
      .then((res) => {
        console.log(res);
        message.success(res.data.message);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.data.message);
        setLoading(false);
      });
  };
  const rendercanlog = (params) => {
    setLoading(true);
    dispatch(onboardinglog(params))
      .then((res) => {
        console.log(res.data.items);
        setListdata1(res.data.items);
        message.success(res.data.message);
        setLoading(false);
        setlvisible(true);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.data.message);
        setLoading(false);
      });
  };
  const onClose = () => {
    setlvisible(false);
  };
  const handleSendEmail = (values) => {
    setLoading(true);
    setloadingSBTbtn(true);

    var obj_arr = {
      onboarding_status: values.email_type,
      email_text: values?.emailcontent ?? "",
    };

    dispatch(send_custom_email_user(obj_arr, activeedit))
      .then((res) => {
        console.log("res", res.data.message);
        message.success(res.data.message);
        setsendmailview(false);
        setLoading(false);
        setloadingSBTbtn(false);
        setactiveedit("");
        form.resetFields();
        fetchData({
          results: 10,
          page: 1,
        });
      })
      .catch((error) => {
        message.error(error.data.message);
        console.log(error);
        setsendmailview(false);
        setLoading(false);
        setloadingSBTbtn(false);
        setactiveedit("");
      });
  };
  const onFinishFailed = (e) => {
    console.log(e);
  };
  const renderonboardingemail = () => {
    return _.map(emailstatus, (master, index) => {
      //console.log(master.id);
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  };
  const columns = [
    {
      title: "Office",
      dataIndex: "office_name",
      key: "office_name",
    },
    {
      title: "Status",
      dataIndex: "status_name",
      key: "status_name",
      width: 220,
      render: (text, record) => (
        <span>
          <Tag color="orange">{record.status_name}</Tag>
        </span>
      ),
    },
    {
      title: "ETC Code",
      dataIndex: "telgoo_id",
      key: "telgoo_id",
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      sorter: true,
    },
    {
      title: "Middle Name",
      dataIndex: "middle_name",
      key: "middle_name",
      sorter: true,
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      sorter: true,
    },
    {
      title: "Address1",
      dataIndex: "street_address1",
      key: "street_address1",
    },
    {
      title: "Address2",
      dataIndex: "street_address2",
      key: "street_address2",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Zip",
      dataIndex: "zip",
      key: "zip",
    },
    {
      title: "Shirt Size",
      dataIndex: "shirt_size",
      key: "shirt_size",
    },
    {
      title: "Profile Picture",
      dataIndex: "photo_copy_url",
      key: "photo_copy_url",
      render: (_, record) => (
        <span>
          {record.photo_copy_url !== null && (
            <a
              href={record?.photo_copy_url ?? ""}
              target="_blank"
              rel="noreferrer"
            >
              <Avatar
                shape="square"
                size={64}
                src={record?.photo_copy_url ?? ""}
              />
            </a>
          )}
        </span>
      ),
    },
    {
      title: "Government ID",
      dataIndex: "government_document_url",
      key: "government_document_url",
      width: 150,
      align: "center",
      render: (_, record) => (
        <span>
          {record.government_document_url !== null && (
            <a
              href={record?.government_document_url ?? ""}
              target="_blank"
              rel="noreferrer"
            >
              <Avatar
                shape="square"
                size={64}
                src={record?.government_document_url ?? ""}
              />
            </a>
          )}
        </span>
      ),
    },
    // {
    //   title: "Comments",
    //   dataIndex: "status_change_comments",
    //   key: "status_change_comments",
    // },
    // {
    //   title: "BGC Description",
    //   dataIndex: "bgc_status",
    //   key: "bgc_status",
    //   width: 150,
    // },
    {
      title: "BGC Acceptance Form",
      dataIndex: "candidate_signed_bgc_url",
      key: "candidate_signed_bgc_url",
      headerAlign: "center",
      width: 150,
      align: "center",
      render: (_, record) => (
        <span>
          {record.candidate_signed_bgc_url !== null ? (
            <span>
              <Tooltip title="Click Here Download">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<DownloadOutlined />}
                  onClick={(e) => {
                    window.open(
                      record?.candidate_signed_bgc_url ?? "",
                      "_blank"
                    );
                  }}
                />
              </Tooltip>
            </span>
          ) : (
            ""
          )}
        </span>
      ),
    },
    {
      title: "Retailer Agent Agreement",
      dataIndex: "retailer_agent_agreement_url",
      key: "retailer_agent_agreement_url",
      headerAlign: "center",
      align: "center",
      render: (_, record) => (
        <span>
          {record.retailer_agent_agreement_url !== null ? (
            <span>
              <Tooltip title="Click Here Download">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<DownloadOutlined />}
                  onClick={(e) => {
                    window.open(
                      record?.retailer_agent_agreement_url ?? "",
                      "_blank"
                    );
                  }}
                />
              </Tooltip>
            </span>
          ) : (
            ""
          )}
        </span>
      ),
    },
    {
      title: "A01",
      dataIndex: "course_one",
      key: "course_one",
    },

    {
      title: "A02",
      dataIndex: "course_two",
      key: "course_two",
    },

    {
      title: "A03",
      dataIndex: "course_three",
      key: "course_three",
    },

    {
      title: "A04",
      dataIndex: "course_four",
      key: "course_four",
    },

    {
      title: "A05",
      dataIndex: "course_five",
      key: "course_five",
    },

    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
    },

    // {
    //   title: "Comments Action",
    //   dataIndex: "id",
    //   key: "id",
    //   align: "center",
    //   render: (_, record) => (
    //     <span>
    //       <Button
    //         type="primary"
    //         icon={<EyeOutlined />}
    //         shape="circle"
    //         style={{ fontSize: 11 }}
    //         onClick={() => showModal(record)}
    //       ></Button>
    //     </span>
    //   ),
    // },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      fixed: "right",
      width: 280,
      render: (_, record) => (
        <span>
          <Tooltip title="View Comments">
            <Button
              type="primary"
              icon={<EyeOutlined />}
              shape="circle"
              style={{ fontSize: 11 }}
              onClick={() => showModal(record)}
            ></Button>
          </Tooltip>
          <Divider type="vertical" />
          {record.status >= 3 ? (
            record.status !== 13 ? (
              <Tooltip title="Edit Candidate">
                <Link
                  to={{
                    pathname: "/editagent",
                    agent_info: record,
                  }}
                >
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    type="primary"
                  ></Button>
                </Link>
              </Tooltip>
            ) : (
              <Tooltip title="Edit Candidate">
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  type="primary"
                  disabled
                ></Button>
              </Tooltip>
            )
          ) : (
            <Tooltip title="Edit Candidate">
              <Button
                shape="circle"
                icon={<EditOutlined />}
                type="primary"
                disabled
              ></Button>
            </Tooltip>
          )}

          <Divider type="vertical" />
          {record.status === 1 ? (
            <Tooltip title="Resend Invite">
              <Button
                shape="circle"
                icon={<MailOutlined />}
                onClick={() => resendoption(record)}
                type="primary"
              ></Button>
            </Tooltip>
          ) : (
            <Tooltip title="Email Reminder">
              <Button
                shape="circle"
                icon={<MailOutlined />}
                onClick={() => resendoptionnew(record)}
                type="primary"
              ></Button>
            </Tooltip>
          )}
          <Divider type="vertical" />
          {record.status !== 13 ? (
            <Tooltip title="SMS Reminder">
              <Button
                shape="circle"
                icon={<MobileOutlined />}
                onClick={() => resendreminder(record)}
                type="primary"
              ></Button>
            </Tooltip>
          ) : (
            <Tooltip title="SMS Reminder">
              <Button
                shape="circle"
                icon={<MobileOutlined />}
                disabled
                type="primary"
              ></Button>
            </Tooltip>
          )}
          <Divider type="vertical" />
          <Tooltip title="View log">
            <Button
              shape="circle"
              icon={<HistoryOutlined />}
              onClick={() => rendercanlog(record)}
              type="primary"
            ></Button>
          </Tooltip>
        </span>
      ),
    },
  ];

  const fetchData = (params = {}) => {
    setLoading(true);
    dispatch(onboarding_summary_report(params))
      .then((res) => {
        setLoading(false);
        console.log(res.data.data);
        setData(res.data.data.data);
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.data.total,
          },
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    // console.log(pagination, filters, sorter);
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    setTimeout(() => {
      searchonboardingreport("sort", {
        pagination,
        filters,
        ...sorter,
      });
    }, 100);
  };
  const onChange = (dates, dateStrings) => {
    if (dates) {
      console.log("From: ", dates[0], ", to: ", dates[1]);
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setstartValue(dateStrings[0]);
      setendValue(dateStrings[1]);
    } else {
      console.log("Clear");
    }
  };
  const onChangeSelect = (value) => {
    console.log(`selected ${value}`);
    setselectedstatus(value);
  };
  const onChangeOffice = (value) => {
    console.log(`selected ${value}`);
    setselectedoffice(value);
  };
  const searchonboardingreport = (reset, params) => {
    setLoading(true);
    setbtnLoading(true);
    // var sort_by = sortOrder;
    console.log(params);
    var status = selectedstatus.toString();
    var search_text = keyword;
    var sort = params.order === undefined ? "" : params.field;
    var sort_by =
      params.order === "ascend"
        ? "ASC"
        : params.order === undefined
        ? ""
        : "DESC";
    var date_range_start = startValue;
    var date_range_end = endValue;
    var office_id = selectedItems.key;
    var page = reset === "reset" ? 1 : params.pagination?.current ?? 1;

    dispatch(
      searchonboardingreportlist({
        status: status,
        search_text: search_text,
        page: page,
        office_id,
        sort,
        sort_by,
        date_range_start,
        date_range_end,
      })
    )
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data.data);
        setLoading(false);
        setbtnLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.data.total,
            current: res.data.data.current_page,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setbtnLoading(false);
      });
  };
  const clearSearch = () => {
    setTableParams({
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });
    setPickerKey((prevKey) => prevKey + 1);
    setLoading(true);
    setstartValue(null);
    setendValue(null);
    setkeyword("");
    setselectedstatus([]);
    setselectedoffice([]);
    setselactedItems([]);
    fetchData();
    setfdata([]);
  };

  const getexportbtn = () => {
    setLoading(true);
    setLoadingEPbtn(true);
    var status = selectedstatus.toString();
    var search_text = keyword;
    var date_range_start = startValue;
    var date_range_end = endValue;
    var office_id = selectedItems.key;
    dispatch(
      exportsearchonboardingreportlist({
        status: status,
        search_text: search_text,
        office_id,
        date_range_start,
        date_range_end,
      })
    )
      .then((res) => {
        setLoading(false);
        setLoadingEPbtn(false);
        console.log(res);
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingEPbtn(false);
      });
  };
  const fetchUser = (value) => {
    console.log("fetching user", value);
    setfetching(false);
    if (value.length > 1) {
      setfdata([]);
      setfetching(true);
      // this.setState({ fdata: [], fetching: true });
      axiosInstance
        .get("/api/v1/reference/offices?term=" + value)
        .then((response) => {
          console.log(response.data);
          const fdata1 = response.data.map((user) => ({
            text: user.name,
            value: user.id,
          }));
          // console.log(this);
          // this.setState({ fdata, fetching: false });
          setfdata(fdata1);
          setfetching(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const onchangeRole = (selectedItems) => {
    console.log(selectedItems);
    setselactedItems(selectedItems);
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">
              {/* <strong>Onboarding Summary</strong> */} Onboarding Summary{" "}
              {tableParams.pagination.total &&
                `(${tableParams.pagination.total})`}
            </h4>
            <div className="col-4 d-flex justify-content-end ">
              <Button
                type="primary"
                className="mr5"
                loading={loadingEPbtn}
                onClick={getexportbtn}
              >
                <UploadOutlined style={{ fontSize: "14px" }} /> Export Report
              </Button>
            </div>
          </div>
          <div className="row mt-2" style={{ padding: "15px" }}>
            <div className="col-sm-12 col-md-4">
              <label>Select Office</label>
              <Select
                showSearch
                style={{ width: "100%" }}
                labelInValue
                placeholder="Type 2 characters to search..!"
                notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
                filterOption={false}
                onSearch={fetchUser}
                defaultActiveFirstOption={false}
                value={selectedItems}
                onChange={onchangeRole}
              >
                {fdata?.map((d) => (
                  <Option key={d.value}>{d.text}</Option>
                ))}
              </Select>
              {/* <Select
                style={{ width: "100%" }}
                showSearch
                placeholder="Select office"
                value={selectedoffice}
                optionFilterProp="children"
                onChange={onChangeOffice}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={(office || []).map((d) => ({
                  value: d.id,
                  label: d.name,
                }))}
              /> */}
            </div>
            <div className="col-sm-12 col-md-4">
              <label>Select Status</label>
              <Select
                style={{ width: "100%" }}
                showSearch
                placeholder="Select status"
                value={selectedstatus}
                optionFilterProp="children"
                onChange={onChangeSelect}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={(onboarding || []).map((d) => ({
                  value: d.id,
                  label: d.name,
                }))}
              ></Select>
            </div>
            <div className="col-sm-12 col-md-4">
              <label>Select Date</label>
              <RangePicker
                key={pickerKey}
                disabledDate={disabledDate}
                format="MM-DD-YYYY"
                style={{ width: "100%" }}
                onChange={onChange}
              />
            </div>

            <div className="col-sm-12 col-md-4 mt-2">
              <label>Search by keyword</label>
              <Input
                placeholder="Search by keyword"
                value={keyword}
                onChange={(e) => setkeyword(e.target.value)}
                style={{ width: "100%", display: "block" }}
              />
            </div>
            <div className="col-sm-12 col-md-12 mt-6">
              <Button
                type="primary"
                className="mr5"
                loading={btnloading}
                onClick={() => searchonboardingreport("reset", "")}
              >
                Search
              </Button>
              <Button
                style={{ marginLeft: "10px" }}
                type="default"
                onClick={clearSearch}
              >
                Clear
              </Button>
            </div>
          </div>
          <div className="card-body">
            <Table
              columns={columns}
              size="middle"
              rowKey={(record) => record.key}
              dataSource={data}
              scroll={{ x: 3500 }}
              pagination={tableParams.pagination}
              loading={loading}
              onChange={handleTableChange}
            />
          </div>
          <div>
            <Modal
              title="View Comments"
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={[
                <Button type="primary" onClick={handleCancel}>
                  Close
                </Button>,
              ]}
            >
              {Listdata.length === 0 ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              ) : (
                <>
                  {Listdata.map((items, index) => {
                    return (
                      <List.Item key={index}>
                        <List.Item.Meta
                          title={
                            <Tag color="orange">
                              {items.created_at}-{items.commented_by_name}
                            </Tag>
                          }
                          description={items.comments}
                        />
                      </List.Item>
                    );
                  })}
                </>
              )}
            </Modal>
            <Drawer
              title="Send Email Candidate"
              placement="right"
              closable={true}
              width={540}
              onClose={onCloseEmail}
              open={sendmailview}
              bodyStyle={{ paddingBottom: 80 }}
            >
              <Form
                form={form}
                layout="vertical"
                onFinish={handleSendEmail}
                onFinishFailed={onFinishFailed}
              >
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="Email Type"
                      name="email_type"
                      rules={[
                        {
                          required: true,
                          message: "Please select email type",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select type"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {renderonboardingemail()}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16} className="mt-2">
                  <Col span={24}>
                    <Form.Item
                      label="Email Content"
                      name="emailcontent"
                      rules={[
                        {
                          required: true,
                          message: "Please select office",
                        },
                      ]}
                    >
                      <ReactQuill theme="snow" />
                    </Form.Item>
                  </Col>
                </Row>

                <div
                  style={{
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                    width: "100%",
                    borderTop: "1px solid #e9e9e9",
                    padding: "10px 16px",
                    background: "#fff",
                    textAlign: "right",
                  }}
                >
                  <Button onClick={onCloseEmail} style={{ marginRight: 8 }}>
                    Cancel
                  </Button>
                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={loadingSBTbtn}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </Drawer>
            <Drawer
              title="View Log"
              placement="right"
              closable={true}
              width={420}
              onClose={onClose}
              open={lvisible}
            >
              <List
                itemLayout="horizontal"
                dataSource={Listdata1}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.created_at}
                      description={item.description}
                    />
                  </List.Item>
                )}
              />
            </Drawer>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OnboardingReport;
