import React, { useState, useEffect, useRef } from "react";
import {
  GettransferRequest,
  GettransferRequestAccept,
  exportAcceptInventoryAction,
} from "../../../store/actions/DeviceActions";
import { map, get } from "lodash";
import { connect, useDispatch } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { ExclamationCircleOutlined, UploadOutlined } from "@ant-design/icons";

import {
  Table,
  Button,
  Drawer,
  List,
  Space,
  Input,
  Divider,
  Modal,
  message,
} from "antd";
const { confirm } = Modal;
const TransferRequest = (props) => {
  //console.log(props.device);
  const [open, setOpen] = useState(false);
  const [imei, setimei] = useState([]);
  const [transferlist, settransferlist] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [loading, setLoading] = useState(true);
  const [ACPTloading, setACPTloading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [search_key, setsearch_key] = useState(null);
  const [loadingEPbtn, setLoadingEPbtn] = useState(false);
  const searchInput = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GettransferRequest())
      .then((res) => {
        setLoading(false);
        settransferlist(res ?? []);
        setFilteredData(res ?? []);
        console.log(res);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [dispatch]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Agent Name",
      dataIndex: "agent_name",
      key: "agent_name",

      width: 230,
    },
    {
      title: "IMEI Count",
      dataIndex: "transfer_count",
      key: "transfer_count",
    },
    {
      title: "Date",
      dataIndex: "created_date",
      key: "created_date",
    },
    {
      title: "Action",
      dataIndex: "address",
      key: "address",
      fixed: "right",
      width: 220,
      render: (_, record) => (
        <span>
          <Button
            onClick={() => showDrawer(record, true)}
            type="primary"
            loading={ACPTloading}
          >
            Accept
          </Button>
          <Divider type="vertical" />
          <Button onClick={() => showDrawerIMEI(record)} type="primary">
            view
          </Button>
          {/* <Button onClick={() => showDrawer(record, false)} type="danger">
            Decline
          </Button> */}
        </span>
      ),
    },
  ];

  // rowSelection object indicates the need for row selection

  const showDrawer = (e, stat) => {
    console.log(e);
    setimei(e.imei);
    // setOpen(true);
    var obj_arr = {
      transfer_job_id: e.transfer_job_id,
      status: stat === true ? 1 : 2,
      // setACPTloading:(true),
    };
    confirm({
      title: "Are you sure ? ",
      icon: <ExclamationCircleOutlined />,
      // content: "Are you sure to do this task?",
      content: "You would like to do this task ?",
      onOk() {
        setLoading(true);
        setLoading(true);
        dispatch(GettransferRequestAccept(obj_arr))
          .then((res) => {
            console.log(res);
            // setACPTloading(false)
            // setLoading(false);
            dispatch(GettransferRequest())
              .then((res) => {
                setLoading(false);
                settransferlist(res);
                console.log(res);
              })
              .catch((err) => {
                setLoading(false);
              });
          })
          .catch((err) => {
            console.log(err);
            // setACPTloading(false)
            setLoading(false);
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const onClose = () => {
    setOpen(false);
  };

  const showDrawerIMEI = (e) => {
    console.log(e);
    console.log(e.device);
    setimei(e.device);
    setOpen(true);
  };

  const getexportbtn = () => {
    setLoading(true);
    setLoadingEPbtn(true);
    const keyword = search_key;
    console.log(keyword);
    dispatch(exportAcceptInventoryAction(keyword))
      .then((res) => {
        setLoading(false);
        setLoadingEPbtn(false);
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingEPbtn(false);
      });
  };

  const onSearch = (e) => {
    const reg = new RegExp(e.target.value, "gi");
    const filteredbydate = map(filteredData, (record) => {
      const agent_name = get(record, "agent_name").toString().match(reg);
      if (!agent_name) {
        return null;
      }
      return record;
    }).filter((record) => !!record);
    setsearch_key(e.target.value);
    settransferlist(
      e.target.value || e.target.value.length === 0
        ? filteredbydate
        : filteredData
    );
  };
  const clearSearch = () => {
    setsearch_key("");
    settransferlist(filteredData);
  };
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                {/* <strong>Accept Transfer</strong> */} Accept Transfer{" "}
                {transferlist.length && `(${transferlist.length})`}
              </h4>
              <Button
                type="primary"
                className="mr5"
                loading={loadingEPbtn}
                onClick={getexportbtn}
              >
                <UploadOutlined style={{ fontSize: "14px" }} /> Export Report
              </Button>
            </div>
            <div className="card-body">
              <div className="row bg-white padd0 align-items-center mb-4">
                <div className="col ">
                  <label for="exampleFormControlInput1">
                    Search by keyword
                  </label>
                  <Input
                    // type="number"
                    placeholder="Search by keyword"
                    value={search_key}
                    onChange={onSearch}
                    // onChange={(e) => setsearch_key(e.target.value)}
                    style={{ width: "100%", display: "block" }}
                  />
                </div>
                <div className="col mt-6">
                  {/* <Button
                    type="primary"
                    className="mr5"
                    loading={btnLoading}
                    onClick={onSearch}
                  >
                    Search
                  </Button> */}
                  <Button
                    type="primary"
                    style={{ marginLeft: "10px" }}
                    onClick={() => clearSearch()}
                  >
                    Clear
                  </Button>
                </div>
              </div>
              <Table
                columns={columns}
                loading={loading}
                dataSource={transferlist}
                scroll={{
                  x: 1000,
                }}
              />
            </div>
          </div>
        </div>
        <Drawer
          title="IMEI List"
          placement="right"
          onClose={onClose}
          open={open}
        >
          <List
            bordered
            dataSource={imei}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        </Drawer>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    transfer: state.devicelist.transfer,
  };
};

export default connect(mapStateToProps)(TransferRequest);
