import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getretailers,
  getofficelistname,
  getEmployeelist,
  searchEmployeelist,
  getRolelist,
  Employeeaction,
  changeofficeuser,
  user_export,
} from "../../../store/actions/DeviceActions";
import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Table,
  Tag,
  Modal,
  message,
  Select,
  Input,
  Divider,
  Tooltip,
  Drawer,
  Form,
  Col,
  Row,
} from "antd";
import {
  ClockCircleOutlined,
  UserOutlined,
  EditOutlined,
  ShopOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import _ from "lodash";
const { Option } = Select;

const Employees = () => {
  let dustatus;
  const user = useSelector((state) => state.auth.auth);
  const userinfo = useSelector((state) => state.userinfo);
  const [Employees, setEmployees] = useState([]);
  const [retailer, setRetailer] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [loading, setLoading] = useState(false);
  const [loadingbtn, setLoadingBtn] = useState(false);
  const [loadingEPbtn, setLoadingEPBtn] = useState(false);
  const [retailerrole, setRetailerRole] = useState(false);
  const [marketrole, setMarketRole] = useState(false);
  const [superrole, setSuperRole] = useState(false);
  const [superadmin, setSuperAdmin] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  const [selectedfilter, setSelectedFilter] = useState([]);
  const [selectedRetail, setSelectedRetail] = useState([]);
  const [Pageurl, setPageUrl] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [results, setResults] = useState(10);
  const [sortList, setSortList] = useState([]);
  const [offices, setOffices] = useState([]);
  const [visible, setVisible] = useState(false);
  const [activeUser, setActiveUser] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [dashData, setDashData] = useState("");
  const [phone, setPhone] = useState("");
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    getdesignation({
      results: 10,
      page: 1,
    });
    console.log(user);
    const Retailer = user?.role_id?.filter((word) => word === 12) ?? [];
    const marketer = user?.role_id?.filter((word) => word === 11) ?? [];
    console.log("Retailerview", Retailer);
    console.log("marketer", marketer);
    if (Retailer.length !== 0) {
      setRetailerRole(true);
    }
    if (marketer.length !== 0) {
      setMarketRole(true);
    }
    getRoleList();
    getRetailerList();
  }, []);

  const getdesignation = (params = {}) => {
    setLoading(true);
    dispatch(getEmployeelist(params))
      .then((res) => {
        console.log(res);
        console.log(res.data.last_run_date);
        // const pager = { ...pagination };
        // pagination.total = res.data.items.total;
        setEmployees(res.data.items.data);
        // setPagination(pager);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.items.total,
          },
        });
        setLoading(false);
        setDashData(res.data.last_run_date);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...pagination };
    console.log(sorter.order);
    console.log("filters", filters);
    var status = filters?.status ?? [];
    pager.current = pagination.current;
    console.log(pagination);
    dustatus = status.toString();
    setTableParams({
      ...tableParams,
      pagination: { current: pagination.current },
    });
    // setPagination(pager);
    setSelectedStatus(status.toString());
    setSortField(sorter.order === undefined ? "" : sorter.field);
    setSortOrder(
      sorter.order === "ascend"
        ? "ASC"
        : sorter.order === undefined
        ? ""
        : "DESC"
    );
    setTimeout(() => {
      searchUser("", {
        pagination,
        filters,
        ...sorter,
      });
    }, 100);
  };

  const searchUser = (resetpage, params) => {
    setLoading(true);
    setLoadingBtn(true);
    // var status = selectedStatus;
    var status = dustatus;
    var search_text = phone;
    var roleName = selectedItems;
    var sort = sortField;
    var sort_by = sortOrder;
    var page = resetpage ? 1 : params.pagination?.current ?? 1;
    var result = results;

    console.log("items", selectedRetail);
    var url = `/api/v1/users?status=${status ?? ""}&search_text=${
      search_text ?? ""
    }&role=${roleName?.length > 0 ? roleName : ""}&sort=${sort ?? ""}&sort_by=${
      sort_by ?? ""
    }&page=${page ?? 1}&results=${result ?? 10}`;
    dispatch(searchEmployeelist(url))
      .then((res) => {
        console.log(res);
        // pagination.total = res.data.items.total;
        // pagination.current = res.data.items.current_page;
        setEmployees(res.data.items.data);
        setLoading(false);
        setLoadingBtn(false);
        setTableParams({
          ...tableParams,
          pagination: {
            total: res.data.items.total,
            current: res.data.items.current_page,
          },
        });
        // setPagination(...pagination);
        setIsSearch(true);
      })
      .catch((err) => {
        setLoading(false);
        setLoadingBtn(false);
      });
  };

  const clearSearch = () => {
    setPhone("");
    setSelectedItems([]);
    setSelectedFilter([]);
    setSelectedRetail([]);
    setIsSearch(false);
    getdesignation({
      results: 10,
      page: 1,
    });
  };
  const onchangeRole = (selectedItems) => {
    setSelectedItems(selectedItems);
    console.log(selectedItems);
  };

  const getRoleList = () => {
    // nprogress.start();
    dispatch(getRolelist())
      .then((res) => {
        setRoles(res.data.items);
      })
      .catch((err) => {
        setRoles([]);
      });
  };

  const getRetailerList = () => {
    dispatch(getretailers())
      .then((res) => {
        console.log(res);
        setRetailer(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderRole = () => {
    return _.map(roles, (dep, index) => {
      if (marketrole === true) {
        if (dep.id === 3 || dep.id === 9 || dep.id === 2) {
          return (
            <Option value={dep.id} key={dep.id}>
              {dep.name}
              {/* Market Supervisor */}
            </Option>
          );
        }
      }
      if (retailerrole === true) {
        if (
          dep.id === 2 ||
          dep.id === 3 ||
          dep.id === 9 ||
          dep.id === 4 ||
          dep.id === 8 ||
          dep.id === 11
        ) {
          return (
            <Option value={dep.id} key={dep.id}>
              {dep.name}
            </Option>
          );
        }
      }
    });
  };

  const getrefreshbtn = () => {
    getdesignation({
      results: 10,
      page: 1,
    });
    setPhone("");
    setSelectedItems([]);
    setSelectedRetail([]);
  };

  const getexportbtn = () => {
    setLoading(true);
    setLoadingEPBtn(true);
    var keyword = phone;
    var role = selectedItems;
    var status = selectedStatus;
    var sort = sortField;
    var sort_by = sortOrder;
    dispatch(user_export(keyword, role, status, sort, sort_by))
      .then((res) => {
        setLoading(false);
        setLoadingEPBtn(false);
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingEPBtn(false);
      });
  };

  const renderOffice = () => {
    return offices.map((dep, index) => {
      return (
        <Option value={dep.id} key={index}>
          {dep.name}
        </Option>
      );
    });
  };
  const showDrawer = (e) => {
    setLoading(true);
    dispatch(getofficelistname(e.ibo_marketer_id))
      .then((res) => {
        console.log(res);
        setOffices(res.data);
        setVisible(true);
        setActiveUser(e);
        setLoading(false);
        form.setFieldsValue({
          office_id: e.office_id,
        });
      })
      .catch((err) => {
        setOffices([]);
        setLoading(false);
      });
  };

  const onClose = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleSubmit = (values) => {
    setLoading(true);
    setBtnLoading(true);
    Modal.confirm({
      title: "Warning",
      content: "Are you sure you want to transfer the agent to the new office?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        dispatch(changeofficeuser(values, activeUser))
          .then((res) => {
            message.success("Office Updated Successfully");
            form.resetFields();
            setLoading(false);
            setBtnLoading(false);
            setVisible(false);
            getdesignation({
              results: 10,
              page: 1,
            });
          })
          .catch((err) => {
            message.error(err);
            console.log(err);
            setLoading(false);
            setBtnLoading(false);
          });
      },
      onCancel: () => {
        setLoading(false);
        setBtnLoading(false);
      },
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  // ======================================================
  const columns = [
    {
      title: "IBOs / Markets",
      dataIndex: "user_ibo_market_name",
      key: "user_ibo_market_name",
      sorter: true,
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      sorter: true,
    },
    {
      title: "Middle Name",
      dataIndex: "middle_name",
      key: "middle_name",
      sorter: true,
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      sorter: true,
    },

    {
      title: "Phone Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
    },

    {
      title: "Role",
      dataIndex: "role_info",
      key: "role_info",
      width: 180,
      render: (tags) => (
        <span>
          {tags.map((tag) => {
            return (
              <Tag color="volcano" key={tag.role_id}>
                {tag.role_name}
              </Tag>
            );
          })}
        </span>
      ),
    },
    {
      title: "Office",
      dataIndex: "office_number",
      key: "office_number",
      sorter: true,
    },
    {
      title: "ETC Code",
      dataIndex: "excess_code",
      key: "excess_code",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "ZIP",
      dataIndex: "zip",
      key: "zip",
    },

    {
      title: "Status",
      key: "status",
      dataIndex: "status",

      filters: [
        { text: "Active", value: "1" },
        { text: "In-Active", value: "0" },
        { text: "Removed", value: "2" },
        { text: "Suspended", value: "4" },
        { text: "Do Not Rehire", value: "5" },
        { text: "Terminated", value: "6" },
        { text: "No office", value: "8" },
      ],
      render: (text, record) => (
        <span>
          <Tag color={record.status === 1 ? "green" : "orange"}>
            {record.status_text}
          </Tag>
        </span>
      ),
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      sorter: true,
      key: "created_at",
    },
    {
      title: "Action",
      key: "action",
      width: 120,
      fixed: "right",
      render: (text, record) => (
        <span>
          {(retailerrole === true || marketrole === true) &&
          record.status === 1 ? (
            <Link
              style={{ marginRight: 10 }}
              to={{ pathname: "/editemployee", employee: record }}
            >
              <Tooltip title="Edit User">
                <Button type="primary" shape="circle" icon={<EditOutlined />} />
              </Tooltip>
            </Link>
          ) : (
            <Link disabled style={{ marginRight: 10 }} to={{ pathname: "" }}>
              <Tooltip title="Edit User">
                <Button
                  type="primary"
                  disabled
                  shape="circle"
                  icon={<EditOutlined />}
                />
              </Tooltip>
            </Link>
          )}
          <Divider type="vertical" />
          {record.role_info
            .filter((e) => e.role_id === 2)
            .map((role) => (
              <Tooltip title="Change Office">
                <Button
                  onClick={() => showDrawer(record)}
                  type="primary"
                  shape="circle"
                  icon={<ShopOutlined />}
                />
              </Tooltip>
            ))}
        </span>
      ),
    },
  ];

  return (
    <div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="container-fluid">
              <div className="card-header">
                <div className="col-sm-4">
                  <h4>
                    Users{" "}
                    {tableParams.pagination.total &&
                      `(${tableParams.pagination.total})`}
                  </h4>
                </div>
                <div className="col text-end">
                  {/* {this.rendercsvformat(data)} */}
                  <Button
                    type="primary"
                    className="mr5"
                    loading={loadingEPbtn}
                    onClick={() => getexportbtn()}
                  >
                    {/* <i className="uil uil-export" /> Export Report */}
                    <UploadOutlined style={{ fontSize: "14px" }} /> Export
                    Report
                  </Button>
                  <Button
                    type="default"
                    className="mr5 mx-2"
                    onClick={() => getrefreshbtn()}
                  >
                    <i className="uil uil-sync" /> Refresh
                  </Button>

                  <Link to="/addEmployee">
                    <Button type="primary">Add User</Button>
                  </Link>
                </div>
              </div>

              <div className="row padd20 bg-grey d-flex mb-4 mt-4">
                <div className="row">
                  <div class="col-md-4">
                    <label>Select Role</label>
                    <Select
                      showSearch
                      mode="multiple"
                      style={{ width: "100%" }}
                      placeholder="Select Role"
                      value={selectedItems}
                      onChange={onchangeRole}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {renderRole()}
                    </Select>
                  </div>

                  <div class="col-md-4">
                    <label>Search By Keyword</label>
                    <Input
                      placeholder="Type Keyword"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      style={{ width: "100%", display: "block" }}
                    />
                  </div>
                  <div className="col mt-6">
                    <Button
                      type="primary"
                      className="mr5"
                      loading={loadingbtn}
                      onClick={() => {
                        searchUser("reset");
                      }}
                    >
                      Search
                    </Button>
                    <Button
                      type="default"
                      className="mx-2"
                      onClick={() => clearSearch()}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
              </div>
              <div className="bg-white padd0">
                <Table
                  size="middle"
                  expandable={true}
                  columns={columns}
                  dataSource={Employees}
                  scroll={{
                    x: 2500,
                  }}
                  pagination={tableParams.pagination}
                  loading={loading}
                  onChange={handleTableChange}
                />
              </div>
              <Drawer
                title="Agent Office Transfer"
                width={500}
                onClose={onClose}
                open={visible}
                bodyStyle={{ paddingBottom: 80 }}
              >
                <Form
                  form={form}
                  name="basic"
                  onFinish={handleSubmit}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                >
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        label="Office"
                        name="office_id"
                        rules={[
                          { required: true, message: "Please select office" },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select Office Number"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {renderOffice()}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <div
                    style={{
                      position: "absolute",
                      right: 0,
                      bottom: 0,
                      width: "100%",
                      borderTop: "1px solid #e9e9e9",
                      padding: "10px 16px",
                      background: "#fff",
                      textAlign: "right",
                    }}
                  >
                    <Button onClick={onClose} style={{ marginRight: 8 }}>
                      Cancel
                    </Button>
                    <Button
                      loading={btnLoading}
                      htmlType="submit"
                      type="primary"
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </Drawer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Employees;
