import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { DropzoneArea } from "material-ui-dropzone";
import { UploadRMAAction } from "../../../store/actions/DeviceActions";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory, useLocation } from "react-router-dom";

function RMAForm() {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const [data1, setData] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [dropzoneKey, setDropzoneKey] = React.useState(0);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleChange = (files) => {
    console.log(files);
    setData(files);
  };

  //   const handletransfer = () => {
  //     const formData = new FormData();
  //     formData.append("imei_image", data[0]);
  //     console.log(data[0], formData);
  //     dispatch(UploadimageAction(formData));
  //     setDropzoneKey(dropzoneKey + 1);
  //   };

  const onSubmit = (data) => {
    setOpen(true);
    console.log(data);
    const formData = new FormData();
    formData.append("device_image", data1[0]);
    formData.append("reason", data.reason);
    formData.append("imei", data.imei);
    //console.log(data[0], formData);
    dispatch(UploadRMAAction(formData))
      .then((res) => {
        setOpen(false);
        setDropzoneKey(dropzoneKey + 1);
        reset({ reason: "", imei: "" });
        history.push("/rmarequest");
      })
      .catch((err) => {
        //setDropzoneKey(dropzoneKey + 1);
        setOpen(false);
      });
  };

  console.log(errors);

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DropzoneArea
        key={"dropzone-" + dropzoneKey}
        filesLimit={1}
        acceptedFiles={["image/*"]}
        onChange={handleChange}
        maxFileSize={50000000}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          style={{ marginTop: 50 }}
          sx={{
            width: "100%",
            maxWidth: "100%",
          }}
        >
          <TextField
            fullWidth
            name="imei"
            id="fullWidth"
            type="tel"
            label="IMEI"
            rows={4}
            {...register("imei", {
              required: true,
              pattern: /^[0-9]*$/,
            })}
            variant="standard"
          />
        </Box>

        {errors.imei && <p className="text-danger">Please enter valid IMEI</p>}

        <Box
          style={{ marginTop: 50 }}
          sx={{
            width: "100%",
            maxWidth: "100%",
          }}
        >
          <TextField
            fullWidth
            name="reason"
            id="fullWidth"
            type="text"
            label="Reason"
            multiline
            rows={4}
            {...register("reason", { required: true })}
            variant="standard"
          />
        </Box>
        {errors.reason && <p className="text-danger">Please enter reason</p>}

        {data1.length !== 0 && (
          <button type="submit" className="btn btn-primary mt-5">
            Submit Request
          </button>
        )}
      </form>
    </div>
  );
}

export default RMAForm;
