import React, { useState, useEffect, useRef } from "react";
import {
  GetApprovedRMARequest,
  GettransferRequestAccept,
} from "../../../store/actions/DeviceActions";
import { connect, useDispatch } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";
import {
  Table,
  Button,
  Drawer,
  List,
  Space,
  Input,
  Divider,
  Modal,
} from "antd";
const { confirm } = Modal;
const RMARequest = (props) => {
  //console.log(props.device);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [imei, setimei] = useState([]);
  const [rma, setrma] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [loading, setLoading] = useState(true);
  const searchInput = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetApprovedRMARequest())
      .then((res) => {
        console.log(res);
        setData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [dispatch]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
      ...getColumnSearchProps("agent_name"),
      width: 230,
      fixed: "right",
    },
    {
      title: "Agent Name",
      dataIndex: "agent_name",
      key: "agent_name",
      ...getColumnSearchProps("agent_name"),
      width: 230,
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "Image",
      dataIndex: "device_image_url",
      key: "device_image_url",
      render: (_, record) => (
        <span>
          <Button onClick={() => showModal(record)} type="primary">
            View Image
          </Button>
        </span>
      ),
    },
    {
      title: "Date",
      dataIndex: "created_date",
      key: "created_date",
    },
  ];

  // rowSelection object indicates the need for row selection

  // const showDrawer = (e, stat) => {
  //   console.log(e);
  //   setimei(e.imei);
  //   // setOpen(true);
  //   var obj_arr = {
  //     transfer_job_id: e.transfer_job_id,
  //     status: stat === true ? 1 : 2,
  //   };
  //   confirm({
  //     icon: <ExclamationCircleOutlined />,
  //     content: "Are you sure to do this task?",
  //     onOk() {
  //       dispatch(GettransferRequestAccept(obj_arr))
  //         .then((res) => {
  //           console.log(res);
  //           dispatch(GetApprovedRMARequest());
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     },
  //     onCancel() {
  //       console.log("Cancel");
  //     },
  //   });
  // };
  const onClose = () => {
    setOpen(false);
  };
  const showModal = (e) => {
    console.log(e);
    setrma(e);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                <strong>Approved RMA Request</strong>
              </h4>
              <Link to="createrma">
                <button className="btn btn-primary float-end">
                  Initiate RMA
                </button>
              </Link>
            </div>
            <div className="card-body">
              <Table
                columns={columns}
                loading={loading}
                dataSource={data}
                scroll={{
                  x: 1000,
                }}
              />
            </div>
          </div>
        </div>
        <Drawer
          title="IMEI List"
          placement="right"
          onClose={onClose}
          open={open}
        >
          <List
            bordered
            dataSource={imei}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        </Drawer>
        <Modal
          title={rma.imei}
          open={isModalOpen}
          conOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Close
            </Button>,
          ]}
        >
          <img src={rma.device_image_url} className="img-fluid" alt="" />
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    transfer: state.devicelist.transfer,
  };
};

export default connect(mapStateToProps)(RMARequest);
