export const GET_DEVICE_ACTION = "[Device Action] Get Device";
export const ACCEPT_DEVICE_ACTION = "[Device Action] Accept Device";
export const INVENTORY_DEVICE_ACTION = "[Inventory Action] Inventory Device";
export const RMA_INVENTORY_DEVICE_ACTION = "[RMA Inventory Action] RMA Inventory Device";
export const TRANSFER_DEVICE_ACTION =
  "[Transfer Action] TRANSFER_DEVICE_ACTION";
export const SCAN_DEVICE_ACTION = "[Scan Action] SCAN_DEVICE_ACTION";
export const PICKUP_DEVICE_ACTION = "[Pick Action] PICKUP_DEVICE_ACTION";
export const GET_DEVICE_TRANSACTION =
  "[Device Transaction] GET_DEVICE_TRANSACTION";
export const TOGGLE_MENU_ACTION = "TOGGLE_MENU_ACTION";
export const GET_IMAGE_ACTION = "GET_IMAGE_ACTION";
export const DOCUMENT_GET_ACTION = "DOCUMENT_GET_ACTION";
export const AGENT_POST_ACTION = "AGENT_POST_ACTION";
export const OFFICE_POST_ACTION = "OFFICE_POST_ACTION";
export const RECEIVE_INVENTORY_DEVICE_ACTION =
  "RECEIVE_INVENTORY_DEVICE_ACTION";
export const GET_OFFICE_LIST = "GET_OFFICE_LIST";
export const RMA_GET_ACTION = "RMA_GET_ACTION";
export const RMA_PENDING_INVENTORY_DEVICE_ACTION = "RMA_PENDING_INVENTORY_DEVICE_ACTION";
export const RMA_SUBMIT_INVENTORY_DEVICE_ACTION = "RMA_SUBMIT_INVENTORY_DEVICE_ACTION";
export const RMA_APPROVED_INVENTORY_DEVICE_ACTION = "RMA_APPROVED_INVENTORY_DEVICE_ACTION";
export const ORDER_APPROVAL_STATUS_ACTION = "ORDER_APPROVAL_STATUS_ACTION";
export const ORDER_STATUS_ACTION = "ORDER_STATUS_ACTION";
export const IBO_MARKETERS_ACTION = "IBO_MARKETERS_ACTION";
export const RMA_ORDER_STATUS_ACTION = "RMA_ORDER_STATUS_ACTION";
export const RMA_DEVICE_STATUS_ACTION = "RMA_DEVICE_STATUS_ACTION";