import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Select, Button, Input, Form, message, Spin } from "antd";
import _ from "lodash";
import {
  getretailers,
  getdistributers,
  getmaster,
  updateibomarket,
  ibo_supervisor,
  ibo_manager,
} from "../../../store/actions/documentActions";

const { Option } = Select;

class EditMarkter extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    // state
    this.state = {
      loading: false,
      spinloader: true,
      distributor: [],
      master: [],
      retailer: [],
      market: [],
      ibo_manager: [],
      ibo_supervisor: [],
      super_user: [],
      marketrole: false,
    };
  }

  componentWillMount() {
    const marketer = this.props.user.role_id.filter((word) => word === 12);
    if (marketer.length !== 0) {
      this.setState({
        marketrole: true,
      });
    }
    console.log(this.props.location.market);
    if (this.props.location.market === undefined) {
      this.props.history.push("/marketers");
    } else {
      this.setState({
        market: this.props.location.market,
      });
      this.props
        .ibo_manager(this.props.location.market.retailer_id)
        .then((res) => {
          console.log(res);
          this.setState({
            ibo_manager: res.data,
            spinloader: false,
          });
        });
      this.props
        .ibo_supervisor(this.props.location.market.retailer_id)
        .then((res) => {
          console.log(res);
          this.setState({
            ibo_supervisor: res.data,
          });
        });
    }
  }

  handleSubmit = (values) => {
    this.setState({
      loading: true,
    });
    this.props
      .updateibomarket(values, this.state.market)
      .then((res) => {
        message.success("IBO/Market updated successfully");
        this.formRef.current.resetFields();
        this.setState({
          loading: false,
        });
        this.props.history.push("/marketers");
      })
      .catch((err) => {
        message.error(err);
        this.setState({
          loading: false,
        });
      });
  };

  renderMaster() {
    return _.map(this.state.master, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderDistributor() {
    return _.map(this.state.distributor, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderRetailer() {
    return _.map(this.state.retailer, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  rendermarket_manager() {
    return _.map(this.state.ibo_manager, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  rendermarket_supervisor() {
    return _.map(this.state.ibo_supervisor, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  render() {
    const { loading, market, spinloader } = this.state;

    return (
      <>
        <Spin spinning={spinloader}>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                    <strong>Edit IBO/Market</strong>
                  </h4>
                  <Link to="/marketers" className="float-right">
                    <Button type="default">Back</Button>
                  </Link>
                </div>
                <div className="card-body">
                  <Form
                    name="basic"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    onFinish={this.handleSubmit}
                    onFinishFailed={this.onFinishFailed}
                    ref={this.formRef}
                    className="addbookform row"
                    requiredMark
                  >
                    {this.state.marketrole === false ? (
                      <Form.Item
                        label="Retailer"
                        className="col-md-4 col-sm-6 col-xs-12"
                        name="RetailerName"
                        initialValue={market.retailer_id}
                        rules={[
                          {
                            required: true,
                            message: "Please input Retailer",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select Retailer"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.renderRetailer()}
                        </Select>
                      </Form.Item>
                    ) : null}
                    <Form.Item
                      label="IBO / Marketer"
                      className="col-md-4 col-sm-6 col-xs-12"
                      name="iboname"
                      initialValue={market.name}
                      rules={[
                        {
                          required: true,
                          message: "Please input IBO / Marketer",
                        },
                      ]}
                    >
                      <Input placeholder="Enter IBO / Marketer" />
                    </Form.Item>
                    <Form.Item
                      label="IBO Manager"
                      className="col-md-4 col-sm-6 col-xs-12"
                      name="manager_id"
                      initialValue={market.get_manager_info.map(
                        (market) => market.user_id
                      )}
                      rules={[
                        {
                          required: false,
                          message: "Please Select IBO Manager",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        mode="multiple"
                        style={{ width: "100%" }}
                        placeholder="Select IBO Manager"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }

                        // onChange={(z) => this.setState({ hubs: _.filter(this.state.allhubs, ["Zone", z]) })}
                      >
                        {this.rendermarket_manager()}
                      </Select>
                    </Form.Item>
                    {/* <Form.Item
                      label="IBO Add on Manager"
                      className="col-md-4 col-sm-6 col-xs-12"
                      name="supervisor_id"
                      initialValue={market.get_supervisor_info.map(
                        (market) => market.user_id
                      )}
                      rules={[
                        {
                          required: false,
                          message: "Please Select IBO Add on Manager",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        mode="multiple"
                        style={{ width: "100%" }}
                        placeholder="Select IBO Add on Manager"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }

                        // onChange={(z) => this.setState({ hubs: _.filter(this.state.allhubs, ["Zone", z]) })}
                      >
                        {this.rendermarket_supervisor()}
                      </Select>
                    </Form.Item> */}

                    <br />
                    <br />
                    <div className="col-md-12 col-sm-12 col-xs-12 padd15">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        loading={loading}
                      >
                        Update IBO/Market
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.auth.auth, data: state.data };
}
export default connect(mapStateToProps, {
  getretailers,
  getdistributers,
  getmaster,
  updateibomarket,
  ibo_supervisor,
  ibo_manager,
})(EditMarkter);
