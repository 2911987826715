/* eslint-disable react-hooks/exhaustive-deps */
import {
  Table,
  Tag,
  Button,
  Select,
  Input,
  DatePicker,
  message,
  Empty,
  Spin,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import qs from "qs";
import { useEffect, useState } from "react";
import {
  SalesSummaryReport,
  getofficelistname,
  detailed_sales_report_search,
  exportsalesreportlist,
} from "../../../store/actions/DeviceActions";

import { connect, useDispatch } from "react-redux";
import moment from "moment";
import "antd/dist/antd.min.css";
import axiosInstance from "../../../services/AxiosAdmin";
const { RangePicker } = DatePicker;
const { Option } = Select;
const SalesReport = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [btnloading, setbtnLoading] = useState(false);
  const [selectedstatus, setselectedstatus] = useState([]);
  const [startValue, setstartValue] = useState("");
  const [endValue, setendValue] = useState("");
  const [keyword, setkeyword] = useState("");
  const [onboarding, setonboarding] = useState([]);
  const [loadingEPbtn, setLoadingEPbtn] = useState(false);
  const [pickerKey, setPickerKey] = useState(0);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [selectedItems, setselactedItems] = useState([]);
  const [fdata, setfdata] = useState([]);
  const [fetching, setfetching] = useState(false);

  const dispatch = useDispatch();

  const disabledDate = (current) => {
    // Can not select days after today

    const weekStart = moment().subtract(31, "days");
    const weekEnd = moment().endOf("day");
    return !(weekStart.isSameOrBefore(current) && weekEnd.isAfter(current));
  };

  useEffect(() => {
    fetchData();
    dispatch(getofficelistname())
      .then((res) => {
        console.log(res.data);
        setonboarding(res.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);
console.log(startValue,endValue)
  const columns = [
    {
      title: "Office Name",
      dataIndex: "office_name",
      key: "office_name",
      sorter: true,
    },
    {
      title: "Agent Name",
      dataIndex: "agent_name",
      key: "agent_name",
    },

    {
      title: "Agent Promo Code",
      dataIndex: "agent_promo_code",
      key: "agent_promo_code",
      sorter: true,
    },
    {
      title: "Enrollment Number",
      dataIndex: "enroll_id",
      key: "enroll_id",
      sorter: true,
    },
    {
      title: "ETC Code",
      dataIndex: "excess_code",
      key: "excess_code",
    },
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
      sorter: true,
    },
    {
      title: "Pallet",
      dataIndex: "pallet",
      key: "pallet",
    },
    {
      title: "Carton",
      dataIndex: "carton",
      key: "carton",
    },
    {
      title: "Order Date",
      dataIndex: "order_date",
      key: "order_date",
      sorter: true,
    },
    {
      title: "Activation Date",
      dataIndex: "activation_date",
      key: "activation_date",
      sorter: true,
    },

    // {
    //   title: "Received Date",
    //   dataIndex: "received_date",
    //   key: "received_date",
    //   sorter: true,
    // },
    {
      title: "Plan Name",
      dataIndex: "plan_name",
      key: "plan_name",
    },
    {
      title: "Device Type",
      dataIndex: "product_type",
      key: "product_type",
    },
  ];
  const getRandomuserParams = (params) => ({
    results: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
  });
  const fetchData = () => {
    setLoading(true);
    dispatch(SalesSummaryReport(getRandomuserParams(tableParams)))
      .then((res) => {
        if (res.status === 200) {
          console.log(res.status);
          console.log(res.data.data);
          setData(res.data.data.data);
          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              total: res.data.data.total,
              // 200 is mock data, you should read it from server
              // total: data.totalCount,
            },
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    setTimeout(() => {
      searchonboardingreport(" ", {
        pagination,
        filters,
        ...sorter,
      });
    }, 100);
  };
  const onChange = (dates, dateStrings) => {
    if (dates) {
      console.log("From: ", dates[0], ", to: ", dates[1]);
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setstartValue(dateStrings[0]);
      setendValue(dateStrings[1]);
    } else {
      console.log("Clear");
    }
  };
  const onChangeSelect = (value) => {
    setselectedstatus(value);
  };
  const searchonboardingreport = (reset, params) => {
    setLoading(true);
    setbtnLoading(true);
    // var sort_by = sortOrder;
    console.log(reset, params);
    var status = selectedItems.key;
    var search_text = keyword;
    // var office_id = selectedItems.key;
    var sort = params.order === undefined ? "" : params.field;
    var sort_by =
      params.order === "ascend"
        ? "ASC"
        : params.order === undefined
        ? ""
        : "DESC";
    var date_range_start = startValue;
    var date_range_end = endValue;

    var page = reset === "reset" ? 1 : params.pagination?.current ?? 1;
    console.log(reset);
    const obj = {
      status: status,
      search_text: search_text,
      page: page,
      sort,
      sort_by,
      date_range_start,
      date_range_end,
    };
    dispatch(detailed_sales_report_search(obj))
      .then((res) => {
        console.log(res.data.data.data);
        setData(res.data.data.data);
        setLoading(false);
        setbtnLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.data.total,
            current: res.data.data.current_page,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setbtnLoading(false);
      });
  };

  const clearSearch = () => {
    setPickerKey(prevKey => prevKey + 1);
    setLoading(true);
    setstartValue(null);
    setendValue(null);
    setkeyword("");
    setselectedstatus([]);
    setselactedItems([]);
    fetchData();
    setTableParams({
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });
  };

  const getexportbtn = () => {
    setLoading(true);
    setLoadingEPbtn(true);
    var status = selectedItems.key;
    var search_text = keyword;
    var date_range_start = startValue;
    var date_range_end = endValue;
    const obj = {
      status: status,
      search_text: search_text,
      date_range_start,
      date_range_end,
    };
    console.log(obj);
    dispatch(exportsalesreportlist(obj))
      .then((res) => {
        setLoading(false);
        setLoadingEPbtn(false);
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingEPbtn(false);
      });
  };

  const onchangeRole = (selectedItems) => {
    console.log(selectedItems);
    setselactedItems(selectedItems);
  };
  const fetchUser = (value) => {
    console.log("fetching user", value);
    setfetching(false);
    setfdata([]);
    if (value.length > 1) {
      // setfdata([])
      setfetching(true);
      // this.setState({ fdata: [], fetching: true });
      axiosInstance
        .get("/api/v1/reference/offices?term=" + value)
        .then((response) => {
          console.log(response.data);
          const fdata1 = response.data.map((user) => ({
            text: user.name,
            value: user.id,
          }));
          // console.log(this);
          // this.setState({ fdata, fetching: false });
          setfdata(fdata1);
          setfetching(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">
              {/* <strong>Detailed Report</strong> */}
              {" "}
              Total Records{" "}
                {tableParams.pagination.total &&
                  `(${tableParams.pagination.total})`}
            </h4>
            <div className="col-sm-2 text-right">
              <Button
                type="primary"
                className="mr5"
                loading={loadingEPbtn}
                onClick={getexportbtn}
              >
                <UploadOutlined style={{ fontSize: "14px" }} /> Export Report
              </Button>
            </div>
          </div>
          <div className="row mt-2" style={{ padding: "15px" }}>
            <div className="col">
              <label style={{ font: "caption" }}>Select office</label>
              <Select
                showSearch
                style={{ width: "100%" }}
                labelInValue
                placeholder="Type 2 characters to search..!"
                notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
                filterOption={false}
                onSearch={fetchUser}
                defaultActiveFirstOption={false}
                value={selectedItems}
                onChange={onchangeRole}
              >
                {fdata?.map((d) => (
                  <Option key={d.value}>{d.text}</Option>
                ))}
              </Select>
              {/* <Select
                style={{ width: "100%" }}
                showSearch
                placeholder="Type office"
                value={selectedstatus}
                optionFilterProp="children"
                onChange={onChangeSelect}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={(onboarding || []).map((d) => ({
                  value: d.id,
                  label: d.name,
                }))}
              ></Select> */}
            </div>
            <div className="col">
              <label style={{ font: "caption" }}>Activation Date</label>
              <RangePicker
                key={pickerKey}
                format="MM-DD-YYYY"
                style={{ width: "100%" }}
                ranges={{
                  Today: [moment(), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                }}
                onChange={onChange}
                disabledDate={disabledDate}
              />
            </div>
            <div className="col">
              <label style={{ font: "caption" }}>Search By Keyword</label>
              <Input
                placeholder="Type keyword"
                value={keyword}
                onChange={(e) => setkeyword(e.target.value)}
                style={{ width: "100%", display: "block" }}
              />
            </div>
            <div className="col mt-4">
              <Button
                type="primary"
                className="mr5"
                loading={btnloading}
                onClick={() => searchonboardingreport("reset", "")}
              >
                Search
              </Button>
              <Button
                style={{ marginLeft: "10px" }}
                type="default"
                onClick={clearSearch}
              >
                Clear
              </Button>
            </div>
          </div>
          <div className="card-body">
            <Table
              scroll={{ x: true }}
              columns={columns}
              rowKey={(record) => record.key}
              dataSource={data}
              pagination={tableParams.pagination}
              loading={loading}
              onChange={handleTableChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SalesReport;
