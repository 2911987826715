/* eslint-disable react-hooks/exhaustive-deps */
import {
  Table,
  Tag,
  Button,
  Select,
  Input,
  DatePicker,
  message,
  Empty,
  Spin,
  Drawer,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import qs from "qs";
import _ from "lodash";
import { useEffect, useState } from "react";
import {
  // SalesSummaryReport,
  getofficelistname,
  // detailed_sales_report_search,
  InventoryDetailReport,
  exportinventorydetailreportlist,
  getinventorystatus,
} from "../../../store/actions/DeviceActions";

import { connect, useDispatch } from "react-redux";
import moment from "moment";
import "antd/dist/antd.min.css";
import { useLocation } from "react-router-dom";
import axiosInstance from "../../../services/AxiosAdmin";

const { RangePicker } = DatePicker;
const { Option } = Select;
const InventoryDetailedReport = (props) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [btnloading, setbtnLoading] = useState(false);
  const [selectedstatus, setselectedstatus] = useState([]);
  // const [startValue, setstartValue] = useState("");
  // const [endValue, setendValue] = useState("");
  const [keyword, setkeyword] = useState("");
  const [onboarding, setonboarding] = useState([]);
  const [loadingEPbtn, setLoadingEPbtn] = useState(false);
  const [inventorystatus, setinventorystatus] = useState([]);

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const [selectedItems, setselactedItems] = useState([]);
  const [AgentselectedItems, setAgentselactedItems] = useState([]);
  const [fdata, setfdata] = useState([]);
  const [fdataAgent, setfdataAgent] = useState([]);

  const [fetching, setfetching] = useState(false);
  const [fAdata, setfAdata] = useState([]);
  const [Afetching, setAfetching] = useState(false);

  const [startValue, setStartValue] = useState(null);
  const [endValue, setEndValue] = useState(null);
  const [endOpen, setEndOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndtDate] = useState(null);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const search = useLocation().search;
  useEffect(() => {
    const status = new URLSearchParams(search).get("status");
    setselectedstatus(parseInt(status) || null);
    console.log(status);
  }, []);

  const disabledStartDate = (startValue) => {
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  const disabledEndDate = (endValue) => {
    // if (!endValue || !startValue) {
    //   return false;
    // }
    // return endValue.valueOf() <= startValue.valueOf();
    return endValue && endValue > moment().endOf("day");
  };

  const onStartChange = (value, date) => {
    console.log(value);
    setStartValue(value);
    setStartDate(date);
  };

  const onEndChange = (value, date) => {
    setEndValue(value);
    setEndtDate(date);
  };

  const handleStartOpenChange = (open) => {
    if (!open) {
      setEndOpen(true);
    }
  };

  const handleEndOpenChange = (open) => {
    setEndOpen(open);
  };

  useEffect(() => {
    fetchData();
    dispatch(getinventorystatus())
      .then((res) => {
        console.log("drop status", res);
        setinventorystatus(res.data);
      })
      .catch((err) => {});
    dispatch(getofficelistname())
      .then((res) => {
        console.log(res.data);
        setonboarding(res.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const renderorderstatus = () => {
    return _.map(inventorystatus, (master, index) => {
      // console.log(master);
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  };

  const columns = [
    // {
    //   title: "Retailer",
    //   dataIndex: "retailer_name",
    //   key: "retailer_name",
    //   sorter: true,
    // },
    // {
    //   title: "IBO / Markets ",
    //   dataIndex: "ibo_name",
    //   key: "ibo_name",
    //   sorter: true,
    // },
    {
      title: "Office ",
      dataIndex: "office_name",
      key: "office_name",
      sorter: true,
    },

    {
      title: "Agent Name",
      dataIndex: "agent_name",
      key: "agent_name",
      sorter: true,
    },

    {
      title: "Manufacturer",
      dataIndex: "make",
      key: "make",
      sorter: true,
    },
    {
      title: "IBO Name",
      dataIndex: "ibo_name",
      key: "ibo_name",
      sorter: true,
    },
    {
      title: "Enrollment Number",
      dataIndex: "enroll_id",
      key: "enroll_id",
    },
    {
      title: "ETC Code",
      dataIndex: "excess_code",
      key: "excess_code",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      sorter: true,
    },
    {
      title: "Pallet",
      dataIndex: "pallet",
      key: "pallet",
      sorter: true,
    },
    {
      title: "Carton",
      dataIndex: "carton",
      key: "carton",
      sorter: true,
    },
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
      sorter: true,
    },
    {
      title: "Device Type",
      dataIndex: "device_type",
      key: "device_type",
    },
    {
      title: "Status",
      dataIndex: "status_name",
      key: "status_name",
      render: (text, record) => (
        <span>
          <Tag color="orange">{record.status_name}</Tag>
        </span>
      ),
    },

    {
      title: "Allocation Date",
      dataIndex: "allocation_date",
      key: "allocation_date",
      sorter: true,
    },
    {
      title: "Action",
      key: "action",
      width: 180,
      fixed: "right",
      render: (text, record) => (
        <span>
          <Button onClick={() => showDrawer(record)} type="primary">
            View Device Log
          </Button>
        </span>
      ),
    },
  ];
  const getRandomuserParams = (params) => ({
    results: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
    status: new URLSearchParams(search).get("status"),
  });
  const fetchData = () => {
    setLoading(true);
    dispatch(InventoryDetailReport(getRandomuserParams(tableParams)))
      .then((res) => {
        if (res.status === 200) {
          console.log(res.status);
          console.log(res.data.items.data);
          setData(res.data.items.data);
          setTableParams({
            ...tableParams,
            pagination: {
              ...tableParams.pagination,
              total: res.data.items.total,
              // 200 is mock data, you should read it from server
              // total: data.totalCount,
            },
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    setTimeout(() => {
      searchonboardingreport(" ", {
        pagination,
        filters,
        ...sorter,
      });
    }, 100);
  };
  const onChange = (dates, dateStrings) => {
    if (dates) {
      console.log("From: ", dates[0], ", to: ", dates[1]);
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      // setstartValue(dateStrings[0]);
      // setendValue(dateStrings[1]);
    } else {
      console.log("Clear");
    }
  };
  // const onChangeSelect = (value) => {
  //   setselectedstatus(value);
  // };
  const searchonboardingreport = (reset, params) => {
    console.log(selectedstatus);

    setLoading(true);
    // setbtnLoading(true);
    // var sort_by = sortOrder;
    console.log(reset, params);
    var status = selectedstatus;
    var search_text = keyword;
    var office_id = selectedItems.key;
    var agent_id = AgentselectedItems.value;
    var sort = params.order === undefined ? "" : params.field;
    var sort_by =
      params.order === "ascend"
        ? "ASC"
        : params.order === undefined
        ? ""
        : "DESC";
    var date_range_start = startDate;
    var date_range_end = endDate;

    var page = reset === "reset" ? 1 : params.pagination?.current ?? 1;
    console.log(reset);
    console.log(agent_id);
    const obj = {
      status: status,
      search_text: search_text,
      page: page,
      sort,
      sort_by,
      date_range_start,
      date_range_end,
      office_id,
      agent_id,
    };
    dispatch(InventoryDetailReport(obj))
      .then((res) => {
        console.log(res.data.items.data);
        setData(res.data.items.data);
        setLoading(false);
        setbtnLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.items.total,
            current: res.data.items.current_page,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setbtnLoading(false);
      });
  };

  const clearSearch = () => {
    setLoading(true);
    setStartValue(null);
    setEndValue(null);
    setStartDate(null);
    setEndtDate(null);
    setkeyword("");
    setselectedstatus([]);
    setselactedItems([]);
    setAgentselactedItems([]);
    setfdata([]);
    fetchData();
    setTableParams({
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });
    setTimeout(() => {
      console.log("Start Value:", startValue);
      console.log("End Value:", endValue);
    }, 100);
  };

  const getexportbtn = () => {
    setLoading(true);
    setLoadingEPbtn(true);
    var status = selectedstatus;
    var search_text = keyword;
    var date_range_start = startValue;
    var date_range_end = endValue;
    var office_id = selectedItems.key;
    var agent_id = AgentselectedItems.value;
    var sort = sort;
    var sort_by = sort_by;

    const obj = {
      status: status,
      search_text: search_text,
      sort,
      sort_by,
      date_range_start,
      date_range_end,
      office_id,
      agent_id,
    };
    console.log(obj);
    dispatch(exportinventorydetailreportlist(obj))
      .then((res) => {
        setLoading(false);
        setLoadingEPbtn(false);
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingEPbtn(false);
      });
  };

  const onchangeRole = (selectedItems) => {
    console.log(selectedItems);
    setselactedItems(selectedItems);
  };
  const onchangeAgentRole = (AgentselectedItems) => {
    console.log(AgentselectedItems);
    setAgentselactedItems(AgentselectedItems);
  };
  const onchangeorderfilter = (selectedstatus) => {
    setselectedstatus(selectedstatus);
  };

  const fetchUser = (value) => {
    console.log("fetching user", value);
    setfetching(false);
    setfdata([]);
    if (value.length > 1) {
      // setfdata([])
      setfetching(true);
      // this.setState({ fdata: [], fetching: true });
      axiosInstance
        .get("/api/v1/reference/offices?term=" + value)
        .then((response) => {
          console.log(response.data);
          const fdata1 = response.data.map((user) => ({
            text: user.name,
            value: user.id,
          }));
          // console.log(this);
          // this.setState({ fdata, fetching: false });
          setfdata(fdata1);
          setfetching(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const AgentfetchUser = (value) => {
    console.log("fetching user", value);
    setfetching(false);
    if (value.length > 1) {
      setfdata([]);
      setfetching(true);
      // this.setState({ fdata: [], fetching: true });
      axiosInstance
        .get("/api/v1/userlist_based_on_role/agent?term=" + value)
        .then((response) => {
          console.log(response.data);
          const fdata = response.data.map((user) => ({
            text: user.name,
            value: user.id,
          }));
          // console.log(this);
          // this.setState({ fdata, fetching: false });
          setfdataAgent(fdata);
          setfetching(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                {/* <strong>Inventory Detailed Report</strong> */} Inventory
                Detailed Report{" "}
                {tableParams.pagination.total &&
                  `(${tableParams.pagination.total})`}
              </h4>
              <div className="col-sm-2 text-right">
                <Button
                  type="primary"
                  className="mr5"
                  loading={loadingEPbtn}
                  onClick={getexportbtn}
                >
                  <UploadOutlined style={{ fontSize: "14px" }} /> Export Report
                </Button>
              </div>
            </div>
            <div className="row mt-2" style={{ padding: "15px" }}>
              <div className="col-sm-12 col-md-4">
                <label style={{ font: "caption" }}>Select office</label>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  labelInValue
                  placeholder="Type 2 characters to search..!"
                  notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
                  filterOption={false}
                  onSearch={fetchUser}
                  defaultActiveFirstOption={false}
                  value={selectedItems}
                  onChange={onchangeRole}
                >
                  {fdata?.map((d) => (
                    <Option key={d.value}>{d.text}</Option>
                  ))}
                </Select>
                {/* <Select
                style={{ width: "100%" }}
                showSearch
                placeholder="Type office"
                value={selectedstatus}
                optionFilterProp="children"
                onChange={onChangeSelect}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={(onboarding || []).map((d) => ({
                  value: d.id,
                  label: d.name,
                }))}
              ></Select> */}
              </div>
              <div className="col-sm-12 col-md-4">
                <label style={{ font: "caption" }}>Select Agent</label>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  labelInValue
                  placeholder="Type 2 characters to search..!"
                  notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
                  filterOption={false}
                  onSearch={AgentfetchUser}
                  defaultActiveFirstOption={false}
                  value={AgentselectedItems}
                  onChange={onchangeAgentRole}
                >
                  {fdataAgent?.map((d) => (
                    <Option key={d.value}>{d.text}</Option>
                  ))}
                </Select>
              </div>
              <div className="col-sm-12 col-md-4">
                <label style={{ font: "caption" }}>Select status</label>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select status"
                  value={selectedstatus}
                  onChange={onchangeorderfilter}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {renderorderstatus()}
                </Select>
              </div>

              <div className="col-sm-12 col-md-4  mt-4">
                <label style={{ font: "caption" }}>Start Date</label>
                <DatePicker
                  style={{ width: "100%" }}
                  disabledDate={disabledStartDate}
                  value={startValue}
                  format="MM-DD-YYYY"
                  placeholder="Select Date"
                  onChange={onStartChange}
                  onOpenChange={handleStartOpenChange}
                />
              </div>
              <div className="col-sm-12 col-md-4  mt-4">
                <label style={{ font: "caption" }}>End Date</label>
                <DatePicker
                  style={{ width: "100%" }}
                  disabledDate={disabledEndDate}
                  value={endValue}
                  format="MM-DD-YYYY"
                  placeholder="Select Date"
                  onChange={onEndChange}
                  open={endOpen}
                  onOpenChange={handleEndOpenChange}
                />
              </div>

              {/* <div className="col-2"> */}
              {/* <RangePicker
                format="MM-DD-YYYY"
                style={{ width: "100%" }}
                ranges={{
                  Today: [moment(), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                }}
                onChange={onChange}
                disabledDate={disabledDate}
              /> */}
              {/* </div> */}
              <div className="col-sm-12 col-md-4 mt-4">
                <label style={{ font: "caption" }}>Search By Keyword</label>
                <Input
                  placeholder="Type keyword"
                  value={keyword}
                  onChange={(e) => setkeyword(e.target.value)}
                  style={{ width: "100%", display: "block" }}
                />
              </div>
              <div className="col-sm-12 col-md-12 mt-5">
                <Button
                  type="primary"
                  className="mr5"
                  loading={btnloading}
                  onClick={() => searchonboardingreport("reset", "")}
                >
                  Search
                </Button>
                <Button
                  style={{ marginLeft: "10px" }}
                  type="default"
                  onClick={clearSearch}
                >
                  Clear
                </Button>
              </div>
            </div>
            <div className="card-body">
              <Table
                scroll={{ x: true }}
                columns={columns}
                rowKey={(record) => record.key}
                dataSource={data}
                pagination={tableParams.pagination}
                loading={loading}
                onChange={handleTableChange}
              />
            </div>
          </div>
        </div>
      </div>
      <Drawer
        title="Device Log"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <Empty />
      </Drawer>
    </>
  );
};
export default InventoryDetailedReport;
