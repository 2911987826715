import {
  GET_DEVICE_ACTION,
  ACCEPT_DEVICE_ACTION,
  INVENTORY_DEVICE_ACTION,
  TRANSFER_DEVICE_ACTION,
  PICKUP_DEVICE_ACTION,
  SCAN_DEVICE_ACTION,
  GET_DEVICE_TRANSACTION,
  DOCUMENT_GET_ACTION,
  AGENT_POST_ACTION,
  OFFICE_POST_ACTION,
  RECEIVE_INVENTORY_DEVICE_ACTION,
  GET_OFFICE_LIST,
  RMA_INVENTORY_DEVICE_ACTION,
  RMA_PENDING_INVENTORY_DEVICE_ACTION,
  RMA_SUBMIT_INVENTORY_DEVICE_ACTION,
  RMA_APPROVED_INVENTORY_DEVICE_ACTION,
  RMA_GET_ACTION,
  ORDER_APPROVAL_STATUS_ACTION,
  ORDER_STATUS_ACTION,
  IBO_MARKETERS_ACTION,
  RMA_ORDER_STATUS_ACTION,
  RMA_DEVICE_STATUS_ACTION
} from "../actions/DeviceTypes";

const initialState = {
  devices: [],
  Accept: [],
  inventory: [],
  rmaDevices: [],
  pendingRmaDevices: [],
  submitRmaDevices: [],
  approvedRmaDevices: [],
  transfer: [],
  scandevice: [],
  pickupdevice: [],
  transaction: [],
  documents: [],
  agents: [],
  office: [],
  otheroffice: [],
  receiveinventory: [],
  rmareport: [],
  orderapprovalstatus: [],
  orderstatus: [],
  ibomarketers: [],
  rmaorderstatus:[],
  rmadevicestatus:[],
};

export default function DeviceReducer(state = initialState, actions) {
  if (actions.type === GET_DEVICE_ACTION) {
    return {
      ...state,
      devices: actions.payload,
    };
  }
  if (actions.type === GET_OFFICE_LIST) {
    return {
      ...state,
      otheroffice: actions.payload,
    };
  }
  if (actions.type === RECEIVE_INVENTORY_DEVICE_ACTION) {
    return {
      ...state,
      receiveinventory: actions.payload,
    };
  }
  if (actions.type === OFFICE_POST_ACTION) {
    return {
      ...state,
      office: actions.payload,
    };
  }
  if (actions.type === ACCEPT_DEVICE_ACTION) {
    return {
      ...state,
      Accept: actions.payload,
    };
  }
  if (actions.type === INVENTORY_DEVICE_ACTION) {
    return {
      ...state,
      inventory: actions.payload,
    };
  }
  if (actions.type === RMA_INVENTORY_DEVICE_ACTION) {
    return {
      ...state,
      rmaDevices: actions.payload,
    };
  }
  if (actions.type === RMA_PENDING_INVENTORY_DEVICE_ACTION) {
    return {
      ...state,
      pendingRmaDevices: actions.payload,
    };
  }
  if (actions.type === RMA_APPROVED_INVENTORY_DEVICE_ACTION) {
    return {
      ...state,
      approvedRmaDevices: actions.payload,
    };
  }
  if (actions.type === RMA_SUBMIT_INVENTORY_DEVICE_ACTION) {
    return {
      ...state,
      submitRmaDevices: actions.payload,
    };
  }
  if (actions.type === TRANSFER_DEVICE_ACTION) {
    return {
      ...state,
      transfer: actions.payload,
    };
  }
  if (actions.type === PICKUP_DEVICE_ACTION) {
    return {
      ...state,
      pcikupdevice: actions.payload,
    };
  }
  if (actions.type === SCAN_DEVICE_ACTION) {
    return {
      ...state,
      scandevice: actions.payload,
    };
  }
  if (actions.type === GET_DEVICE_TRANSACTION) {
    return {
      ...state,
      transaction: actions.payload,
    };
  }
  if (actions.type === DOCUMENT_GET_ACTION) {
    return {
      ...state,
      documents: actions.payload,
    };
  }
  if (actions.type === AGENT_POST_ACTION) {
    return {
      ...state,
      agents: actions.payload,
    };
  }
  if (actions.type === RMA_GET_ACTION) {
    return {
      ...state,
      rmareport: actions.payload,
    };
  }
  if (actions.type === ORDER_APPROVAL_STATUS_ACTION) {
    return {
      ...state,
      orderapprovalstatus: actions.payload,
    };
  }
  if (actions.type === ORDER_STATUS_ACTION) {
    return {
      ...state,
      orderstatus: actions.payload,
    };
  }
  if (actions.type === IBO_MARKETERS_ACTION) {
    return {
      ...state,
      ibomarketers: actions.payload
    };
  }

  if (actions.type === RMA_ORDER_STATUS_ACTION) {
    return {
      ...state,
      rmaorderstatus: actions.payload,
    };
  }
  if (actions.type === RMA_DEVICE_STATUS_ACTION) {
    return {
      ...state,
      rmadevicestatus: actions.payload
    };
  }
  
  return state;
}
