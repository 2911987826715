import React, { useState, useEffect, useRef } from "react";
import { OfficeDeviceAction } from "../../../store/actions/DeviceActions";
import { connect, useDispatch } from "react-redux";
import { office_manager } from "../../../store/actions/documentActions";
// import { SearchOutlined } from "@ant-design/icons";
import { UploadOutlined } from "@ant-design/icons";
// import Highlighter from "react-highlight-words";
import {
  Table,
  Space,
  Input,
  Button,
  Spin,
  Select,
  message,
  Empty,
} from "antd";
import {
  searchofficesaleslist,
  manageragentslist,
  getofficelistname,
  exportOfficeDeviceAction,
  InventoryManager,
  inventory_summary_manager,
} from "../../../store/actions/DeviceActions";
import axiosInstance from "../../../services/AxiosAdmin";

const { Option } = Select;

const MyManager = (props) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [devicestat, setDevicestat] = useState("");
  const [loading, setLoading] = useState(true);
  const [btnloading, setbtnLoading] = useState(false);
  const [staus, setStaus] = useState();
  const [officeList, setOfficeList] = useState([]);
  const [onboarding, setonboarding] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [loadingEPbtn, setLoadingEPbtn] = useState(false);
  const [data, setData] = useState();
  const [manager, setManager] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [selectedItems, setselactedItems] = useState([]);
  const [fdata, setfdata] = useState([]);
  const [fetching, setfetching] = useState(false);
  //console.log(props.device
  const dispatch = useDispatch();
  useEffect(() => {
    fetchData(tableParams.pagination);
    dispatch(office_manager())
      .then((res) => {
        console.log(res.data);
        setManager(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getRandomuserParams = (params) => ({
    results: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
  });

  const fetchData = (params) => {
    console.log(params);
    setLoading(true);
    dispatch(InventoryManager(params))
      .then((res) => {
        setData(res.data.items.data);
        setDevicestat(res.data.grand_totals);
        console.log(res.data.items.current);
        // setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.items.total,
            current: res.data.items.current,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const handleTableChange = (pagination, filters, sorter) => {
    console.log("page", pagination);
    console.log("filter", filters);
    console.log("sorter", sorter);
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
    setTimeout(() => {
      searchonboardingreport(" ", {
        pagination,
        filters,
        ...sorter,
      });
    }, 100);
  };
  const columns = [
    {
      title: "Manager",
      dataIndex: "office_manager_name",
      key: "office_manager_name",
      sorter: true,
    },
    {
      title: "In-Stock",
      dataIndex: "in_stock",
      key: "in_stock",
      sorter: true,
    },
    {
      title: "Sold(Last 30 days)",
      dataIndex: "sold_30_days",
      key: "sold_30_days",
      sorter: true,
    },
    {
      title: "Manager Pending",
      dataIndex: "manager_pending",
      key: "manager_pending",
      sorter: true,
    },
    {
      title: "Manager Received",
      dataIndex: "manager_assigned",
      key: "manager_assigned",
      sorter: true,
    },
    {
      title: "Agent Pending",
      dataIndex: "agent_pending",
      key: "agent_pending",
      sorter: true,
    },
    {
      title: "Agent Transfer",
      dataIndex: "agent_transfer",
      key: "agent_transfer",
    },
    {
      title: "Agent Received",
      dataIndex: "agent_assigned",
      key: "agent_assigned",
      sorter: true,
    },
  ];

  const searchonboardingreport = (reset, params) => {
    setLoading(true);
    var retailer = selectedItems.value;
    var page = reset === "reset" ? 1 : params.pagination?.current ?? 1;
    var sort = params?.order === undefined ? "" : params?.field;
    var sort_by =
      params?.order === "ascend"
        ? "ASC"
        : params?.order === undefined
        ? ""
        : "DESC";

    fetchData({
      current: page,
      sort,
      sort_by,
      retailer: retailer,
    });
  };
  const renderOffice = () => {
    return manager.map((master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  };
  const clearSearch = () => {
    fetchData(tableParams.pagination);
    setselactedItems([]);
  };
  const getexportbtn = () => {
    setLoading(true);
    setLoadingEPbtn(true);
    var manager_id = selectedItems.value;
    console.log(manager_id);
    dispatch(inventory_summary_manager({ manager_id }))
      .then((res) => {
        setLoading(false);
        setLoadingEPbtn(false);
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingEPbtn(false);
      });
  };

  const onchangeRole = (selectedItems) => {
    console.log(selectedItems);
    setselactedItems(selectedItems);
  };

  return (
    <>
      <Spin spinning={loading}>
        <div className="row">
          <div className="col-xl-4 col-sm-6">
            <div className="card gradient-2 card-bx">
              <div className="card-body d-flex align-items-center">
                <div className="me-auto text-white">
                  <h2 className="text-white">{devicestat.in_stock}</h2>
                  <span className="fs-18">In stock</span>
                </div>
                <i style={{ color: "#fff" }} className="fa fa-4x fa-cubes"></i>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-sm-6">
            <div className="card gradient-4 card-bx">
              <div className="card-body d-flex align-items-center">
                <div className="me-auto text-white">
                  <h2 className="text-white">{devicestat.manager_pending}</h2>
                  <span className="fs-18">Manager Pending </span>
                </div>
                <i
                  style={{ color: "#fff" }}
                  className="fa fa-4x fa-luggage-cart"
                ></i>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-sm-6">
            <div className="card gradient-1 card-bx">
              <div className="card-body d-flex align-items-center">
                <div className="me-auto text-white">
                  <h2 className="text-white">{devicestat.sold_30_days}</h2>
                  <span className="fs-18">Sold </span>
                </div>
                <i style={{ color: "#fff" }} className="fa fa-4x fa-cube"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  {/* <strong>By Offices </strong> */} By Managers{" "}
                  {tableParams.pagination.total &&
                    `(${tableParams.pagination.total})`}
                </h4>
                <div className="col-4 d-flex justify-content-end  ">
                  <Button
                    type="primary"
                    className="mr5"
                    loading={loadingEPbtn}
                    onClick={getexportbtn}
                  >
                    <UploadOutlined style={{ fontSize: "14px" }} /> Export
                    Report
                  </Button>
                </div>
              </div>
              <div className="row mt-2" style={{ padding: "15px" }}>
                <div className="col-4">
                  <label>Search Manager</label>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    labelInValue
                    placeholder="Select Option"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    defaultActiveFirstOption={false}
                    value={selectedItems}
                    onChange={onchangeRole}
                  >
                    {renderOffice()}
                  </Select>
                  {/* <Select
                    value={staus}
                    style={{ width: "100%" }}
                    showSearch
                    placeholder="Select office"
                    optionFilterProp="children"
                    onChange={onChangeSelect}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={(onboarding || []).map((d) => ({
                      value: d.id,
                      label: d.name,
                    }))}
                  ></Select> */}
                </div>

                <div className="col-4 mt-6">
                  <Button
                    type="primary"
                    className="mr5"
                    loading={btnloading}
                    onClick={() => searchonboardingreport("reset", "")}
                  >
                    Search
                  </Button>
                  <Button
                    type="default"
                    style={{ marginLeft: "10px" }}
                    onClick={() => clearSearch()}
                  >
                    Clear
                  </Button>
                </div>
              </div>

              <div className="card-body">
                <Table
                  columns={columns}
                  scroll={{
                    x: 1000,
                  }}
                  rowKey={(record) => record.key}
                  dataSource={data}
                  loading={loading}
                  pagination={tableParams.pagination}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    office: state.devicelist.office,
  };
};

export default connect(mapStateToProps)(MyManager);
