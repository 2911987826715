import React, { useState, useEffect, useRef } from "react";
import {
  inventoryDeviceAction,
  Get_agent_list,
  Get_assigndevice,
  exportAssignInventoryAction,
} from "../../../store/actions/DeviceActions";
import { connect, useDispatch } from "react-redux";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { map, get } from "lodash";
import {
  Table,
  Button,
  Drawer,
  List,
  Space,
  Input,
  Select,
  Form,
  Radio,
  message,
} from "antd";

const TransferDevice = (props) => {
  //console.log(props.device);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [selectedcartonKeys, setselectedcartonKeys] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [imeikey, setimeikey] = useState([]);
  const [visibleimei, setvisibleimei] = useState(false);
  const [open, setOpen] = useState(false);
  const [transferopen, settransferopen] = useState(false);
  const [imei, setimei] = useState([]);
  const [carton, setcarton] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [loadingEPbtn, setLoadingEPbtn] = useState(false);
  const [agent, setAgent] = useState(false);
  const searchInput = useRef(null);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setbtnLoading] = useState(false);
  const [search_key, setsearch_key] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [isSwitch, setisSwitch] = useState(false);

  useEffect(() => {
    dispatch(Get_agent_list())
      .then((res) => {
        console.log(res);
        setAgent(res?.data ?? []);
      })
      .catch((err) => {
        setAgent([]);
      });
    dispatch(inventoryDeviceAction())
      .then((res) => {
        console.log(res.data.data);
        setFilteredData(res?.data?.data ?? []);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    localStorage.setItem("sssitems", JSON.stringify(carton));
    setSelectedRowKeys(JSON.parse(localStorage.getItem("sssitems")));
  }, [carton]);

  function compareAndRemoveDuplicates(arr1, arr2, property) {
    const uniqueSet = new Set();
    // Add entire objects to the set (ensuring uniqueness based on the specified property)
    arr1.forEach((obj) => uniqueSet.add(obj));
    arr2.forEach((obj) => uniqueSet.add(obj));
    // Create an array directly from the set (no need for JSON.parse)
    const uniqueObjects = Array.from(uniqueSet);
    return uniqueObjects;
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedRowKeys(selectedRowKeys);
      setselectedcartonKeys((prev) => {
        return compareAndRemoveDuplicates(
          [
            ...(isSwitch || search_key
              ? prev?.filter((p) => !p.key.includes(search_key))
              : []),
          ],
          selectedRows,
          "key"
        );
      });
      setcarton((prev) => {
        console.log("prev", prev, selectedRowKeys);
        return [
          ...new Set([
            ...(isSwitch || search_key
              ? prev?.filter((p) => !p.includes(search_key))
              : []),
            ...selectedRowKeys,
          ]),
        ];
      });
      setisSwitch(false);
    },
  };

  const columns = [
    {
      title: "Carton Number",
      dataIndex: "carton_number",
      key: "carton_number",
    },
    {
      title: "IMEI Count",
      dataIndex: "imei_count",
      key: "imei_count",
    },
    {
      title: "Device Type",
      dataIndex: "device_type",
      key: "device_type",
    },
    {
      title: "Allocation Date",
      dataIndex: "allocation_date",
      key: "allocation_date",
    },
    {
      title: "Age",
      dataIndex: "device_age",
      key: "device_age",
    },
    {
      title: "Office Name",
      dataIndex: "office_name",
      key: "office_name",
    },
    {
      title: "Action",
      dataIndex: "address",
      key: "address",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <Button onClick={() => showDrawer(record)} type="primary">
          view
        </Button>
      ),
    },
  ];

  // rowSelection object indicates the need for row selection

  const showDrawer = (e) => {
    setbtnLoading(true);
    console.log(e);
    setimei(e.imei);
    setOpen(true);
    if (open) {
      setbtnLoading(false);
    }
  };
  const onClose = () => {
    setOpen(false);
  };
  const onClose1 = (e) => {
    form.resetFields();
    settransferopen(false);
    if (e.target.value === 1) {
      setvisibleimei(true);
    } else {
      setvisibleimei(false);
    }
  };

  const transferdevice = () => {
    settransferopen(true);
  };

  const onFinish = (values) => {
    setbtnLoading(true);
    setLoading(true);
    console.log("Success:", values);
    console.log(carton);
    var obj_arr = {
      carton_number: values.transferoption === 2 ? carton.toString() : "",
      imei: values.transferoption === 1 ? values.imeis.toString() : "",
      agent_id: values.agent === undefined ? 0 : values.agent,
      office_id: parseInt(values.officeid),
      devicestatus: values.transferoption === 2 ? 0 : 1,
      assign_count:
        values.transferoption === 2
          ? selectedcartonKeys.length
          : values.imeis.length,
    };

    console.log(obj_arr);

    dispatch(Get_assigndevice(obj_arr))
      .then((res) => {
        console.log(res);
        setbtnLoading(false);
        setLoading(false);
        settransferopen(false);
        setAgent([]);
        setSelectedRowKeys([]);
        dispatch(inventoryDeviceAction())
          .then((res) => {
            console.log(res.data.data);
            setFilteredData(res?.data?.data ?? []);
            setLoading(false);
            localStorage.removeItem("sssitems");
          })
          .catch((err) => {
            setLoading(false);
          });
        form.resetFields();
        dispatch(Get_agent_list())
          .then((res) => {
            console.log(res);
            setAgent(res?.data ?? []);
          })
          .catch((err) => {
            setAgent([]);
          });
        localStorage.removeItem("sssitems");
      })
      .catch((err) => {
        console.log(err);
        setbtnLoading(false);
        setLoading(false);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const hasSelected = selectedRowKeys.length > 0;

  const resetform = () => {
    console.log("Reset Form");
    form.resetFields();
    //setAgent([]);
  };

  const onChangeoffice = (value) => {
    console.log(`selected ${value}`);
    var selected = agent.filter((person) => person.id === value);
    console.log(selected);
    form.setFieldsValue({
      office: selected[0].office_number,
      officeid: selected[0].office_id,
    });
  };

  const chooseoption = (e) => {
    console.log("radio checked", e.target.value);
    if (e.target.value === 1) {
      setvisibleimei(true);
    } else {
      setvisibleimei(false);
    }
  };
  const onChangecarton = (e) => {
    console.log(e);
    var imeiarr = selectedcartonKeys.filter(
      (device) => device.carton_number === e
    );
    console.log(imeiarr[0].imei);
    setimeikey(imeiarr[0].imei);
  };
  const onChangeimei = (e) => {
    console.log(e);
  };

  const getexportbtn = () => {
    setLoading(true);
    setLoadingEPbtn(true);
    const keyword = search_key;
    console.log(keyword);
    dispatch(exportAssignInventoryAction(keyword))
      .then((res) => {
        setLoading(false);
        setLoadingEPbtn(false);
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingEPbtn(false);
      });
  };

  const onSearch = (e) => {
    setbtnLoading(true);
    const reg = new RegExp(e.target.value, "gi");
    // console.log(props.device.device_type)
    const filteredbydate = map(props.device, (record) => {
      const carton_number = get(record, "carton_number").toString().match(reg);
      const office_name = get(record, "office_name").toString().match(reg);
      // const device_type = get(record, "device_type").toString().match(reg);

      if (!office_name && !carton_number) {
        setbtnLoading(false);
        return null;
      }
      setbtnLoading(false);
      return record;
    }).filter((record) => !!record);
    setsearch_key(e.target.value);
    setFilteredData(
      e.target.value || e.target.value.length === 0
        ? filteredbydate
        : filteredData
    );
  };
  const clearSearch = () => {
    setsearch_key("");
    setFilteredData(props?.device ?? []);
  };
  const clearTable = () => {
    setLoading(true);
    setSelectedRowKeys([]);
    setselectedcartonKeys([]);
    setcarton([]);
    dispatch(inventoryDeviceAction())
      .then((res) => {
        console.log(res.data.data);
        setFilteredData(res.data.data);
        setLoading(false);
        localStorage.removeItem("sssitems");
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">
                {/* <strong>Assign Inventory</strong> */} Assign Inventory{" "}
                {filteredData.length && `(${filteredData.length})`}
              </h4>
              <div className="d-flex align-items-center justify-content-end">
                <Button
                  type="primary"
                  className="mr5"
                  loading={loadingEPbtn}
                  onClick={getexportbtn}
                >
                  <UploadOutlined style={{ fontSize: "14px" }} /> Export Report
                </Button>
                {hasSelected ? (
                  <div
                    style={{
                      padding: 8,
                      // float: 'right',
                      textAlign: "right",
                    }}
                  >
                    <Button onClick={transferdevice} type="primary">
                      Assign
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="card-body">
              <div className="row bg-white padd0 align-items-center mb-4">
                <div className="col ">
                  <label for="exampleFormControlInput1">
                    Search by keyword
                  </label>
                  <Input
                    // type="number"
                    placeholder="Search by keyword"
                    value={search_key}
                    onChange={onSearch}
                    // onChange={(e) => setsearch_key(e.target.value)}
                    style={{ width: "100%", display: "block" }}
                  />
                </div>
                <div className="col mt-6">
                  <Button
                    type="primary"
                    style={{ marginLeft: "10px" }}
                    onClick={clearSearch}
                  >
                    Clear
                  </Button>
                </div>
              </div>
              <div
                style={{
                  marginBottom: 16,
                }}
              >
                <Button
                  type="primary"
                  onClick={clearTable}
                  disabled={!hasSelected}
                  loading={loading}
                >
                  Reload
                </Button>
                <span
                  style={{
                    marginLeft: 8,
                  }}
                >
                  {hasSelected
                    ? `Selected (${selectedRowKeys.length}) Carton`
                    : ""}
                </span>
              </div>
              <Table
                columns={columns}
                dataSource={filteredData}
                loading={loading}
                rowSelection={{ ...rowSelection }}
                scroll={{
                  x: 1000,
                }}
              />
            </div>
          </div>
        </div>
        <Drawer
          title="IMEI List"
          placement="right"
          onClose={onClose}
          open={open}
        >
          <List
            bordered
            dataSource={imei}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        </Drawer>
        <Drawer
          title="Assign Agent"
          placement="right"
          onClose={onClose1}
          open={transferopen}
        >
          <Form
            form={form}
            name="basic"
            initialValues={{
              transferoption: 2,
            }}
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Do you want to Transfer ? "
              name="transferoption"
              rules={[
                {
                  required: true,
                  message: "Please select option",
                },
              ]}
            >
              <Radio.Group onChange={chooseoption}>
                <Radio value={1}>IMEIs</Radio>
                <Radio value={2}>CARTONS</Radio>
              </Radio.Group>
            </Form.Item>
            {visibleimei && (
              <Form.Item
                label="Cartons"
                name="cartons"
                rules={[
                  {
                    required: true,
                    message: "Please select cartons",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Select cartons"
                  optionFilterProp="children"
                  onChange={onChangecarton}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={(selectedcartonKeys || []).map((d) => ({
                    value: d.carton_number,
                    label: d.carton_number,
                  }))}
                />
              </Form.Item>
            )}
            {visibleimei && (
              <Form.Item
                label="IMEIs"
                name="imeis"
                rules={[
                  {
                    required: true,
                    message: "Please select IMEIs",
                  },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  mode="multiple"
                  placeholder="Select IMEIs"
                  optionFilterProp="children"
                  onChange={onChangeimei}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={(imeikey || []).map((d) => ({
                    value: d,
                    label: d,
                  }))}
                />
              </Form.Item>
            )}
            <Form.Item
              label="Agent"
              name="agent"
              rules={[
                {
                  required: true,
                  message: "Please select option",
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                onChange={onChangeoffice}
                placeholder="Select agent"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={(agent || []).map((d) => ({
                  value: d.id,
                  label: d.full_name,
                }))}
              />
            </Form.Item>
            <Form.Item
              label="Office"
              name="office"
              rules={[
                {
                  required: true,
                  message: "Please input office",
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              style={{ visibility: "hidden" }}
              label="Officeid"
              name="officeid"
              rules={[
                {
                  required: true,
                  message: "Please input office",
                },
              ]}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 0,
                span: 16,
              }}
            >
              <Space>
                <Button type="primary" htmlType="submit" loading={btnLoading}>
                  Submit
                </Button>

                <Button type="dashed" onClick={resetform}>
                  Clear
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Drawer>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    device: state.devicelist.inventory,
  };
};

export default connect(mapStateToProps)(TransferDevice);
