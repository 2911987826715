import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Select, Button, Input, Form, message, Spin } from "antd";
import _ from "lodash";
import {
  getretailers,
  createibomarket,
  ibo_supervisor,
  ibo_manager,
} from "../../../store/actions/documentActions";

const { Option } = Select;

class AddMarkter extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    // state
    this.state = {
      loading: false,
      spinloader: false,
      distributor: [],
      master: [],
      retailer: [],
      market: [],
      ibo_manager: [],
      ibo_supervisor: [],
      super_user: [],
      marketrole: false,
    };
  }

  componentWillMount() {
    const marketer = this.props.user.role_id.filter((word) => word === 12);
    if (marketer.length !== 0) {
      this.setState({
        marketrole: true,
      });
    }
  }

  handleSubmit = (e) => {
    this.setState({
      loading: true,
    });

    this.props
      .createibomarket(e)
      .then((res) => {
        message.success("IBO/Market created successfully");
        this.formRef.current.resetFields();
        this.setState({
          loading: false,
        });
        this.props.history.push("/marketers");
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        message.error(err);
      });
  };

  renderMaster() {
    return _.map(this.state.master, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderDistributor() {
    return _.map(this.state.distributor, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderRetailer() {
    return _.map(this.state.retailer, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }

  rendermarket_manager() {
    return _.map(this.state.ibo_manager, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  rendermarket_supervisor() {
    return _.map(this.state.ibo_supervisor, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }

  render() {
    const { loading, spinloader } = this.state;

    return (
      <>
        <Spin spinning={spinloader}>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                    <strong>Add IBO/Market</strong>
                  </h4>
                  <Link to="/marketers" className="float-right">
                    <Button type="default">Back</Button>
                  </Link>
                </div>
                <div className="card-body">
                  <Form
                    name="basic"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    onFinish={this.handleSubmit}
                    onFinishFailed={this.onFinishFailed}
                    ref={this.formRef}
                    className="addbookform row"
                    requiredMark
                  >
                    {this.state.marketrole === false ? (
                      <Form.Item
                        label="Retailer"
                        className="col-md-4 col-sm-6 col-xs-12"
                        name="RetailerName"
                        rules={[
                          {
                            required: true,
                            message: "Please input Retailer",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select Retailer"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          // onChange={(e) => this.handleretailer(e)}
                        >
                          {this.renderRetailer()}
                        </Select>
                      </Form.Item>
                    ) : null}
                    <Form.Item
                      label="IBO / Market Name"
                      className="col-md-4 col-sm-6 col-xs-12"
                      name="iboname"
                      rules={[
                        {
                          required: true,
                          message: "Please input IBO / Market Name",
                        },
                      ]}
                    >
                      <Input placeholder="Enter IBO name" />
                    </Form.Item>

                    <br />
                    <br />
                    <div className="col-md-12 col-sm-12 col-xs-12 padd15">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        loading={loading}
                      >
                        Add IBO/Market
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.auth.auth, data: state.data };
}

export default connect(mapStateToProps, {
  getretailers,
  createibomarket,
  ibo_supervisor,
  ibo_manager,
})(AddMarkter);
