export const CREATE_POST_ACTION = "[Post Action] Create Post";
export const CONFIRMED_CREATE_POST_ACTION =
  "[Post Action] Confirmed Create Post";
export const CONFIRMED_GET_POSTS = "[Post Action] Confirmed Get Posts";
export const EDIT_POST_ACTION = "[Post Action] Edit Post";
export const CONFIRMED_EDIT_POST_ACTION = "[Post Action] Confirmed Edit Post";
export const CONFIRMED_DELETE_POST_ACTION =
  "[Post Action] Confirmed Delete Post";

export const CONFIRMED_HOME_ACTIONS = "CONFIRMED_HOME_ACTIONS";
