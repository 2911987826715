import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { DropzoneArea } from "material-ui-dropzone";
import { UploadimageAction } from "../../../store/actions/DeviceActions";

function App() {
  const [data, setData] = React.useState("");
  const [dropzoneKey, setDropzoneKey] = React.useState(0);
  const dispatch = useDispatch();

  const handleChange = (files) => {
    console.log(files);
    setData(files);
  };

  const handletransfer = () => {
    const formData = new FormData();
    formData.append("imei_image", data[0]);
    console.log(data[0], formData);
    dispatch(UploadimageAction(formData));
    setDropzoneKey(dropzoneKey + 1);
  };
  return (
    <>
      <DropzoneArea
        key={"dropzone-" + dropzoneKey}
        filesLimit={1}
        acceptedFiles={["image/*"]}
        onChange={handleChange}
        maxFileSize={50000000}
      />
      {data.length !== 0 && (
        <button
          onClick={handletransfer}
          type="submit"
          className="btn btn-primary mt-5"
        >
          Upload Image
        </button>
      )}
    </>
  );
}

export default App;