/* eslint-disable react-hooks/exhaustive-deps */
import { Table, Tag, Button, DatePicker, Input, message } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, useLocation } from "react-router-dom";
import {
  agent_summary_report,
  searchagentsummarylist,
  exportsearchagentsummarylist,
  getagentstatus,
} from "../../../store/actions/DeviceActions";
import { connect, useDispatch } from "react-redux";
import { UploadOutlined } from "@ant-design/icons";
import "antd/dist/antd.min.css";
const { RangePicker } = DatePicker;
const AgentReport = () => {
  let location = useLocation();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [btnloading, setbtnLoading] = useState(false);
  const [startValue, setstartValue] = useState("");
  const [endValue, setendValue] = useState("");
  const [keyword, setkeyword] = useState("");
  const [filteredInfo, setFilteredInfo] = useState({});
  const [loadingEPbtn, setLoadingEPbtn] = useState(false);
  const [pickerKey, setPickerKey] = useState(0);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(location);
    const urlString = location?.state?.urlpath ?? "";
    fetchData({
      url: urlString ?? "",
      results: 10,
      page: 1,
    });
  }, []);

  const columns = [
    {
      title: "Office",
      dataIndex: "office_number",
      key: "office_number",
      sorter: true,
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      sorter: true,
    },
    {
      title: "Middle Name",
      dataIndex: "middle_name",
      key: "middle_name",
      sorter: true,
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "ETC Code",
      dataIndex: "excess_code",
      key: "excess_code",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },

    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Zip",
      dataIndex: "zip",
      key: "zip",
    },

    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status_name",
      key: "status_name",
      filteredValue: filteredInfo.status_name || null,
      filters: [
        { text: "Active", value: "1" },
        { text: "In-Active", value: "0" },
        { text: "Removed", value: "2" },
        { text: "Suspended", value: "4" },
        { text: "Do Not Rehire", value: "5" },
        { text: "Terminated", value: "6" },
        { text: "No office", value: "8" },
      ],
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <span>
          <Tag color={record.status === 1 ? "green" : "orange"}>
            {/* {record.status === 1 ? "Active" : "In-Active"} */}
            {record.status === 1
              ? "Active"
              : record.status === 0
              ? "In-Active"
              : record.status === 2
              ? "Removed"
              : record.status === 4
              ? "Suspended"
              : record.status === 5
              ? "Do Not Rehire"
              : record.status === 8
              ? "No office"
              : "Terminated"}
          </Tag>
        </span>
      ),
    },
    {
      title: "Telgoo Status",
      dataIndex: "telgoo_status",
      key: "telgoo_status",

      fixed: "right",
      width: 100,
      render: (text, record) => (
        <span>
          <Tag color={record.telgoo_status === "Inactive" ? "orange" : "green"}>
            {record.telgoo_status}
          </Tag>
        </span>
      ),
    },
  ];

  const getRandomuserParams = (params) => ({
    results: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
  });
  const fetchData = (params = {}) => {
    setLoading(true);
    dispatch(agent_summary_report(getRandomuserParams(params)))
      .then((res) => {
        setLoading(false);
        console.log(res.data.data);
        setData(res.data.data.data);
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.data.total,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    console.log(filters);
    setFilteredInfo(filters);
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    setTimeout(() => {
      searchonboardingreport("", {
        pagination,
        filters,
        ...sorter,
      });
    }, 1000);
  };
  const onChange = (dates, dateStrings) => {
    if (dates) {
      console.log("From: ", dates[0], ", to: ", dates[1]);
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setstartValue(dateStrings[0]);
      setendValue(dateStrings[1]);
    } else {
      console.log("Clear");
      setstartValue("");
      setendValue("");
    }
  };
  const searchonboardingreport = (reset, params) => {
    setLoading(true);
    setbtnLoading(true);
    console.log(filteredInfo);
    var search_text = keyword;
    var status =
      reset === "reset"
        ? filteredInfo?.status_name
        : params?.filters?.status_name ?? "";

    var sort = params.order === undefined ? "" : params.field;
    var sort_by =
      params.order === "ascend"
        ? "ASC"
        : params.order === undefined
        ? ""
        : "DESC";
    var date_range_start = startValue;
    var date_range_end = endValue;

    var page = reset === "reset" ? 1 : params.pagination?.current ?? 1;

    dispatch(
      searchagentsummarylist({
        search_text: search_text,
        page: page,
        sort,
        sort_by,
        date_range_start,
        date_range_end,
        status,
      })
    )
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data.data);
        setLoading(false);
        setbtnLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.data.total,
            current: res.data.data.current_page,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setbtnLoading(false);
      });
  };
  const clearSearch = () => {
    setPickerKey((prevKey) => prevKey + 1);
    setLoading(true);
    setstartValue(null);
    setendValue(null);
    setkeyword("");
    fetchData();
    setFilteredInfo({});
  };

  const getexportbtn = () => {
    setLoading(true);
    setLoadingEPbtn(true);
    var date_range_start = startValue;
    var date_range_end = endValue;
    var search_text = keyword;
    var status = filteredInfo?.status_name ?? "";
    dispatch(
      exportsearchagentsummarylist({
        search_text: search_text,
        date_range_start,
        date_range_end,
        status,
      })
    )
      .then((res) => {
        setLoading(false);
        setLoadingEPbtn(false);
        console.log(res);
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingEPbtn(false);
      });
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">
              {/* <strong>Agent Summary </strong> */} Agent Summary{" "}
              {tableParams.pagination.total &&
                `(${tableParams.pagination.total})`}
            </h4>
            <div className="col-4 d-flex justify-content-end ">
              <Button
                type="primary"
                className="mr5"
                loading={loadingEPbtn}
                onClick={getexportbtn}
              >
                <UploadOutlined style={{ fontSize: "14px" }} /> Export Report
              </Button>
            </div>
          </div>
          <div className="row mt-2" style={{ padding: "15px" }}>
            <div className="col">
              <label>Select Date</label>
              <RangePicker
                key={pickerKey}
                format="MM-DD-YYYY"
                disabledDate={disabledDate}
                style={{ width: "100%" }}
                onChange={onChange}
              />
            </div>

            <div className="col">
              <label>Search by keyword</label>
              <Input
                placeholder="Search by keyword"
                value={keyword}
                // ref={(node) => {
                //   this.searchInput = node;
                // }}
                onChange={(e) => setkeyword(e.target.value)}
                style={{ width: "100%", display: "block" }}
              />
            </div>
            <div className="col mt-6">
              <Button
                type="primary"
                className="mr5"
                loading={btnloading}
                onClick={() => searchonboardingreport("reset", "")}
              >
                Search
              </Button>
              <Button
                style={{ marginLeft: "10px" }}
                type="default"
                onClick={clearSearch}
              >
                Clear
              </Button>
            </div>
          </div>
          <div className="card-body">
            <Table
              columns={columns}
              rowKey={(record) => record.key}
              dataSource={data}
              scroll={{ x: true }}
              pagination={tableParams.pagination}
              loading={loading}
              onChange={handleTableChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AgentReport;
