import React, { useState, useEffect, useRef } from "react";
import { map, get } from "lodash";
import {
  receiveinventoryDeviceAction,
  receiveinventoryDeviceStatus,
  receiveinventoryUpdate,
  exportReceiveInventoryAction,
} from "../../../store/actions/DeviceActions";
import { connect, useDispatch } from "react-redux";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  Table,
  Button,
  Drawer,
  List,
  Space,
  Input,
  Checkbox,
  Tooltip,
  Modal,
  message,
} from "antd";
import { logDOM } from "@testing-library/react";

const { confirm } = Modal;

const ReceiveInventory = (props) => {
  //console.log(props.device);
  const [open, setOpen] = useState(false);
  const [job, setJob] = useState([]);
  const [btnload, setbtnload] = useState(false);
  const [dbtnload, setdbtnload] = useState(false);
  const [checked, setChecked] = useState([]);
  const [isAll, setIsAll] = useState(false);
  const [carton, setcarton] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [search_key, setsearch_key] = useState(null);
  const [loadingEPbtn, setLoadingEPbtn] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const searchInput = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(receiveinventoryDeviceAction())
      .then((res) => {
        console.log(res.payload);
        setFilteredData(res?.payload ?? []);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "Date",
      dataIndex: "assigned_date",
      key: "assigned_date",
    },
    {
      title: "OFFICE",
      dataIndex: "office_name",
      key: "office_name",
    },
    {
      title: "# of Carton",
      dataIndex: "total_cartons_count",
      key: "total_cartons_count",
    },
    {
      title: "# of Devices",
      dataIndex: "total_device_count",
      key: "total_device_count",
    },
    {
      title: "Action",
      dataIndex: "address",
      key: "address",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <Button onClick={() => showDrawer(record)} type="primary">
          Select
        </Button>
      ),
    },
  ];

  // rowSelection object indicates the need for row selection

  const showDrawer = (e) => {
    setJob(e);
    setLoading(true);
    console.log(e);
    var obj_arr = {
      job_id: e.id,
    };
    dispatch(receiveinventoryDeviceStatus(obj_arr))
      .then((res) => {
        console.log(res);
        setcarton(res.data);
        setOpen(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    // setimei(e.imei);
  };
  const onClose = () => {
    setOpen(false);
    setIsAll(false);
  };

  const onAccept = (stat) => {
    var obj_arr = {
      carton_numbers: checked,
      job_id: job.id,
      status: stat === true ? 1 : 2,
    };
    confirm({
      title: "Are you sure ? ",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      content:
        stat === true
          ? // ? "Are you received this carton ?"
            "  You would like to receive this carton ?"
          : // : "Are you sure did not receive this carton ?",
            "  You don't want to receive this carton ?",
      onOk() {
        console.log(`obj_arr`, obj_arr);
        if (stat === true) {
          setbtnload(true);
          setLoading(true);
        } else {
          setbtnload(false);
          setLoading(false);
          setdbtnload(true);
        }

        dispatch(receiveinventoryUpdate(obj_arr))
          .then((res) => {
            console.log(res);

            setOpen(false);
            setIsAll(false);
            // dispatch(receiveinventoryDeviceAction());
            dispatch(receiveinventoryDeviceAction())
              .then((res) => {
                console.log(res.payload);
                setFilteredData(res?.payload ?? []);
                setLoading(false);
                setbtnload(false);
                setdbtnload(false);
              })
              .catch((err) => {
                console.log(err);
                setLoading(false);
                setbtnload(false);
                setdbtnload(false);
              });
            setChecked([]);
            // setbtnload(false);
            setLoading(false);
          })

          .catch((err) => {
            console.log(err);
            setbtnload(false);
            setLoading(false);
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleCheckAllChange = (e) => {
    if (!isAll) {
      setChecked(carton.map((c) => c.carton_number));
      setIsAll(true);
    } else {
      setChecked([]);
      setIsAll(false);
    }
  };

  const getexportbtn = () => {
    setLoading(true);
    setLoadingEPbtn(true);
    const keyword = search_key;
    console.log(keyword);
    dispatch(exportReceiveInventoryAction(keyword))
      .then((res) => {
        setLoading(false);
        setLoadingEPbtn(false);
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingEPbtn(false);
      });
  };

  const onSearch = (e) => {
    setBtnLoading(true);
    const reg = new RegExp(e.target.value, "gi");
    const filteredbydate = map(props.receiveinventory, (record) => {
      const assignedDate = get(record, "assigned_date").toString().match(reg);
      const numOfDevice = get(record, "total_device_count")
        .toString()
        .match(reg);

      if (!numOfDevice && !assignedDate) {
        setBtnLoading(false);
        return null;
      }
      setBtnLoading(false);
      return record;
    }).filter((record) => !!record);
    setsearch_key(e.target.value);
    console.log(e.target.value);
    setFilteredData(
      e.target.value || typeof e.target.value === "string"
        ? filteredbydate
        : filteredData
    );
  };

  const clearSearch = () => {
    setsearch_key("");
    setFilteredData(props?.receiveinventory ?? []);
  };
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">
              {/* <strong>Receive Inventory</strong> */} Receive Inventory{" "}
              {(filteredData.length === 0 || filteredData.length) &&
                `(${filteredData.length})`}
            </h4>
            <Button
              type="primary"
              className="mr5"
              loading={loadingEPbtn}
              onClick={getexportbtn}
            >
              <UploadOutlined style={{ fontSize: "14px" }} /> Export Report
            </Button>
          </div>
          <div className="card-body">
            <div className="row bg-white padd0 align-items-center mb-4">
              <div className="col ">
                <label for="exampleFormControlInput1">Search by keyword</label>
                <Input
                  // type="number"
                  placeholder="Search by keyword"
                  value={search_key}
                  onChange={onSearch}
                  // onChange={(e) => setsearch_key(e.target.value)}
                  style={{ width: "100%", display: "block" }}
                />
              </div>
              <div className="col mt-6">
                {/* <Button
                  type="primary"
                  className="mr5"
                  loading={btnLoading}
                  onClick={onSearch}
                >
                  Search
                </Button> */}
                <Button
                  type="primary"
                  style={{ marginLeft: "10px" }}
                  onClick={() => clearSearch()}
                >
                  Clear
                </Button>
              </div>
            </div>
            <Table
              columns={columns}
              loading={loading}
              dataSource={filteredData}
              scroll={{
                x: 1000,
              }}
            />
          </div>
        </div>
      </div>
      <Drawer
        title="Cartons List"
        placement="right"
        onClose={onClose}
        open={open}
        extra={
          <Space>
            {checked.length === 0 ? (
              ""
            ) : (
              <>
                <Button
                  loading={dbtnload}
                  onClick={() => onAccept(false)}
                  danger
                >
                  Decline
                </Button>
                <Button
                  loading={btnload}
                  onClick={() => onAccept(true)}
                  type="primary"
                >
                  Accept
                </Button>
              </>
            )}
          </Space>
        }
      >
        {open && (
          <>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="selectAll"
                checked={checked.length === carton.length}
                onChange={handleCheckAllChange}
              />
              <label className="form-check-label" htmlFor="selectAll">
                Select all
              </label>
            </div>
            <Checkbox.Group
              style={{ width: "100%" }}
              value={checked}
              onChange={(checkedValues) => {
                console.log(`checkedValues`, checkedValues, "checked", checked);
                setChecked(checkedValues);
                setIsAll(false);
              }}
            >
              <List
                itemLayout="horizontal"
                dataSource={carton}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Tooltip
                        trigger="click"
                        title={item.imei_device.join("\r\n")}
                      >
                        <Button type="dashed">View IMEI</Button>
                      </Tooltip>,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={<Checkbox value={item.carton_number} />}
                      title={item.carton_number}
                      description={"IMEI Count: " + item.imei_count}
                    />
                  </List.Item>
                )}
              />
            </Checkbox.Group>
          </>
        )}
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    receiveinventory: state.devicelist.receiveinventory,
  };
};

export default connect(mapStateToProps)(ReceiveInventory);
