import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import { getDeviceTransaction } from "../../../store/actions/DeviceActions";
import { connect, useDispatch } from "react-redux";
import Chip from "@mui/material/Chip";

const Transaction = (props) => {
  //console.log(props.device);
  const [pagesize, setpagesize] = useState(10);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDeviceTransaction());
  }, [dispatch]);
  const useStyles = makeStyles({
    grid: {
      fontFamily: "poppins, sans-serif !important",
    },
  });
  const classes = useStyles();

  const columns = [
    {
      field: "imei",
      headerName: "IMEI",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "short_description",
      headerName: "Description",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "status",
      headerName: "status",
      width: 200,
      sortable: false,
      headerAlign: "center",
      headerClassName: "bold-text",
      align: "center",
      renderCell: (params) => {
        return (
          <span>
            {params.row.status === 5 ? (
              <Chip label=" In stock" color="primary" />
            ) : params.row.status === 7 ? (
              <Chip label="Transfer" color="primary" />
            ) : params.row.status === 8 ? (
              <Chip label="sold" color="primary" />
            ) : null}
          </span>
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "updated_at",
      headerName: "Date & Time",
      headerClassName: "bold-text",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
  ];

  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">
              <strong>Log History</strong>
            </h4>
          </div>
          <div className="card-body">
            <div style={{ width: "100%" }}>
              <div style={{ display: "flex", height: "100%" }}>
                <div style={{ flexGrow: 1 }}>
                  <DataGrid
                    sx={{
                      ".MuiDataGrid-columnSeparator": {
                        display: "none",
                      },
                    }}
                    autoHeight
                    disableColumnMenu
                    getRowId={(row) => row.imei}
                    rows={props.device}
                    className={classes.grid}
                    columns={columns}
                    pageSize={pagesize}
                    rowsPerPageOptions={[pagesize]}
                    disableSelectionOnClick
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    device: state.devicelist.transaction,
  };
};

export default connect(mapStateToProps)(Transaction);
