import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Select, Button, Input, Form, message, Spin, Modal } from "antd";
import _ from "lodash";
import {
  getretailers,
  getdistributers,
  getmaster,
  getibomarketlistnew,
  updateoffice,
  short_us_states,
  get_inventory_count,
  office_manager_name,
  office_supervisor,
} from "../../../store/actions/documentActions";

const { Option } = Select;

class EditOffice extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    // state
    this.state = {
      distributor: [],
      master: [],
      retailer: [],
      market: [],
      office: [],
      office_manager: [],
      office_supervisor: [],
      spinloader: true,
      super_user: [],
      marketrole: false,
      retailerrole: false,
      shortstate: [],
    };
  }

  componentWillMount() {
    const marketer = this.props.user.role_id.filter((word) => word === 11);
    const Retailer = this.props.user.role_id.filter((word) => word === 12);
    if (marketer.length !== 0) {
      this.setState({
        marketrole: true,
      });
    }
    if (Retailer.length !== 0) {
      this.setState({
        retailerrole: true,
      });
    }
    console.log(this.props.location.office);
    if (this.props.location.office === undefined) {
      this.props.history.push("/offices");
    } else {
      //console.log(result);
      this.setState({
        office: this.props.location.office,
      });
      this.props
        .office_manager_name(this.props.location.office.ibo_marketer_id)
        .then((res) => {
          console.log(res);
          this.setState({
            office_manager: res.data.manager_info,
            office_supervisor: res.data.manager_info,
          });
        });
      this.props.short_us_states().then((res) => {
        console.log(res);
        this.setState({
          shortstate: res.data,
        });
      });

      this.props.getibomarketlistnew(this.props.user.user_id).then((res) => {
        this.setState({
          market: res.data,
          spinloader: false,
        });
      });
    }
  }

  handleSubmit = (values) => {
    this.formRef.current
      .validateFields({ validateOnly: true })
      .then(() => {
        console.log(values);
        this.setState({
          loading: true,
        });
        values.id = this.state.office.id;
        console.log(values);
        this.props
          .updateoffice(values)
          .then((res) => {
            message.success("Office Updated successfully");
            this.formRef.current.resetFields();
            this.setState({
              loading: false,
            });
            this.props.history.push("/offices");
          })
          .catch((err) => {
            message.error(err);
            this.setState({
              loading: false,
            });
          });
      })
      .catch((err) => {
        message.error(err);
        this.setState({
          loading: false,
        });
      });
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  renderMaster() {
    return _.map(this.state.master, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderDistributor() {
    return _.map(this.state.distributor, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderRetailer() {
    return _.map(this.state.retailer, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderMarketer() {
    return _.map(this.state.market, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderoffice_manager() {
    return _.map(this.state.office_manager, (master, index) => {
      return (
        <Option value={master.user_id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderoffice_supervisor() {
    return _.map(this.state.office_supervisor, (master, index) => {
      return (
        <Option value={master.user_id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderstateshort() {
    return _.map(this.state.shortstate, (master, index) => {
      return (
        <Option value={master.short_name} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  GetInventoryCount = () => {
    this.setState({
      spinloader: true,
    });
    this.props
      .get_inventory_count(this.state.office)
      .then((res) => {
        this.setState({
          spinloader: false,
        });
        if (res.data !== 0) {
          Modal.confirm({
            title: "Warning",
            content:
              "Office currently has inventory. New Manager will be responsible for all existing inventory?",
            okText: "Yes",
            cancelText: "No",
            onOk: () => {
              console.log(`Ok`);
            },
            onCancel: () => {
              this.props.history.push("/office");
            },
          });
        }
      })
      .catch((err) => {
        this.setState({
          spinloader: false,
        });
      });
  };
  handleretailer = (e) => {
    console.log(e);
    this.setState({
      spinloader: true,
      office_manager: [],
      office_supervisor: [],
    });
    this.formRef.current.setFieldsValue({
      manager_id: [],
      supervisor_id: [],
    });
    this.props
      .office_manager_name(e)
      .then((res) => {
        console.log(res);
        this.setState({
          office_manager: res.data.manager_info,
          office_supervisor: res.data.manager_info,
          spinloader: false,
        });
      })
      .catch((err) => {
        this.setState({
          office_manager: [],
          office_supervisor: [],
          spinloader: false,
        });
      });
  };

  render() {
    const { loading, spinloader, office } = this.state;

    return (
      <>
        <Spin spinning={spinloader}>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                    <strong>Edit Office</strong>
                  </h4>
                  <Link to="/offices" className="float-right">
                    <Button type="default">Back</Button>
                  </Link>
                </div>
                <div className="card-body">
                  <Form
                    name="basic"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    onFinish={this.handleSubmit}
                    onFinishFailed={this.onFinishFailed}
                    ref={this.formRef}
                    className="addbookform row"
                  >
                    {this.state.retailerrole === true ? (
                      <Form.Item
                        label="IBO Name"
                        className="col-md-4 col-sm-6 col-xs-12"
                        name="ibo_marketer_id"
                        initialValue={
                          this.props.location.office.ibo_marketer_id
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please select",
                          },
                        ]}
                      >
                        <Select
                          disabled
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select IBO Name"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(e) => this.handleretailer(e)}
                        >
                          {this.renderMarketer()}
                        </Select>
                      </Form.Item>
                    ) : null}

                    <Form.Item
                      label="Office Name"
                      className="col-md-4 col-sm-6 col-xs-12"
                      name="officename"
                      initialValue={this.props.location.office.name}
                      rules={[
                        {
                          required: true,
                          message: "Please input office name",
                        },
                      ]}
                    >
                      <Input disabled placeholder="Enter Office name" />
                    </Form.Item>
                    <Form.Item
                      label="Office Manager"
                      className="col-md-4 col-sm-6 col-xs-12"
                      name="manager_id"
                      initialValue={
                        this.props.location.office.get_manager_info.length !== 0
                          ? this.props.location.office.get_manager_info[0]
                              .user_id
                          : ""
                      }
                      rules={[
                        {
                          required: false,
                          message: "Please Select Office Manager",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Select Office Manager"
                        onChange={() => this.GetInventoryCount()}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }

                        // onChange={(z) => this.setState({ hubs: _.filter(this.state.allhubs, ["Zone", z]) })}
                      >
                        {this.renderoffice_manager()}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Office Add-on Manager"
                      className="col-md-4 col-sm-6 col-xs-12"
                      name="supervisor_id"
                      initialValue={this.props.location.office.get_supervisor_info.map(
                        (market) => market.user_id
                      )}
                      rules={[
                        {
                          required: false,
                          message: "Please Select Office Add-on Manager",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        mode="multiple"
                        style={{ width: "100%" }}
                        placeholder="Select Office Add-on Manager"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(z) => console.log(z)}
                      >
                        {this.renderoffice_supervisor()}
                      </Select>
                    </Form.Item>

                    <br />
                    <br />
                    <div className="col-md-12 col-sm-12 col-xs-12 padd15">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        loading={loading}
                      >
                        Update Office
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.auth.auth, data: state.data };
}
export default connect(mapStateToProps, {
  getretailers,
  getdistributers,
  getmaster,
  getibomarketlistnew,
  updateoffice,
  office_manager_name,
  office_supervisor,
  short_us_states,
  get_inventory_count,
})(EditOffice);
