/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Tree, Input } from "antd";
import { DownOutlined } from "@ant-design/icons";
// import Data from "./trainingDocument.json";
const { TreeNode } = Tree;
const { Search } = Input;

function TrainingComponent() {
  const user = useSelector((state) => state.auth.auth);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [OFFM, setOFFM] = useState();
  const [RIM, setRIM] = useState();
  const [IIM, setIIM] = useState();
  const treeRef = useRef(null);
  const treeNodesRef = useRef([]);
  const Data = [
    {
      category: "Agent",
      data: [
        {
          id: 1,
          isCollapsed: true,
          title: "Dashboard",
          content: "Agent Dashboard Training Module",
          link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/Agent/Agent+Dashboard.pdf",
        },
        {
          id: 2,
          isCollapsed: true,
          title: "Inventory RMA",
          content: "Agent Inventory RMA Training Module",
          link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/Agent/Agent+Inventory+_+RMA.pdf",
        },
        {
          id: 3,
          isCollapsed: true,
          title: "Sales Report",
          content: "Agent Sales Report Training Module",
          link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/Agent/Agent+Sales+Report.pdf",
        },
      ],
    },
    OFFM === 3
      ? {}
      : {
          category: "IBO Inventory Manager",
          data: [
            {
              id: 4,
              isCollapsed: true,
              title: "Dashboard",
              content: "IBO Inventory Manager Dashboard Training Module",
              link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/IBO+Inventory+Manager/IBO+Inventory+Manager+Dashboard.pdf",
            },
            {
              id: 5,
              isCollapsed: true,
              title: "Inventory",
              content: "IBO Inventory Manager Inventory Training Module",
              link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/IBO+Inventory+Manager/IBO+Inventory+Manager+Inventory.pdf",
            },
            {
              id: 6,
              isCollapsed: true,
              title: "Manage Agents",
              content: "IBO Inventory Manager Manage Agents Training Module",
              link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/IBO+Inventory+Manager/IBO+Inventory+Manager+Manage+Agents.pdf",
            },
            {
              id: 7,
              isCollapsed: true,
              title: "RMA Orders",
              content: "IBO Inventory Manager RMA Orders Training Module",
              link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/IBO+Inventory+Manager/IBO+Inventory+Manager+RMA+_+Orders.pdf",
            },
            {
              id: 8,
              isCollapsed: true,
              title: "Sale Report",
              content: "IBO Inventory Manager Sale Report Training Module",
              link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/IBO+Inventory+Manager/IBO+Inventory+Manager+Sale+Report.pdf",
            },
          ],
        },
    OFFM === 3
      ? {}
      : {
          category: "IBO Manager",
          data: [
            {
              id: 9,
              isCollapsed: true,
              title: "Administration",
              content: "IBO Manager Administration Training Module",
              link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/IBO+Manager/IBO+Manager+Administration.pdf",
            },
            {
              id: 10,
              isCollapsed: true,
              title: "Dashboard",
              content: "IBO Manager Dashboard Training Module",
              link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/IBO+Manager/IBO+Manager+Dashboard.pdf",
            },
            {
              id: 11,
              isCollapsed: true,
              title: "Inventory",
              content: "IBO Manager Inventory Training Module",
              link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/IBO+Manager/IBO+Manager+Inventory.pdf",
            },
            {
              id: 12,
              isCollapsed: true,
              title: "Onboarding",
              content: "IBO Manager Onboarding Training Module",
              link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/IBO+Manager/IBO+Manager+Onboarding.pdf",
            },
            {
              id: 13,
              isCollapsed: true,
              title: "Sales Report",
              content: "IBO Manager Sales Report Training Module",
              link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/IBO+Manager/IBO+Manager+Sale+Report.pdf",
            },
          ],
        },
    {
      category: "Office Manager",
      data: [
        {
          id: 14,
          isCollapsed: true,
          title: "Dashboard",
          content: "Office Manager Dashboard Training Module",
          link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/Office+Manager/Office+Manager+Dashboard.pdf",
        },
        {
          id: 15,
          isCollapsed: true,
          title: "Inventory",
          content: "Office Manager Inventory Training Module",
          link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/Office+Manager/Office+Manager+Inventory.pdf",
        },
        {
          id: 16,
          isCollapsed: true,
          title: "Manage Agents",
          content: "Office Manager Manage Agents Training Module",
          link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/Office+Manager/Office+Manager+Manage+Agents.pdf",
        },
        {
          id: 17,
          isCollapsed: true,
          title: "RMA Orders",
          content: "Office Manager RMA Orders Training Module",
          link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/Office+Manager/Office+Manager+RMA+_+Orders.pdf",
        },
        {
          id: 18,
          isCollapsed: true,
          title: "Sales Report",
          content: "Office Manager Sales Report Training Module",
          link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/Office+Manager/Office+Manager+Sales+Report.pdf",
        },
      ],
    },
    IIM === 11 || OFFM === 3
      ? {}
      : {
          category: "Retailer Inventory Manager",
          data: [
            {
              id: 21,
              isCollapsed: true,
              title: "Dashboard",
              content: "Retailer Inventory Manager Dashboard Training Module",
              link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/Retailer+Inventory+Manager/Retailer+Inventory+Manager+Dashboard.pdf",
            },
            {
              id: 22,
              isCollapsed: true,
              title: "Inventory",
              content: "Retailer Inventory Manager Inventory Training Module",
              link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/Retailer+Inventory+Manager/Retailer+Inventory+Manager+Inventory.pdf",
            },
            {
              id: 23,
              isCollapsed: true,
              title: "Manage Agents",
              content:
                "Retailer Inventory Manager Manage Agents Training Module",
              link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/Retailer+Inventory+Manager/Retailer+Inventory+Manager+Manage+Agents.pdf",
            },
            {
              id: 24,
              isCollapsed: true,
              title: "RMA Orders",
              content: "Retailer Inventory Manager RMA Orders Training Module",
              link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/Retailer+Inventory+Manager/Retailer+Inventory+Manager+RMA+_+Orders.pdf",
            },
            {
              id: 25,
              isCollapsed: true,
              title: "Sale Report",
              content: "Retailer Inventory Manager Sale Report Training Module",
              link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/Retailer+Inventory+Manager/Retailer+Inventory+Manager+Sale+Report.pdf",
            },
          ],
        },
    IIM === 11 || OFFM === 3
      ? {}
      : {
          category: "Retailer",
          data: [
            {
              id: 19,
              isCollapsed: true,
              title: "Retailer Administration",
              content: "Retailer Administration Training Module",
              link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/Retailer/Retailer+Administration.pdf",
            },
            {
              id: 20,
              isCollapsed: true,
              title: "Retailer Dashboard",
              content: "Retailer Dashboard Training Module",
              link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/Retailer/Retailer+Dashboard.pdf",
            },
            {
              id: 26,
              isCollapsed: true,
              title: "Retailer Inventory",
              content: "Retailer Inventory Training Module",
              link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/Retailer/Retailer+Inventory.pdf",
            },
            {
              id: 27,
              isCollapsed: true,
              title: "Retailer Onboarding",
              content: "Retailer Onboarding Training Module",
              link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/Retailer/Retailer+Onboarding.pdf",
            },
            {
              id: 28,
              isCollapsed: true,
              title: "Retailer Sales Report",
              content: "Retailer Sales Report Training Module",
              link: "https://wieserp.s3.amazonaws.com/Training_Documents/pdf+document/Retailer/Retailer+Sales+Report.pdf",
            },
          ],
        },
  ];

  const filter = Data.filter((item) => Object.keys(item).length !== 0);

  const onChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    setAutoExpandParent(true);

    const filtered = filter.filter(
      (item) =>
        item.category.toLowerCase().includes(value.toLowerCase()) ||
        item.data.some(
          (subItem) =>
            subItem.title.toLowerCase().includes(value.toLowerCase()) ||
            subItem.content.toLowerCase().includes(value.toLowerCase())
        )
    );
    setFilteredData(filtered);
  };

  useEffect(() => {
    console.log(user.role_id[0]);
    if (user.role_id[0] === 3) {
      setOFFM(user?.role_id[0]);
    }
    if (user.role_id[0] === 11) {
      setIIM(user?.role_id[0]);
    }
    if (user.role_id[0] === 12) {
      setRIM(user?.role_id[0]);
    }
    console.log("ibo", IIM);
    console.log("ret", RIM);
    console.log("offic", OFFM);
  }, []);

  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const renderTreeNodes = (data) =>
    data.map((item) => {
      if (item.data) {
        return (
          <TreeNode
            style={{ fontSize: "18px", marginLeft: "20px", width: "auto" }}
            title={item.category}
            key={item.category}
            ref={(node) => treeNodesRef.current.push(node)}
          >
            {item.data.map((subItem) => (
              <TreeNode
                className="mx-3"
                key={subItem.id}
                title={
                  <div>
                    {/* <p
                      className="mx-3"
                      style={{
                        fontSize: "18px",
                        marginBottom: "10px",
                        color: "#7E775F",
                      }}
                    >
                      {subItem.title}
                    </p> */}
                    <a
                      href={subItem.link}
                      rel="noopener noreferrer"
                      target="_blank"
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      <p
                        style={{ margin: 0, padding: 5 }}
                        onClick={() => {
                          const newWindow = window.open(
                            subItem.link,
                            "_blank",
                            "noopener,noreferrer"
                          );
                          if (newWindow) newWindow.opener = null;
                        }}
                      >
                        {subItem.title}
                      </p>
                    </a>
                  </div>
                }
              />
            ))}
          </TreeNode>
        );
      }
      return <TreeNode title={item.title} key={item.id} />;
    });

  useEffect(() => {
    const expandedKeys = filter.reduce((keys, item) => {
      if (
        item.category.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.data.some(
          (subItem) =>
            subItem.title.toLowerCase().includes(searchValue.toLowerCase()) ||
            subItem.content.toLowerCase().includes(searchValue.toLowerCase())
        )
      ) {
        return [...keys, item.category];
      }
      return keys;
    }, []);
    setExpandedKeys(expandedKeys);
    scrollToClosestMatch();
  }, [searchValue]);

  const scrollToClosestMatch = () => {
    if (treeRef.current) {
      const nodes = treeNodesRef.current;
      let minDistance = Number.MAX_VALUE;
      let closestNode = null;
      nodes.forEach((node) => {
        const text =
          node.props.title.props.children[0].props.children.toLowerCase();
        const distance = text.includes(searchValue.toLowerCase())
          ? text.indexOf(searchValue.toLowerCase())
          : Number.MAX_VALUE;
        if (distance < minDistance) {
          minDistance = distance;
          closestNode = node;
        }
      });

      if (closestNode && closestNode.dom) {
        closestNode.dom.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  return (
    <div>
      <section className="section mb-5 pt-4 pb-5 support_banner">
        <div className="container ">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xl-7 text-center desc">
              <div className=" mb-1 text-white">
                <Search
                  placeholder="Search"
                  onChange={onChange}
                  onPressEnter={(e) => e.preventDefault()}
                  size="large"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Tree
        switcherIcon={<DownOutlined style={{ fontSize: "1.2rem" }} />}
        ref={treeRef}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        onExpand={onExpand}
      >
        {renderTreeNodes(filteredData.length > 0 ? filteredData : filter)}
      </Tree>
    </div>
  );
}

export default TrainingComponent;
