import React, { useState, useEffect, useRef } from "react";
import { AgentDeviceAction } from "../../../store/actions/DeviceActions";
import { UploadOutlined } from "@ant-design/icons";
import { connect, useDispatch } from "react-redux";

import { Table, Spin, Button, Select, message, Empty } from "antd";
import {
  searchagentsaleslist,
  manageragentslist,
  exportAgentDeviceAction,
} from "../../../store/actions/DeviceActions";
import axiosInstance from "../../../services/AxiosAdmin";

const { Option } = Select;

const MyAgent = (props) => {
  const [devicestat, setDevicestat] = useState("");
  const [data, setData] = useState();
  const [staus, setStaus] = useState();
  const [agentList, setAgentList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [btnloading, setbtnLoading] = useState(false);
  const [loadingEPbtn, setLoadingEPbtn] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [selectedItems, setselactedItems] = useState([]);
  const [fdata, setfdata] = useState([]);
  const [fetching, setfetching] = useState(false);
  //console.log(props.device
  const dispatch = useDispatch();
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // dispatch(AgentDeviceAction());
    dispatch(manageragentslist())
      .then((res) => {
        console.log(res.data.data);
        setAgentList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const columns = [
    {
      title: "Agent Name",
      dataIndex: "agent_name",
      key: "agent_name",
      fixed: "left",
    },
    {
      title: "Office",
      dataIndex: "office_name",
      key: "office_name",
    },
    {
      title: "Agent Received",
      dataIndex: "agent_assigned",
      key: "agent_assigned",
    },
    {
      title: "Agent Pending",
      dataIndex: "agent_pending",
      key: "agent_pending",
    },
    {
      title: "Sold",
      dataIndex: "sold_30_days",
      key: "sold_30_days",
    },
    // {
    //   title: "Action",
    //   dataIndex: "address",
    //   key: "address",
    //   fixed: "right",
    //   width: 100,
    //   render: (_, record) => (
    //     <Button onClick={() => showDrawer(record)} type="primary">
    //       view
    //     </Button>
    //   ),
    // },
  ];
  const getRandomuserParams = (params) => ({
    results: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
  });
  const fetchData = () => {
    setLoading(true);
    dispatch(AgentDeviceAction(getRandomuserParams(tableParams)))
      .then((res) => {
        // setLoading(false);
        console.log("fetch response", res.data.data);
        setData(res.data.data.data);
        setDevicestat(res.data.grand_totals);

        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.data.total,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
        setLoading(false);
        if (data.set) {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    setTimeout(() => {
      searchonboardingreport(" ", {
        pagination,
        filters,
        ...sorter,
      });
    }, 100);
  };
  // rowSelection object indicates the need for row selection

  const onChangeSelect = (value) => {
    console.log(`selected ${value}`);
    setStaus(value);
  };
  const searchonboardingreport = (reset, params) => {
    setLoading(true);
    setbtnLoading(true);
    // var search_text = keyword;
    var agent_id = selectedItems.key;
    var page = reset === "reset" ? 1 : params.pagination?.current ?? 1;
    dispatch(
      AgentDeviceAction({
        page: page,
        agent_id,
        // search_text: search_text,
      })
    )
      .then((res) => {
        console.log("search response", res.data.data.data);
        setData(res.data.data.data);
        setDevicestat(res.data.grand_totals);

        setLoading(false);
        setbtnLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.data.total,
            current: res.data.data.current_page,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
        setbtnLoading(false);
      });
  };
  const clearSearch = () => {
    setStaus([]);
    setselactedItems([]);
    setfdata([]);
    fetchData();
    setselactedItems([])
    setfdata([]);
  };

  const getexportbtn = () => {
    setLoading(true);
    setLoadingEPbtn(true);
    const agent_id = selectedItems.key;
    console.log(agent_id);
    dispatch(exportAgentDeviceAction(agent_id))
      .then((res) => {
        setLoading(false);
        setLoadingEPbtn(false);
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingEPbtn(false);
      });
  };
  const fetchUser = (value) => {
    console.log("fetching user", value);
    setfetching(false);
    if (value.length > 1) {
      setfdata([]);
      setfetching(true);
      // this.setState({ fdata: [], fetching: true });
      axiosInstance
        .get("/api/v1/userlist_based_on_role/agent?term=" + value)
        .then((response) => {
          console.log(response.data);
          const fdata1 = response.data.map((user) => ({
            text: user.name,
            value: user.id,
          }));
          // console.log(this);
          // this.setState({ fdata, fetching: false });
          setfdata(fdata1);
          setfetching(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const onchangeRole = (selectedItems) => {
    console.log(selectedItems);
    setselactedItems(selectedItems);
  };
  return (
    <>
      <Spin spinning={loading}>
        <div className="row">
          <div className="col-xl-4 col-sm-6">
            <div className="card gradient-4 card-bx">
              <div className="card-body d-flex align-items-center">
                <div className="me-auto text-white">
                  <h2 className="text-white">
                    {devicestat?.agent_pending ?? 0}
                  </h2>
                  <span className="fs-18">Agent Pending </span>
                </div>
                <i
                  style={{ color: "#fff" }}
                  className="fa fa-4x fa-luggage-cart"
                ></i>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-sm-6">
            <div className="card gradient-2 card-bx">
              <div className="card-body d-flex align-items-center">
                <div className="me-auto text-white">
                  <h2 className="text-white">
                    {devicestat?.agent_assigned ?? 0}
                  </h2>
                  <span className="fs-18">Agent Received</span>
                </div>
                <i style={{ color: "#fff" }} className="fa fa-4x fa-cubes"></i>
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-sm-6">
            <div className="card gradient-1 card-bx">
              <div className="card-body d-flex align-items-center">
                <div className="me-auto text-white">
                  <h2 className="text-white">
                    {devicestat?.sold_30_days ?? 0}
                  </h2>
                  <span className="fs-18">Sold </span>
                </div>
                <i style={{ color: "#fff" }} className="fa fa-4x fa-cube"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  {/* <strong>By Agents</strong> */}
                  {" "}
                  By Agents{" "}
                {tableParams.pagination.total &&
                  `(${tableParams.pagination.total})`}
                </h4>
                <div className="col-4 d-flex justify-content-end ">
                  <Button
                    type="primary"
                    className="mr5"
                    loading={loadingEPbtn}
                    onClick={getexportbtn}
                  >
                    <UploadOutlined style={{ fontSize: "14px" }} /> Export
                    Report
                  </Button>
                </div>
              </div>
              
              <div className="row mt-2" style={{ padding: "15px" }}>
                <div className="col-4">
                  <label>Select Agent</label>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    labelInValue
                    placeholder="Type 2 characters to search..!"
                    notFoundContent={
                      fetching ? <Spin size="small" /> : <Empty />
                    }
                    filterOption={false}
                    onSearch={fetchUser}
                    defaultActiveFirstOption={false}
                    value={selectedItems}
                    onChange={onchangeRole}
                  >
                    {fdata?.map((d) => (
                      <Option key={d.value}>{d.text}</Option>
                    ))}
                  </Select>
                  {/* <Select
                    value={staus}
                    style={{ width: "100%" }}
                    showSearch
                    placeholder="Select agent"
                    optionFilterProp="children"
                    onChange={onChangeSelect}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={(agentList || []).map((d) => ({
                      value: d.id,
                      label: d.full_name,
                    }))}
                  ></Select> */}
                </div>

                <div className="col-4 mt-6">
                  <Button
                    type="primary"
                    className="mr5"
                    loading={btnloading}
                    onClick={() => searchonboardingreport("reset", "")}
                  >
                    Search
                  </Button>
                  <Button
                    type="default"
                    style={{ marginLeft: "10px" }}
                    onClick={() => clearSearch()}
                  >
                    Clear
                  </Button>
                </div>
               
              </div>
              <div className="card-body">
                <Table
                  columns={columns}
                  scroll={{
                    x: 1000,
                  }}
                  dataSource={data}
                  pagination={tableParams.pagination}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    agents: state.devicelist.agents,
  };
};

export default connect(mapStateToProps)(MyAgent);
