import React, { useState, useEffect } from "react";
import {
  rmaInitiateAction,
  GetRMAReasonsAction,
  SubmitRMAAction,
  searchimeilist,
  exportImeilist,
} from "../../../store/actions/DeviceActions";
import { connect, useDispatch } from "react-redux";
import { ExclamationCircleOutlined, UploadOutlined } from "@ant-design/icons";
import _ from "lodash";
import {
  Drawer,
  Space,
  Input,
  Col,
  Form,
  Row,
  Select,
  Button,
  Table,
  Modal,
  message,
} from "antd";
const { Option } = Select;
const { confirm } = Modal;

const InitiateRMA = (props) => {
  //console.log(props.device);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [imei, setimei] = useState([]);
  const [IMEIOpen, setIMEIOpen] = useState(false);
  const [form] = Form.useForm();
  const [imeiList, setImeiList] = useState([]);
  const [rmaReasons, setRmaReasons] = useState([]);
  const [data, setData] = useState([]);
  const [hasSelected, setHasSelected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [btnloading, setbtnloading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [keyword, setkeyword] = useState("");
  const [itemsrow, setItemsrow] = useState([]);
  const [itemsimei, setItemimei] = useState([]);
  const [loadingEPbtn, setLoadingEPbtn] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const dispatch = useDispatch();
  useEffect(() => {
    localStorage.removeItem("items");
    localStorage.removeItem("itemsimei");
    fetchData({
      // url: urlString ?? "",
      results: 10,
      page: 1,
    });
    dispatch(GetRMAReasonsAction())
      .then((res) => {
        setRmaReasons(res.data);
      })
      .catch((ex) => {
        console.log(`error fetching RMA reasons`, ex);
      });
  }, []);
  useEffect(() => {
    var olddata = JSON.parse(localStorage.getItem("items")) ?? [];
    var olddata1 = JSON.parse(localStorage.getItem("itemsimei")) ?? [];
    olddata[tableParams?.pagination?.current - 1] = itemsrow;
    olddata1[tableParams?.pagination?.current - 1] = itemsimei;
    localStorage.setItem("items", JSON.stringify(olddata));
    localStorage.setItem("itemsimei", JSON.stringify(olddata1));
    let selectedrows = [];
    let selectedimei = [];
    olddata.forEach((element) => {
      if (element && element?.length > 0) {
        console.log(element);
        selectedrows = [...selectedrows, ...element];
      }
    });
    olddata1.forEach((element) => {
      if (element && element?.length > 0) {
        console.log(element);
        selectedimei = [...selectedimei, ...element];
      }
    });
    console.log(selectedrows);
    console.log(selectedimei);
    setSelectedRowKeys(selectedrows);
    setItemimei(selectedimei);
    setimei(selectedimei);
    setImeiList(selectedimei);
  }, [itemsrow]);

  const getRandomuserParams = (params) => ({
    results: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
  });
  const fetchData = () => {
    setLoading(true);
    dispatch(rmaInitiateAction(getRandomuserParams(tableParams)))
      .then((res) => {
        // console.log(res);

        setData(res.data.data.data);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.data.total,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    // setItemsrow(JSON.parse(localStorage.getItem("items")));
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
    setTimeout(() => {
      searchonboardingreport(" ", {
        pagination,
        filters,
        ...sorter,
      });
    }, 100);
  };

  const renderReasons = () => {
    return _.map(rmaReasons, (reason, index) => {
      return (
        <Option value={reason.id} key={index}>
          {reason.name}
        </Option>
      );
    });
  };

  const columns = [
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
      align: "center",
      headerAlign: "center",
      width: 200,
    },
    {
      title: "Age (Days)",
      dataIndex: "device_age",
      key: "device_age",
      align: "center",
      headerAlign: "center",
      width: 200,
    },
    {
      dataIndex: "status_name",
      key: "status_name",
      title: "status",
      width: 200,
      // sortable: false,
      headerAlign: "center",
      // headerClassName: "bold-text",
      align: "center",
    },
    {
      dataIndex: "carton",
      key: "carton",
      title: "Carton Number",
      // headerClassName: "bold-text",
      width: 150,
      fixed: "right",
      headerAlign: "center",
      align: "center",
    },
  ];

  const showDrawer = () => {
    setIMEIOpen(true);
    setImeiList(imei);
    // var tempRmaList = [];
    imei.map((imei_item, index) => {
      return form.setFieldsValue({
        [`imei_${index}`]: imei_item,
        [`reason1_${index}`]: "",
        [`reason2_${index}`]: "",
        [`notes_${index}`]: "",
      });
      // return tempRmaList.push({ [`imei_${index}`]: imei_item, 'reason1': "", 'reason2': "", "notes": "" })
    });
    // setRmaList(tempRmaList);
  };
  const onClose = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: "Are you sure you want to cancel initiating the RMA?",
      // content: "You want to cancel initiating RMA?",
      onOk: () => {
        setIMEIOpen(false);
        setImeiList([]);
        setimei([]);
        setHasSelected(false);
        setSelectedRowKeys([]);
        resetform();
        localStorage.removeItem("items");
        localStorage.removeItem("itemsimei");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const resetform = () => {
    form.resetFields();
    imei.map((imei_item, index) => {
      return form.setFieldsValue({
        [`imei_${index}`]: imei_item,
        [`reason1_${index}`]: "",
        [`reason2_${index}`]: "",
        [`notes_${index}`]: "",
      });
      // return tempRmaList.push({ [`imei_${index}`]: imei_item, 'reason1': "", 'reason2': "", "notes": "" })
    });
  };

  const onFinish = (values) => {
    // console.log("Success:", values);
    // const {
    //   form: { validateFields, setFields },
    // } = props;
    // console.log('form.values', form)
    // form.validateFields((err, values) => {
    //   if (!err) {
    // const isAccept = this.props.form.getFieldValue("IsAccept");
    // const reason = this.props.form.getFieldValue("reason");
    // console.log(`reason`, this.props.form.getFieldValue("IsAccept"), this.props.form.getFieldValue("reason"));
    // var params = { id: this.state.record.id, status: isAccept ? 4 : 3 }
    // if (!isAccept) {
    //   params.reason = reason;
    // }
    setbtnloading(true);
    var imei_list = [];
    imei.map((item, index) => {
      return imei_list.push({
        imei: values[`imei_${index}`],
        reason: values[`reason1_${index}`],
        reason_two: values[`reason2_${index}`],
        notes: values[`notes_${index}`],
      });
    });
    // console.log("imei_list", imei_list);
    dispatch(SubmitRMAAction({ imei_list: imei_list }))
      .then((res) => {
        console.log(`response of RMA submit`, res);
        setIMEIOpen(false);
        setSelectedRowKeys([]);
        setimei([]);
        setImeiList([]);
        fetchData({
          // url: urlString ?? "",
          results: 10,
          page: 1,
        });
        // dispatch(rmaInitiateAction());
        // setDropzoneKey(dropzoneKey + 1);
        // reset({ reason: "", imei: "" });
        // history.push("/allrmareport");
        setbtnloading(false);
        localStorage.removeItem("items");
        localStorage.removeItem("itemsimei");
      })
      .catch((err) => {
        //setDropzoneKey(dropzoneKey + 1);
        setIMEIOpen(false);
        setSelectedRowKeys([]);
        setimei([]);
        setImeiList([]);
        setbtnloading(false);
      });
  };

  const onFinishFailed = (values) => {
    console.log("Failed:", values);
  };

  const onRowsSelectionHandler = (ids, selectedrow) => {
    var olddata1 = JSON.parse(localStorage.getItem("itemsimei"));
    // console.log(_.union(olddata1, ids));
    // console.log(selectedrow);
    // setimei(_.union(olddata1, ids));
    // setImeiList(_.union(olddata1, ids));
    setSelectedRowKeys(selectedrow);
    setHasSelected(_.union(olddata1, ids).length > 0);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setItemsrow(newSelectedRowKeys);
    if (
      newSelectedRowKeys.length === data.length &&
      imei?.length === data.length &&
      hasSelected
    ) {
      setimei([]);
      setHasSelected(false);
      setSelectedRowKeys([]);
      setImeiList([]);
    } else {
      const tempdata = data;
      var selRow = [];
      var rma_ids = [];
      newSelectedRowKeys.map((selectedRow) => {
        var index = tempdata.findIndex((res) => res.id === selectedRow);
        if (index >= 0) {
          // console.log("tempdata", tempdata[index]);
          selRow.push(tempdata[index].imei);
          rma_ids.push(tempdata[index].id);
        }
        return selRow;
      });
      console.log(selRow, rma_ids);
      setItemimei(selRow);
      onRowsSelectionHandler(selRow, rma_ids);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const clearSearch = () => {
    setkeyword("");
    fetchData();
    setTableParams({
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });
  };
  const searchonboardingreport = (reset, params) => {
    setLoading(true);
    setbtnloading(true);
    var search_text = keyword;
    var page = reset === "reset" ? 1 : params.pagination?.current ?? 1;
    // console.log(reset);
    const obj = {
      search_text: search_text,
      page: page,
    };
    dispatch(searchimeilist(obj))
      .then((res) => {
        // console.log(res.data.data.data);
        setData(res.data.data.data);
        setLoading(false);
        setbtnloading(false);

        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.data.total,
            current: res.data.data.current_page,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setbtnloading(false);
      });
  };
  const getexportbtn = () => {
    setLoading(true);
    setLoadingEPbtn(true);
    var search_text = keyword;
    const obj = {
      search_text: search_text,
    };
    // console.log(obj);
    dispatch(exportImeilist(obj))
      .then((res) => {
        setLoading(false);
        setLoadingEPbtn(false);
        if (res.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingEPbtn(false);
      });
  };
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">
              {/* <strong>Initiate RMA </strong> */} Initiate RMA{" "}
              {tableParams.pagination.total &&
                `(${tableParams.pagination.total})`}
            </h4>
            <div className="d-flex align-items-center text-right">
              <div className="px-3">
                <Button
                  type="primary"
                  className="mr5"
                  loading={loadingEPbtn}
                  onClick={getexportbtn}
                >
                  <UploadOutlined style={{ fontSize: "14px" }} /> Export Report
                </Button>
              </div>
              <div className="float-end">
                {imei.length !== 0 && (
                  <Button
                    type="primary"
                    onClick={() => showDrawer()}
                    className="mr5"
                  >
                    Initiate RMA
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row mt-2" style={{ padding: "15px" }}>
              <div className="col-6">
              <label>Search by keyword</label>
                <Input
                  placeholder="Search by keyword"
                  value={keyword}
                  onChange={(e) => setkeyword(e.target.value)}
                />
              </div>
              <div className="col-6 mt-6">
                <Button
                  type="primary"
                  className="mr5"
                  loading={btnloading}
                  onClick={() => searchonboardingreport("reset", "")}
                >
                  Search
                </Button>
                <Button
                  style={{ marginLeft: "10px" }}
                  type="default"
                  onClick={clearSearch}
                >
                  Clear
                </Button>
              </div>
            </div>

            <div className="bg-white padd0">
              <Table
                columns={columns}
                dataSource={data}
                scroll={{ x: true }}
                pagination={tableParams.pagination}
                loading={loading}
                onChange={handleTableChange}
                rowSelection={rowSelection}
                // size="small"
              />
            </div>
            {/* <DataGrid
                    sx={{
                      ".MuiDataGrid-columnSeparator": {
                        display: "none",
                      },
                    }}
                    autoHeight
                    disableColumnMenu
                    getRowId={(row) => row.imei}
                    rows={data}
                    className={classes.grid}
                    columns={columns}
                    pageSize={pagesize}
                    rowsPerPageOptions={[pagesize]}
                    disableSelectionOnClick
                    checkboxSelection
                    onSelectionModelChange={(ids) => {
                      const selectedIDs = new Set(ids);
                      const selectedRows = data.filter((row) =>
                        selectedIDs.has(row.imei)
                      );
                      onRowsSelectionHandler(ids, selectedRows);
                      console.log(ids, selectedRows);
                    }}
                  // onSelectionModelChange={(ids) =>
                  //   onRowsSelectionHandler(ids)
                  // }
                  /> */}
          </div>
        </div>
      </div>
      <Drawer
        width={680}
        onClose={onClose}
        open={IMEIOpen}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          name="basic"
          layout="vertical"
        >
          <>
            {imeiList.map((item, index) => {
              return (
                <>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        name={`imei_${index}`}
                        label={`IMEI ${index + 1}`}
                        key={index}
                        value={item}
                        disabled={true}
                        rules={[
                          {
                            required: true,
                            message: "Please input imei",
                          },
                        ]}
                      >
                        <Input disabled={true} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label="Reason 1"
                        name={`reason1_${index}`}
                        rules={[
                          { required: true, message: "Please select reason" },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select Reason"
                          // onChange={reason1OnChange}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {renderReasons()}
                          {/* <Option value="AL">Alabama</Option>
                        <Option value="AK">Alaska</Option>
                        <Option value="AZ">Arizona</Option>
                        <Option value="AR">Arkansas</Option>
                        <Option value="CA">California</Option>
                        <Option value="CO">Colorado</Option>
                        <Option value="CT">Connecticut</Option>
                        <Option value="DE">Delaware</Option>
                        <Option value="DC">District Of Columbia</Option> */}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Reason 2"
                        name={`reason2_${index}`}
                        rules={[
                          { required: false, message: "Please select reason" },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select Reason"
                          // onChange={reason2OnChange}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {renderReasons()}
                          {/* <Option value="AL">Alabama</Option>
                        <Option value="AK">Alaska</Option>
                        <Option value="AZ">Arizona</Option>
                        <Option value="AR">Arkansas</Option>
                        <Option value="CA">California</Option>
                        <Option value="CO">Colorado</Option>
                        <Option value="CT">Connecticut</Option>
                        <Option value="DE">Delaware</Option>
                        <Option value="DC">District Of Columbia</Option> */}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        label="Comment"
                        name={`notes_${index}`}
                        rules={[
                          {
                            required: false,
                            // pattern: new RegExp(/^[0-9]+$/),
                            message: "Please input comment",
                          },
                        ]}
                      >
                        <Input.TextArea rows={2} placeholder="Enter comment" />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              );
            })}
          </>
          <Form.Item
            wrapperCol={{
              offset: 0,
              span: 16,
            }}
          >
            <Space>
              <Button loading={btnloading} type="primary" htmlType="submit">
                Submit
              </Button>
              <Button type="dashed" onClick={resetform}>
                Reset
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    rmaDevices: state.devicelist.rmaDevices,
  };
};

export default connect(mapStateToProps)(InitiateRMA);
