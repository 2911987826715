import React, { useState, useEffect, useRef } from "react";
import { OfficeDeviceAction } from "../../../store/actions/DeviceActions";
import { getibomarketlist } from "../../../store/actions/documentActions";
import { connect, useDispatch } from "react-redux";
// import { SearchOutlined } from "@ant-design/icons";
import { UploadOutlined } from "@ant-design/icons";
// import Highlighter from "react-highlight-words";

import {
  Table,
  Space,
  Input,
  Button,
  Spin,
  Select,
  message,
  Empty,
} from "antd";
import {
  searchofficesaleslist,
  manageragentslist,
  getofficelistname,
  exportOfficeDeviceAction,

  InventoryIbo,
  inventory_summary_ibo_marketer,
} from "../../../store/actions/DeviceActions";
import axiosInstance from "../../../services/AxiosAdmin";

const { Option } = Select;

const MyIBO = (props) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [devicestat, setDevicestat] = useState("");
  const [loading, setLoading] = useState(true);
  const [btnloading, setbtnLoading] = useState(false);
  const [staus, setStaus] = useState();
  const [officeList, setOfficeList] = useState([]);
  const [onboarding, setonboarding] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [loadingEPbtn, setLoadingEPbtn] = useState(false);
  const [data, setData] = useState();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [manager,setManager] = useState([])
  const [selectedItems, setselactedItems] = useState([]);
  const [fdata, setfdata] = useState([]);
  const [fetching, setfetching] = useState(false);
  //console.log(props.device
  const dispatch = useDispatch();
  useEffect(() => {
    fetchData(tableParams.pagination);
    dispatch(getibomarketlist()).then((res) => {
      console.log(res.data);
     setManager(res.data);
    });
  }, []);

  const getRandomuserParams = (params) => ({
    results: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
  });

  const fetchData = (params) => {
    console.log(params)
    // setLoading(true);
    dispatch(InventoryIbo(params))
      .then((res) => {
        // setLoading(false);
        console.log(res.data);
        console.log(res.data.grand_totals);
        setData(res.data.items.data);
        setDevicestat(res.data.grand_totals);
        console.log(res.data.items.current);
        console.log(res.data.items.total)
        // setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.items.total,
            current:res.data.items.current,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
        setLoading(false);
        setbtnLoading(false)
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
    setTimeout(() => {
      searchonboardingreport(" ", {
        pagination,
        filters,
        ...sorter,
      });
    }, 100);
  };
  const columns = [
    {
      title: "IBOs / Markets",
      dataIndex: "ibo_market_name",
      key: "ibo_market_name",
      sorter: true,
    },
    {
      title: "In-Stock",
      dataIndex: "in_stock",
      key: "in_stock",
      sorter: true,
    },
    {
      title: "Sold(Last 30 days)",
      dataIndex: "sold_30_days",
      key: "sold_30_days",
      sorter: true,
    },
    {
      title: "Manager Pending",
      dataIndex: "manager_pending",
      key: "manager_pending",
      sorter: true,
    },
    {
      title: "Manager Received",
      dataIndex: "manager_assigned",
      key: "manager_assigned",
      sorter: true,
    },
    {
      title: "Agent Pending",
      dataIndex: "agent_pending",
      key: "agent_pending",
      sorter: true,
    },
    {
      title: "Agent Transfer",
      dataIndex: "agent_transfer",
      key: "agent_transfer",
      sorter: true,
    },
    {
      title: "Agent Received",
      dataIndex: "agent_assigned",
      key: "agent_assigned",
      sorter: true,
    },
  ];

  // rowSelection object indicates the need for row selection

  const onChangeSelect = (value) => {
    console.log(`selected ${value}`);
    setStaus(value);
  };
  const searchonboardingreport = (reset, params) => {
    setLoading(true);
    setbtnLoading(true);
    // var search_text = keyword;

    var retailer = selectedItems.value;
    var page = reset === "reset" ? 1 : params.pagination?.current ?? 1;
    var sort = params?.order === undefined ? "" : params?.field;
    var sort_by =
      params?.order === "ascend"
        ? "ASC"
        : params?.order === undefined
        ? ""
        : "DESC";

    fetchData({
      current: page,
      sort,
      sort_by,
      retailer,
    })   
    
      // .then((res) => {
      //   console.log(res.data.data);
      //   setData(res.data.data.data);
      //   setLoading(false);
      //   setbtnLoading(false);
      //   setTableParams({
      //     ...tableParams,
      //     pagination: {
      //       ...tableParams.pagination,
      //       total: res.data.data.total,
      //       current: res.data.data.current_page,
      //       // 200 is mock data, you should read it from server
      //       // total: data.totalCount,
      //     },
      //   });
      // })
      // .catch((err) => {
      //   console.log(err);
      //   setLoading(false);
      //   setbtnLoading(false);
      // });
  };

  const renderOffice = ()=> {
    return manager.map((master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  const clearSearch = () => {
    setStaus([]);
    setfdata([]);
    setselactedItems([]);
    setfdata([]);
    setLoading(true);
    setselactedItems([]);
    fetchData(tableParams.pagination);
  };
  const getexportbtn = () => {
    setLoading(true);
    setLoadingEPbtn(true);
    var retailer = selectedItems.value;
    console.log(retailer);
    dispatch(inventory_summary_ibo_marketer({retailer}))
      .then((res) => {
        setLoading(false);
        setLoadingEPbtn(false);
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingEPbtn(false);
      });
  };
  const fetchUser = (value) => {
    console.log("fetching user", value);
    setfetching(false);
    if (value.length > 1) {
      setfdata([]);
      setfetching(true);
      // this.setState({ fdata: [], fetching: true });
      axiosInstance
        .get("/api/v1/reference/offices?term=" + value)
        .then((response) => {
          console.log(response.data);
          const fdata1 = response.data.map((user) => ({
            text: user.name,
            value: user.id,
          }));
          // console.log(this);
          // this.setState({ fdata, fetching: false });
          setfdata(fdata1);
          setfetching(false);
        })
        .catch((error) => {
          console.log(error);
          console.log(tableParams.pagination.total)
        });
    }
  };
  const onchangeRole = (selectedItems) => {
    console.log(selectedItems);
    setselactedItems(selectedItems);
  };
  return (
    <>
      <Spin spinning={loading}>
        <div className="row">
          <div className="col-xl-4 col-sm-6">
            <div className="card gradient-2 card-bx">
              <div className="card-body d-flex align-items-center">
                <div className="me-auto text-white">
                  <h2 className="text-white">{devicestat.in_stock}</h2>
                  <span className="fs-18">In stock</span>
                </div>
                <i style={{ color: "#fff" }} className="fa fa-4x fa-cubes"></i>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-sm-6">
            <div className="card gradient-4 card-bx">
              <div className="card-body d-flex align-items-center">
                <div className="me-auto text-white">
                  <h2 className="text-white">{devicestat.manager_pending}</h2>
                  <span className="fs-18">Manager Pending </span>
                </div>
                <i
                  style={{ color: "#fff" }}
                  className="fa fa-4x fa-luggage-cart"
                ></i>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-sm-6">
            <div className="card gradient-1 card-bx">
              <div className="card-body d-flex align-items-center">
                <div className="me-auto text-white">
                  <h2 className="text-white">{devicestat.sold_30_days}</h2>
                  <span className="fs-18">Sold </span>
                </div>
                <i style={{ color: "#fff" }} className="fa fa-4x fa-cube"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  {/* <strong>By Offices </strong> */} By IBOs/Markets{" "}
                  {tableParams.pagination.total &&
                    `(${tableParams.pagination.total})`}
                </h4>
                <div className="col-4 d-flex justify-content-end  ">
                  <Button
                    type="primary"
                    className="mr5"
                    loading={loadingEPbtn}
                    onClick={getexportbtn}
                  >
                    <UploadOutlined style={{ fontSize: "14px" }} /> Export
                    Report
                  </Button>
                </div>
              </div>
              <div className="row mt-2" style={{ padding: "15px" }}>
                <div className="col-4">
                  <label>Search IBO</label>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    labelInValue
                    placeholder="Select Option"
                   
                    defaultActiveFirstOption={false}
                    value={selectedItems}
                    onChange={onchangeRole}
                  >
                   {renderOffice()}
                  </Select>
                  {/* <Select
                    value={staus}
                    style={{ width: "100%" }}
                    showSearch
                    placeholder="Select office"
                    optionFilterProp="children"
                    onChange={onChangeSelect}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={(onboarding || []).map((d) => ({
                      value: d.id,
                      label: d.name,
                    }))}
                  ></Select> */}
                </div>

                <div className="col-4 mt-6">
                  <Button
                    type="primary"
                    className="mr5"
                    loading={btnloading}
                    onClick={() => searchonboardingreport("reset", "")}
                  >
                    Search
                  </Button>
                  <Button
                    type="default"
                    style={{ marginLeft: "10px" }}
                    onClick={() => clearSearch()}
                  >
                    Clear
                  </Button>
                </div>
              </div>

              <div className="card-body">
                <Table
                  columns={columns}
                  scroll={{
                    x: 1000,
                  }}
                  dataSource={data}
                  pagination={tableParams.pagination}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    office: state.devicelist.office,
  };
};

export default connect(mapStateToProps)(MyIBO);
