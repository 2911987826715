import React, { useContext } from "react";
import { useSelector } from "react-redux";
/// React router dom
import { Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
/// Dashboard
import Home from "./components/Dashboard/Home";
import Dashboard from "./components/Dashboard/Dashboard";
import ReceiveInventory from "./components/ReceiveInventory/ReceiveInventory";
import InventoryDetailedReport from "./components/Inventory/InventoryDetailedReport";
import Inventory from "./components/Inventory/Inventory";
import MyAgent from "./components/Myagent/agent";
import Myoffice from "./components/Myoffice/office";
import Compliance from "./components/Compliance/index";
import Carton from "./components/Pickup/CartonPickup";
import Transactionhistory from "./components/transaction/index";
import ChangePassword from "./pages/ChangePassword";
import Login from "./pages/Login";
import TransferDevice from "./components/TransferDevice/TransferDevice";
import TransferRequest from "./components/TransferRequest/TransferRequest";
import AssignAgent from "./components/AssignAgent/AssignAgent";
import RMARequest from "./components/RmaRequest/RMARequest";
import InitiateRMA from "./components/RmaRequest/InitiateRMA";
import PendingRMA from "./components/RmaRequest/PendingRMA";
import SubmitRMA from "./components/RmaRequest/SubmitRMA";
import ApprovedRMA from "./components/RmaRequest/ApprovedRMA";
import AllRMAReport from "./components/RmaRequest/AllRMAReport";
import OrderInventory from "./components/OrderInventory/OrderInventory";
import SalesReport from "./components/SalesReport/SalesReport";
import SalesByAgent from "./components/SalesReport/SalesByAgent";
import SalesByOffice from "./components/SalesReport/SalesByOffice";
import ApprovedRMARequest from "./components/RmaRequest/ApprovedRMARequest";
import RMAForm from "./components/RmaRequest/RMAForm";
import SendInvite from "./components/ManageAgent/SendInvite";
import OnboardingReport from "./components/ManageAgent/OnboardingSummary";
import AgentReport from "./components/ManageAgent/AgentSummary";
// import Freshchatapp from "./components/Support/support";
import SideMenuSupport from "./components/Support/SideMenuSupport";
import RetailerDocuments from "./components/Documents/RetalierDocuments";
import TrainingComponent from "./components/Support/TrainingComponent";
import TrainingVideo from "./components/Support/TrainingVideos";
import Announcement from "./components/Announcement/Announcement";
import EditEmployee from "./components/Administration/EditEmployee";
import AddEmployee from "./components/Administration/AddEmployee";
import Employee from "./components/Administration/Employee";
import EditMarkter from "./components/Administration/EditMarketers";
import AddMarkter from "./components/Administration/AddMarketers";
import Marketer from "./components/Administration/Marketers";
import EditOffices from "./components/Administration/EditOffice";
import AddOffices from "./components/Administration/AddOffice";
import Offices from "./components/Administration/Offices";
import { FAQ } from "./components/Support/FAQ";
import agedbyagent from "./components/Inventory/agedbyagent";
import agedbymanager from "./components/Inventory/agedbymanager";
import agedinventorymarket from "./components/Inventory/agedinventorymarket";
import agedinventoryoffice from "./components/Inventory/agedinventoryoffice";
import EditAgent from "./components/ManageAgent/EditAgent";
/// Pages
import Error404 from "./pages/Error404";
import Error403 from "./pages/Error403";

import Setting from "./layouts/Setting";
import { ThemeContext } from "../context/ThemeContext";
import SalesByManagers from "./components/SalesReport/SalesByManagers";
import SalesByIBoMarkets from "./components/SalesReport/SalesByIBoMarkets";
import InventoryByIbo from "./components/Inventory/InventoryByIbo"
import InventoryByManager from "./components/Inventory/InventoryByManager"


const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const user = useSelector((state) => state.auth.auth);
  console.log(user);
  const routes = [
    /// Dashboard
    { url: "", component: Home, roles: [3, 11, 12] },

    { url: "dashboard", component: Home, roles: [3, 11, 12] },
    {
      url: "ReceiveInventory",
      component: ReceiveInventory,
      roles: [3, 11, 12],
    },
    { url: "Inventory", component: Inventory, roles: [3, 11, 12] },
    {
      url: "InventoryDetailedReport",
      component: InventoryDetailedReport,
      roles: [3, 11, 12],
    },
    { url: "InventoryByIbo", component: InventoryByIbo, roles: [3, 11, 12] },
    { url: "InventoryByManager", component: InventoryByManager, roles: [3, 11, 12] },
    { url: "Agent", component: MyAgent, roles: [3, 11, 12] },
    { url: "Office", component: Myoffice, roles: [3, 11, 12] },
    { url: "IMEI", component: Carton, roles: [3, 11, 12] },
    { url: "Transaction", component: Transactionhistory, roles: [3, 11, 12] },
    { url: "changepassword", component: ChangePassword, roles: [3, 11, 12] },
    { url: "compliancedocument", component: Compliance, roles: [3, 11, 12] },
    { url: "transferdevice", component: TransferDevice, roles: [3, 11, 12] },
    { url: "TransferRequest", component: TransferRequest, roles: [3, 11, 12] },
    { url: "assignagent", component: AssignAgent, roles: [3, 11, 12] },
    { url: "summary", component: Dashboard, roles: [3, 11, 12] },
    { url: "rmarequest", component: RMARequest, roles: [3, 11, 12] },
    { url: "rmainitiaterequest", component: InitiateRMA, roles: [3, 11, 12] },
    { url: "rmapendingrequest", component: PendingRMA, roles: [3, 11, 12] },
    { url: "rmasubmitrequest", component: SubmitRMA, roles: [3, 11, 12] },
    { url: "rmaapprovedrequest", component: ApprovedRMA, roles: [3, 11, 12] },
    { url: "rmarequeststatus", component: AllRMAReport, roles: [3, 11, 12] },
    { url: "orderinventory", component: OrderInventory, roles: [3, 11, 12] },
    { url: "salesreport", component: SalesReport, roles: [3, 11, 12] },
    { url: "salesreportoffice", component: SalesByOffice, roles: [3, 11, 12] },
    { url: "salesreportmanagers", component: SalesByManagers, roles: [3, 11, 12] },
    { url: "salesreportmarkets", component: SalesByIBoMarkets, roles: [3, 11, 12] },
    { url: "salesreportagent", component: SalesByAgent, roles: [3, 11, 12] },
    { url: "approvedrma", component: ApprovedRMARequest, roles: [3, 11, 12] },
    { url: "createrma", component: RMAForm, roles: [3, 11, 12] },
    { url: "createrma", component: RMAForm, roles: [3, 11, 12] },
    { url: "sendinvite", component: SendInvite, roles: [3, 11, 12] },
    { url: "trainingdocs", component: TrainingComponent, roles: [3, 11, 12] },
    { url: "trainingvideos", component: TrainingVideo, roles: [3, 11, 12] },
    {
      url: "onboardingreport",
      component: OnboardingReport,
      roles: [3, 11, 12],
    },
    { url: "agentreport", component: AgentReport, roles: [3, 11, 12] },
    { url: "documents", component: RetailerDocuments, roles: [3, 11, 12] },
    { url: "support", component: SideMenuSupport, roles: [3, 11, 12] },
    { url: "announcement", component: Announcement, roles: [3, 11, 12] },
    { url: "employee", component: Employee, roles: [11, 12] },
    { url: "editemployee", component: EditEmployee, roles: [11, 12] },
    { url: "addemployee", component: AddEmployee, roles: [11, 12] },
    { url: "marketers", component: Marketer, roles: [12] },
    { url: "editmarketers", component: EditMarkter, roles: [12] },
    { url: "addmarketers", component: AddMarkter, roles: [12] },
    { url: "offices", component: Offices, roles: [11, 12] },
    { url: "editoffice", component: EditOffices, roles: [11, 12] },
    { url: "addoffice", component: AddOffices, roles: [11, 12] },
    { url: "faq", component: FAQ, roles: [3, 11, 12] },
    { url: "agedbyagent", component: agedbyagent, roles: [11, 12] },
    { url: "agedbymanager", component: agedbymanager, roles: [11, 12] },
    {
      url: "editagent",
      component: EditAgent,
      roles: [11, 12],
    },
    {
      url: "agedinventorymarket",
      component: agedinventorymarket,
      roles: [11, 12],
    },
    {
      url: "agedinventoryoffice",
      component: agedinventoryoffice,
      roles: [11, 12],
    },

    /// pages

    { url: "*", component: Error403, roles: [3, 11, 12] },
  ];
  console.log(window.location.pathname);
  let path = window.location.pathname;

  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  function hasMatchingValues(array1, array2) {
    // console.log(array1, array2);
    // Create a Set from array2 for efficient lookup
    const set2 = new Set(array2);
    //console.log(set2);
    // Iterate through array1
    for (const value of array1) {
      // Check if the value is in set2
      if (set2.has(value)) {
        return true; // Matching value found
      }
    }
    return false; // No matching values found
  }
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 350 }}
          >
            <Switch>
              {routes
                .filter((route) => hasMatchingValues(route.roles, user.role_id))
                .map((data, i) => (
                  <Route
                    key={i}
                    exact
                    path={`/${data.url}`}
                    component={data.component}
                  />
                ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <Setting />
    </>
  );
};

export default Markup;
