import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Select,
  Button,
  Input,
  Form,
  message,
  InputNumber,
  Upload,
  Spin,
  DatePicker,
} from "antd";
import moment from "moment";
import { UploadOutlined } from "@ant-design/icons";

import { updatecandidateuser } from "../../../store/actions/DeviceActions";

const { Option } = Select;
const { Dragger } = Upload;

class EditAgent extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    // state
    this.state = {
      showcase: false,
      fileList: [],
      fileList2: [],
    };
  }

  onChange(value) {
    console.log(`selected ${value}`);
  }

  componentWillMount() {
    if (this.props.location.agent_info === undefined) {
      this.props.history.push("/onboardingreport");
    }
  }

  mobilevalidation = (value) => {
    console.log(value);
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    if (value.test(phoneno)) {
      console.log(value.match(phoneno));
      this.props.form.setFieldsValue({
        MobileNo: value,
      });
    }
  };

  handleSubmit = (values) => {
    this.setState({ loading: true, loadingSBTbtn: true });

    const { fileList, fileList2 } = this.state;

    const formData = new FormData();

    fileList?.forEach((fileList) => {
      formData.append("photo_copy", fileList ?? []);
    });
    fileList2?.forEach((fileList) => {
      formData.append("government_document", fileList ?? []);
    });
    formData.append("first_name", values?.first_name ?? "");
    formData.append("last_name", values?.last_name ?? "");
    formData.append("middle_name", values?.middle_name ?? "");
    formData.append("street_address1", values?.address ?? "");
    formData.append("street_address2", values?.street_address2 ?? "");
    formData.append("city", values?.city ?? "");
    formData.append("zip", values?.zip ?? "");
    formData.append("state", values?.state ?? "");
    formData.append("email", values.email);
    formData.append("dob", moment(values?.dob ?? "").format("MM-DD-YYYY"));
    formData.append("ssn_number", values.ssn_number);
    formData.append("rad_id", values?.rad_id ?? "");
    formData.append("shirt_size", values?.shirt_size ?? "");
    formData.append("mobile_number", values.mobile_number.toString());

    var agent_id = this.props.location.agent_info.id;

    this.props
      .updatecandidateuser(formData, agent_id)
      .then((res) => {
        console.log("res", res.data.message);
        message.success(res.data.message);
        this.setState({
          loading: false,
        });

        this.props.history.push("/onboardingreport");
      })
      .catch((error) => {
        message.error(error.response);
        console.log(error);
        this.setState({ loading: false, loadingSBTbtn: false });
      });
  };

  render() {
    const { fileList, fileList2 } = this.state;
    const propsthumb = {
      multiple: false,

      accept: ".jpg,.jpeg",
      showUploadList: { showPreviewIcon: false, showRemoveIcon: true },
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
        // console.log(this.state.fileList);
      },
      beforeUpload: (file) => {
        console.log(file);
        this.setState((state) => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };
    const propsthumb2 = {
      multiple: false,
      fileList: fileList2,
      accept: ".jpg,.jpeg",
      showUploadList: { showPreviewIcon: false, showRemoveIcon: true },
      onRemove: (file2) => {
        this.setState((state) => {
          const index = state.fileList2.indexOf(file2);
          const newFileList = state.fileList2.slice();
          newFileList.splice(index, 1);
          return {
            fileList2: newFileList,
          };
        });
        console.log(this.state.fileList2);
      },
      beforeUpload: (file2) => {
        console.log(file2);
        this.setState((state) => ({
          fileList2: [...state.fileList2, file2],
        }));
        return false;
      },
      fileList2,
    };
    // eslint-disable-next-line
    var mailformat =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    const { loading, showcase } = this.state;

    return (
      <Spin spinning={showcase}>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  <strong>Edit Candidate</strong>
                </h4>
                <Link to="/onboardingreport" className="float-right">
                  <Button type="default">Back</Button>
                </Link>
              </div>
              <div className="card-body">
                <Form
                  name="basic"
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  ref={this.formRef}
                  onFinish={this.handleSubmit}
                  className="addbookform row"
                >
                  <Form.Item
                    label="First Name"
                    className="col-md-4 col-sm-6 col-xs-12"
                    name="first_name"
                    initialValue={
                      this.props.location?.agent_info?.first_name ?? ""
                    }
                    rules={[
                      {
                        required: true,
                        pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                        message: "Please input first name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter First Name" />
                  </Form.Item>
                  <Form.Item
                    label="Middle Name"
                    className="col-md-4 col-sm-6 col-xs-12"
                    name="middle_name"
                    initialValue={
                      this.props.location?.agent_info?.middle_name ?? ""
                    }
                    rules={[
                      {
                        required: false,
                        pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                        message: "Please input middle name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Middle Name" />
                  </Form.Item>
                  <Form.Item
                    label="Last Name"
                    className="col-md-4 col-sm-6 col-xs-12"
                    name="last_name"
                    initialValue={
                      this.props.location?.agent_info?.last_name ?? ""
                    }
                    rules={[
                      {
                        required: true,
                        pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                        message: "Please input last name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Last Name" />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    className="col-md-4 col-sm-6 col-xs-12 mt-2 "
                    name="email"
                    initialValue={this.props.location?.agent_info?.email ?? ""}
                    rules={[
                      {
                        required: true,
                        pattern: new RegExp(mailformat),
                        message: "Enter valid email address",
                      },
                    ]}
                  >
                    <Input
                      type="email"
                      placeholder="Enter Email Address"
                      autoComplete="new-password"
                      // onBlur={(e) => this.checkEmail(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Mobile Number"
                    className="col-md-4 col-sm-6 col-xs-12 mt-2"
                    name="mobile_number"
                    initialValue={
                      this.props.location?.agent_info?.mobile_number ?? ""
                    }
                    rules={[
                      {
                        required: true,
                        pattern: new RegExp(/^[0-9]{10,10}$/),
                        message: "Please input mobile number",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Enter Mobile Number"
                      minLength={10}
                      maxLength={10}
                      min={0}
                      step={0}
                      autoComplete="new-password"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Date of birth"
                    className="col-md-4 col-sm-6 col-xs-12 mt-2"
                    name="dob"
                    initialValue={moment(
                      this.props.location?.agent_info?.dob ?? ""
                    )}
                    rules={[
                      {
                        required: true,
                        message: "Please input valid date!",
                      },
                    ]}
                    extra="Must be above 18 years old"
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      format={"MM-DD-YYYY"}
                      placeholder="Date of Birth"
                      disabledDate={(current) =>
                        current.isAfter(moment().subtract(18, "year"))
                      }
                      showToday={false}
                    />
                  </Form.Item>
                  <Form.Item
                    className="col-md-4 col-sm-6 col-xs-12 mt-2"
                    label="SSN Number"
                    name="ssn_number"
                    initialValue={
                      this.props.location?.agent_info?.ssn_number ?? ""
                    }
                    rules={[
                      {
                        required: true,
                        pattern: new RegExp(
                          /^(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$|^(?!(000|666|9))\d{3}(?!00)\d{2}(?!0000)\d{4}$/
                        ),
                        message: "Please input valid SSN Number!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter SSN number" />
                  </Form.Item>
                  <Form.Item
                    className="col-md-4 col-sm-6 col-xs-12 mt-2"
                    label="RAD ID"
                    name="rad_id"
                    initialValue={this.props.location?.agent_info?.rad_id ?? ""}
                    rules={[
                      {
                        pattern: new RegExp("^[a-zA-Z0-9]{0,9}$"),
                        required: true,
                        message: "Please input RAD ID",
                      },
                      {
                        pattern: /^[A-Za-z0-9]+$/,
                        message: "Please enter a valid RAD ID",
                      },
                    ]}
                  >
                    <Input maxLength={9} placeholder="Enter RAD ID" />
                  </Form.Item>
                  <Form.Item
                    className="col-md-4 col-sm-6 col-xs-12 mt-2"
                    label="Shirt Size"
                    name="shirt_size"
                    initialValue={
                      this.props.location?.agent_info?.shirt_size ?? ""
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please input Shirt Size",
                      },
                    ]}
                  >
                    <Select
                      style={{
                        width: "100%",
                      }}
                      placeholder="Select shirt size"
                      options={[
                        {
                          value: "XS",
                          label: "XS",
                        },
                        {
                          value: "S",
                          label: "S",
                        },
                        {
                          value: "M",
                          label: "M",
                        },
                        {
                          value: "L",
                          label: "L",
                        },
                        {
                          value: "XL",
                          label: "XL",
                        },
                        {
                          value: "XXL",
                          label: "XXL",
                        },
                        {
                          value: "XXXL",
                          label: "XXXL",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Street Address"
                    className="col-md-4 col-sm-6 col-xs-12 mt-2"
                    name="address"
                    initialValue={
                      this.props.location?.agent_info?.street_address1 ?? ""
                    }
                    rules={[
                      { required: false, message: "Please input Address" },
                    ]}
                  >
                    <Input placeholder="Address" />
                  </Form.Item>
                  <Form.Item
                    className="col-md-4 col-sm-6 col-xs-12 mt-2"
                    label="Street Address2"
                    name="street_address2"
                    initialValue={
                      this.props.location?.agent_info?.street_address2 ?? ""
                    }
                    rules={[
                      {
                        required: false,
                        message: "Please input valid address!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Address 2" />
                  </Form.Item>
                  <Form.Item
                    label="City"
                    className="col-md-4 col-sm-6 col-xs-12 mt-2"
                    name="city"
                    initialValue={this.props.location?.agent_info?.city ?? ""}
                    rules={[{ required: false, message: "Please input 	City" }]}
                  >
                    <Input placeholder="City" />
                  </Form.Item>
                  <Form.Item
                    label="State"
                    className="col-md-4 col-sm-6 col-xs-12 mt-2"
                    name="state"
                    initialValue={this.props.location?.agent_info?.state ?? ""}
                    rules={[{ required: false, message: "Please input 	State" }]}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select State"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="AL">Alabama</Option>
                      <Option value="AK">Alaska</Option>
                      <Option value="AZ">Arizona</Option>
                      <Option value="AR">Arkansas</Option>
                      <Option value="CA">California</Option>
                      <Option value="CO">Colorado</Option>
                      <Option value="CT">Connecticut</Option>
                      <Option value="DE">Delaware</Option>
                      <Option value="DC">District Of Columbia</Option>
                      <Option value="FL">Florida</Option>
                      <Option value="GA">Georgia</Option>
                      <Option value="HI">Hawaii</Option>
                      <Option value="ID">Idaho</Option>
                      <Option value="IL">Illinois</Option>
                      <Option value="IN">Indiana</Option>
                      <Option value="IA">Iowa</Option>
                      <Option value="KS">Kansas</Option>
                      <Option value="KY">Kentucky</Option>
                      <Option value="LA">Louisiana</Option>
                      <Option value="ME">Maine</Option>
                      <Option value="MD">Maryland</Option>
                      <Option value="MA">Massachusetts</Option>
                      <Option value="MI">Michigan</Option>
                      <Option value="MN">Minnesota</Option>
                      <Option value="MS">Mississippi</Option>
                      <Option value="MO">Missouri</Option>
                      <Option value="MT">Montana</Option>
                      <Option value="NE">Nebraska</Option>
                      <Option value="NV">Nevada</Option>
                      <Option value="NH">New Hampshire</Option>
                      <Option value="NJ">New Jersey</Option>
                      <Option value="NM">New Mexico</Option>
                      <Option value="NY">New York</Option>
                      <Option value="NC">North Carolina</Option>
                      <Option value="ND">North Dakota</Option>
                      <Option value="OH">Ohio</Option>
                      <Option value="OK">Oklahoma</Option>
                      <Option value="OR">Oregon</Option>
                      <Option value="PA">Pennsylvania</Option>
                      <Option value="RI">Rhode Island</Option>
                      <Option value="SC">South Carolina</Option>
                      <Option value="SD">South Dakota</Option>
                      <Option value="TN">Tennessee</Option>
                      <Option value="TX">Texas</Option>
                      <Option value="UT">Utah</Option>
                      <Option value="VT">Vermont</Option>
                      <Option value="VA">Virginia</Option>
                      <Option value="WA">Washington</Option>
                      <Option value="WV">West Virginia</Option>
                      <Option value="WI">Wisconsin</Option>
                      <Option value="WY">Wyoming</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="ZIP"
                    className="col-md-4 col-sm-6 col-xs-12 mt-2"
                    name="zip"
                    initialValue={this.props.location?.agent_info?.zip ?? ""}
                    rules={[
                      {
                        required: false,
                        pattern: new RegExp(/^[0-9]+$/),
                        message: "Please input 	ZIP",
                      },
                    ]}
                  >
                    <Input maxLength={5} placeholder="ZIP" />
                  </Form.Item>

                  <Form.Item
                    label="Profile Picture"
                    className="col-md-6 col-sm-12 col-xs-12 paddlr15 mt-4"
                    name="Documents1"
                    getValueFromEvent={this.normFile}
                    initialValue={
                      this.props.location.agent_info === undefined
                        ? ""
                        : this.props.location.agent_info.photo_copy
                    }
                    rules={[
                      {
                        required: false,
                        message: "Please upload required documents!",
                      },
                    ]}
                  >
                    <Dragger {...propsthumb}>
                      <p className="ant-upload-drag-icon">
                        <UploadOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        Support for a single or bulk upload. Strictly prohibit
                        from uploading company data or other band files
                      </p>
                    </Dragger>
                  </Form.Item>
                  <Form.Item
                    label="ID Picture"
                    className="col-md-6 col-sm-12 col-xs-12 paddlr15 mt-4"
                    name="Documents2"
                    rules={[
                      {
                        required: false,
                        message: "Please upload required documents!",
                      },
                    ]}
                  >
                    <Dragger {...propsthumb2}>
                      <p className="ant-upload-drag-icon">
                        <UploadOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        Support for a single or bulk upload. Strictly prohibit
                        from uploading company data or other band files
                      </p>
                    </Dragger>
                  </Form.Item>
                  <br />
                  <br />
                  <div className="col-md-12 col-sm-12 col-xs-12 padd15">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      loading={loading}
                    >
                      Update User
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user, data: state.data };
}

export default connect(mapStateToProps, {
  updatecandidateuser,
})(EditAgent);
