import React, { useState, useEffect, useRef } from "react";
import { map, get } from "lodash";
import {
  inventoryDeviceAction,
  exportMyInventoryAction,
} from "../../../store/actions/DeviceActions";
import { connect, useDispatch } from "react-redux";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

import { Table, Button, Drawer, List, Space, Input, message } from "antd";

const Inventory = (props) => {
  //console.log(props.device);
  const [open, setOpen] = useState(false);
  const [imei, setimei] = useState([]);
  const [carton, setcarton] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [loadingEPbtn, setLoadingEPbtn] = useState(false);
  const [search_key, setsearch_key] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(inventoryDeviceAction())
      .then((res) => {
        console.log(res.data.data);
        setFilteredData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [dispatch]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "Carton Number",
      dataIndex: "carton_number",
      key: "carton_number",
      ...getColumnSearchProps("carton_number"),
    },
    {
      title: "Device Count",
      dataIndex: "imei_count",
      key: "imei_count",
    },
    {
      title: "Device Type",
      dataIndex: "device_type",
      key: "device_type",
    },
    {
      title: "Office Name",
      dataIndex: "office_name",
      key: "office_name",
      ...getColumnSearchProps("office_name"),
    },
    {
      title: "Action",
      dataIndex: "address",
      key: "address",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <Button onClick={() => showDrawer(record)} type="primary">
          view
        </Button>
      ),
    },
  ];

  // rowSelection object indicates the need for row selection

  const getexportbtn = () => {
    setLoading(true);
    setLoadingEPbtn(true);
    const keyword = search_key;
    console.log(keyword);
    dispatch(exportMyInventoryAction(keyword))
      .then((res) => {
        setLoading(false);
        setLoadingEPbtn(false);
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingEPbtn(false);
      });
  };

  const showDrawer = (e) => {
    console.log(e);
    setimei(e.imei);
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onSearch = (e) => {
    const reg = new RegExp(e.target.value, "gi");
    const filteredbydate = map(props.device, (record) => {
      const carton_number = get(record, "carton_number").toString().match(reg);
      const office_name = get(record, "office_name").toString().match(reg);

      if (!carton_number && !office_name) {
        return null;
      }
      return record;
    }).filter((record) => !!record);
    setsearch_key(e.target.value);
    console.log(e.target.value);
    setFilteredData(
      e.target.value || typeof e.target.value === "string"
        ? filteredbydate
        : filteredData
    );
  };

  const clearSearch = () => {
    setsearch_key("");
    setFilteredData(props.device);
  };
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">
              {/* <strong>My Inventory</strong> */}
              {" "}
              My Inventory{" "}
                {filteredData.length &&
                  `(${filteredData.length})`}
            </h4>
            <Button
              type="primary"
              className="mr5"
              loading={loadingEPbtn}
              onClick={getexportbtn}
            >
              <UploadOutlined style={{ fontSize: "14px" }} /> Export Report
            </Button>
          </div>
          <div className="card-body">
            <div className="row bg-white padd0 align-items-center mb-4">
              <div className="col ">
                <label for="exampleFormControlInput1">Search by keyword</label>
                <Input
                  placeholder="Search by keyword"
                  value={search_key}
                  onChange={onSearch}
                  style={{ width: "100%", display: "block" }}
                />
              </div>
              <div className="col mt-6">
                <Button
                  type="primary"
                  style={{ marginLeft: "10px" }}
                  onClick={() => clearSearch()}
                >
                  Clear
                </Button>
              </div>
            </div>
            <Table
              columns={columns}
              dataSource={filteredData}
              loading={loading}
              scroll={{
                x: 1000,
              }}
            />
          </div>
        </div>
      </div>
      <Drawer title="IMEI List" placement="right" onClose={onClose} open={open}>
        <List
          bordered
          dataSource={imei}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    device: state.devicelist.inventory,
  };
};

export default connect(mapStateToProps)(Inventory);
