import React, { useState, useEffect } from "react";
import {
  sendinviteuser,
  getofficelistname,
} from "../../../store/actions/DeviceActions";
import { connect, useDispatch } from "react-redux";

import { Button, Spin, Select, Form, Input, message, Radio, Empty } from "antd";
import { QrcodeOutlined, LinkOutlined } from "@ant-design/icons";
import axiosInstance from "../../../services/AxiosAdmin";

const { Option } = Select;

const SendInvite = (props) => {
  //console.log(props.device);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [btnloading, setBtnLoading] = useState(false);
  const [data, setData] = useState([]);
  const [optionVisible, setOptionvisbile] = useState();
  const [resvisbible, setResvisbible] = useState(false);
  const [singleOffice, setSingleOffice] = useState([]);
  const [selectedItems, setselactedItems] = useState([]);
  const [fdata, setfdata] = useState([]);
  const [fetching, setfetching] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {}, []);

  const onFinish = (values) => {
    console.log("Success:", values);
    var obj_arr = {
      office_id: values.office_id.value,
      email: values.email,
      first_name: values.first_name,
      middle_name: values?.middle_name ?? "",
      last_name: values.last_name,
      mobile_number: values.mobile_number,
    };
    setBtnLoading(true);
    setLoading(true);
    dispatch(sendinviteuser(obj_arr))
      .then((res) => {
        message.success("Candidate Invite sent succesfully");
        form.resetFields();
        setLoading(false);
        setBtnLoading(false);
        setfdata([]);
        setResvisbible(false);
        setOptionvisbile("");
      })
      .catch((err) => {
        setLoading(false);
        setBtnLoading(false);
        message.error(err);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handlereset = (e) => {
    form.resetFields();
    setResvisbible(false);
    setfdata([]);
    setOptionvisbile("");
  };
  const onChangeOption = (input) => {
    console.log(input);
    setOptionvisbile(input);
  };

  const fetchUser = (value) => {
    console.log("fetching user", value);
    setfetching(false);
    if (value.length > 1) {
      setfdata([]);
      setfetching(true);
      // this.setState({ fdata: [], fetching: true });
      axiosInstance
        .get("/api/v1/reference/offices?term=" + value)
        .then((response) => {
          console.log(response.data);
          setData(response.data);
          const fdata1 = response.data.map((user) => ({
            text: user.name,
            value: user.id,
          }));
          setfdata(fdata1);
          setfetching(false);
        })
        .catch((error) => {
          console.log(error);
          setfetching(false);
        });
    }
  };

  const onChangeOffice = (e) => {
    console.log(e);
    console.log(data);
    const results = data.filter((element) => {
      return element.id === parseInt(e.key);
    });
    console.log(results);
    setSingleOffice(results);
    setResvisbible(true);
    setselactedItems(e.value);
  };

  return (
    <>
      <Spin spinning={loading}>
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  <strong>Send Invite</strong>
                </h4>
              </div>
              <div className="card-body">
                <Form
                  form={form}
                  name="basic"
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <div className="row">
                    <div className="col-4">
                      <Form.Item
                        label="Office"
                        name="office_id"
                        rules={[
                          {
                            required: true,
                            message: "Please select office",
                          },
                        ]}
                      >
                        {/* <Select
                          showSearch
                          placeholder="Select office"
                          // onChange={(e) => onChangeOffice(e)}
                          options={(data || []).map((d) => ({
                            value: d.id,
                            label: d.name,
                          }))}
                        /> */}
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          labelInValue
                          placeholder="Type 2 characters to search..!"
                          notFoundContent={
                            fetching ? <Spin size="small" /> : <Empty />
                          }
                          filterOption={false}
                          onSearch={fetchUser}
                          defaultActiveFirstOption={false}
                          value={selectedItems}
                          onChange={onChangeOffice}
                        >
                          {fdata?.map((d) => (
                            <Option key={d.value}>{d.text}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div
                      className={
                        resvisbible === true
                          ? "col align-self-center"
                          : "d-none"
                      }
                    >
                      <Button
                        type="primary"
                        icon={<QrcodeOutlined />}
                        className="mt-3"
                        style={{ cursor: "pointer", marginRight: "10px" }}
                        onClick={() => {
                          window.open(singleOffice[0].qr_code_url, "_blank");
                        }}
                      >
                        Download QR Code
                      </Button>
                      <Button
                        type="primary"
                        icon={<LinkOutlined />}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard
                            .writeText(singleOffice[0].onboarding_link)
                            .then(() => {
                              message.success("COPIED");
                            })
                            .catch((err) => {
                              console.log(err.message);
                            });
                        }}
                      >
                        Copy Link
                      </Button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <Form.Item
                        label="First Name"
                        name="first_name"
                        rules={[
                          {
                            required: false,
                            pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                            message: "Please input first name",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div className="col-4">
                      <Form.Item
                        label="Middle Name "
                        name="middle_name"
                        rules={[
                          {
                            required: false,
                            message: "Please input  middle name",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                    <div className="col-4">
                      <Form.Item
                        label="Last Name"
                        name="last_name"
                        rules={[
                          {
                            required: false,
                            pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                            message: "Please input your last name",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row">
                    <Form.Item
                      label="Select Option"
                      name="option_id"
                      className="col-3"
                      rules={[
                        {
                          required: true,
                          message: "Please select option",
                        },
                      ]}
                    >
                      <Radio.Group
                        onChange={(e) => onChangeOption(e.target.value)}
                      >
                        <Radio value={1}>Email</Radio>
                        <Radio value={2}>Phone</Radio>
                      </Radio.Group>
                    </Form.Item>
                    {optionVisible === 1 && (
                      <div className="col-4">
                        <Form.Item
                          label="Email Address"
                          name="email"
                          rules={[
                            {
                              required: true,
                              pattern: new RegExp(
                                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                              ),
                              message: "Please input valid email address",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </div>
                    )}
                    {optionVisible === 2 && (
                      <div className="col-4">
                        <Form.Item
                          label="Mobile Number"
                          name="mobile_number"
                          rules={[
                            {
                              required: true,
                              pattern: new RegExp(/^[0-9]{10,10}$/),
                              message: "Please input  valid mobile number",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </div>
                    )}
                  </div>

                  <Form.Item
                    className="mt-4"
                    wrapperCol={{
                      span: 16,
                    }}
                  >
                    <Button
                      type="primary"
                      loading={btnloading}
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                    <Button
                      style={{ marginLeft: 10 }}
                      type="default"
                      onClick={handlereset}
                    >
                      Clear
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    transfer: state.devicelist.transfer,
  };
};

export default connect(mapStateToProps)(SendInvite);
