import { Table, Tag, Button, Select, Input, message, Spin, Empty } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import {
  SalesSummaryAgentReport,
  getagentlistname,
  searchagentsaleslist,
  manageragentslist,
  exportagentsaleslist,
} from "../../../store/actions/DeviceActions";
import { connect, useDispatch } from "react-redux";
import "antd/dist/antd.min.css";
import agent from "../Myagent/agent";
import _ from "lodash";
import axiosInstance from "../../../services/AxiosAdmin";
const { Option } = Select;

const SalesByAgent = () => {
  const [data, setData] = useState();
  const [staus, setStaus] = useState();
  const [loading, setLoading] = useState(false);
  const [onboarding, setonboarding] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [btnloading, setbtnLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [loadingEPbtn, setLoadingEPbtn] = useState(false);
  const [selectedItems, setselactedItems] = useState([]);
  const [fdata, setfdata] = useState ([]);
  const [fetching, setfetching] = useState (false);
  
  const dispatch = useDispatch();
  // const [keyword, setkeyword] = useState("");

  useEffect(() => {
    fetchData();
    // dispatch(manageragentslist()).then((res) => {
    //   setAgentList(res.data);
    //   console.log(res.data);
    // });

    dispatch(manageragentslist())
      .then((res) => {
        console.log(res.data.data);
        setAgentList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // const {fetching,fdata}=this.state;
  const columns = [
    {
      title: "Agent Name",
      dataIndex: "agent_name",
      key: "agent_name",
      sorter: true,
    },
    {
      title: "Today",
      dataIndex: "today",
      key: "today",
      sorter: true,
    },
    {
      title: "WTD",
      dataIndex: "WTD",
      key: "WTD",
      sorter: true,
    },
    {
      title: "MTD",
      dataIndex: "MTD",
      key: "MTD",
      sorter: true,
    },
    {
      title: "7 Days",
      dataIndex: "last_7_days",
      key: "last_7_days",
      sorter: true,
    },
    {
      title: "30 Days",
      dataIndex: "last_30_days",
      key: "last_30_days",
       sorter: true,
    },
  ];
  const getRandomuserParams = (params) => ({
    results: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
  });
  const fetchData = (params = {}) => {
    setLoading(true);
    dispatch(SalesSummaryAgentReport(getRandomuserParams(params)))
      .then((res) => {
        setLoading(false);
        console.log(res.data.data);
        console.log(res.data.data.total)
        setData(res.data.data.data);
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.data.total,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    setTimeout(() => {
      searchonboardingreport(" ", {
        pagination,
        filters,
        ...sorter,
      });
    }, 100);
  };

  const onChangeSelect = (value) => {
    console.log(`selected ${value}`);
    setStaus(value);
  };

  const onchangeRole = (selectedItems) => {
    console.log(selectedItems);
    setselactedItems(selectedItems)
  };

  const searchonboardingreport = (reset, params) => {
    setLoading(true);
    setbtnLoading(true);
    // var search_text = keyword;
    var agent_id = selectedItems.key;
    var page = reset === "reset" ? 1 : params.pagination?.current ?? 1;
    var sort = params.order === undefined ? "" : params.field;
    var sort_by =
      params.order === "ascend"
        ? "ASC"
        : params.order === undefined
        ? ""
        : "DESC";
    dispatch(
      searchagentsaleslist({
        page: page,
        sort,
        sort_by,
        agent_id,
        // search_text: search_text,
      })
    )
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data.data);
        setLoading(false);
        setbtnLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.data.total,
            current: res.data.data.current_page,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setbtnLoading(false);
      });
  };
  const clearSearch = () => {
    setStaus([]);
    // setkeyword("");
    fetchData();
    setselactedItems([])
    setfdata([])
  };

  const getexportbtn = () => {
    setLoading(true);
    setLoadingEPbtn(true);
    const agent_id = selectedItems.key;
    console.log(agent_id);
    dispatch(exportagentsaleslist(agent_id))
      .then((res) => {
        setLoading(false);
        setLoadingEPbtn(false);
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoadingEPbtn(false);
      });
  };

  const fetchUser = (value) => {
    console.log("fetching user", value);
    setfetching(false)
    if (value.length > 1) {
      setfdata([])
      setfetching(true)
      // this.setState({ fdata: [], fetching: true });
      axiosInstance
        .get("/api/v1/userlist_based_on_role/agent?term=" + value)
        .then((response) => {
          console.log(response.data);
          const fdata1 = response.data.map((user) => ({
            text: user.name,
            value: user.id,
          }));  
          // console.log(this);
          // this.setState({ fdata, fetching: false });
          setfdata(fdata1)
      setfetching(false)
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">
              {/* <strong> By Agents</strong> */}
              {" "}
              By Agents{" "}
                {tableParams.pagination.total &&
                  `(${tableParams.pagination.total})`}
            </h4>
            <div className="col-sm-2 text-right">
              <Button
                type="primary"
                className="mr5"
                loading={loadingEPbtn}
                onClick={getexportbtn}
              >
                <UploadOutlined style={{ fontSize: "14px" }} /> Export Report
              </Button>
            </div>
          </div>

          <div className="row mt-2" style={{ padding: "15px" }}>
            <div className="col-4">
            <label for="exampleFormControlInput1">Select Agent</label>
              <Select
                showSearch
                style={{ width: "100%" }}
                labelInValue
                placeholder="Type 2 characters to search..!"
                notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
                filterOption={false}
                onSearch={fetchUser}
                defaultActiveFirstOption={false}
                value={selectedItems}
                onChange={onchangeRole}
              >
                {fdata?.map((d) => (
                  <Option key={d.value}>{d.text}</Option>
                ))}
              </Select>
              {/* <Select
                value={staus}
                style={{ width: "100%" }}
                showSearch
                placeholder="Select agent"
                optionFilterProp="children"
                onChange={onChangeSelect}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={(agentList || []).map((d) => ({
                  value: d.id,
                  label: d.full_name,
                }))}
              > */}
                {/* {renderagent()} */}
              {/* </Select> */}
            </div>

            {/* <div className="col-4">
                <Input
                  placeholder="Search by keyword"
                  value={keyword}
                  onChange={(e) => setkeyword(e.target.value)}
                />
              </div> */}

            <div className="col-4 mt-6">
              <Button
                type="primary"
                className="mr5"
                loading={btnloading}
                onClick={() => searchonboardingreport("reset", "")}
              >
                Search
              </Button>
              <Button
                type="default"
                style={{ marginLeft: "10px" }}
                onClick={() => clearSearch()}
              >
                Clear
              </Button>
            </div>
          </div>
          <div className="card-body">
            <Table
              columns={columns}
              rowKey={(record) => record.key}
              dataSource={data}
              pagination={tableParams.pagination}
              loading={loading}
              onChange={handleTableChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SalesByAgent;
