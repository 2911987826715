import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Tree, Input } from "antd";
import { DownOutlined } from "@ant-design/icons";
// import Data from "./trainingDocument.json";
const { TreeNode } = Tree;
const { Search } = Input;

function TrainingVideo() {
  const user = useSelector((state) => state.user);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [iframeVisible, setIframeVisible] = useState(false);
  const [iframeData, setIframeData] = useState([]);
  const treeRef = useRef(null);
  const treeNodesRef = useRef([]);
  const Data = [
    {
      category: "Agent",
      data: [
        {
          id: 1,
          isCollapsed: true,
          title: "Dashboard",
          content: "Dashboard Training Video",
          link: "https://wieserp.s3.amazonaws.com/Training_videos/Agent/Dashboard.mp4",
        },
        {
          id: 2,
          isCollapsed: true,
          title: "Inventory Management",
          content: "Inventory Management Training Video",
          link: "https://wieserp.s3.amazonaws.com/Training_videos/Agent/Inventory+Management.mp4",
        },
        {
          id: 3,
          isCollapsed: true,
          title: "Sales Reporting",
          content: "Sales Reporting Training Video",
          link: "https://wieserp.s3.amazonaws.com/Training_videos/Agent/Sales+Reporting.mp4",
        },
        {
          id: 4,
          isCollapsed: true,
          title: "Agent All Modules",
          content: "Agent All Modules Training Video",
          link: "https://wieserp.s3.amazonaws.com/Training_videos/Agent/Agent_WiES_All_Modules.mp4",
        },
      ],
    },
    {
      category: "Office Manager",
      data: [
        {
          id: 14,
          isCollapsed: true,
          title: "Dashboard and Onboarding",
          content: "Dashboard and Onboarding Agents Training Video",
          link: "https://wieserp.s3.amazonaws.com/Training_videos/Manager/Dashboard+_+Onboarding.mp4",
        },
        {
          id: 15,
          isCollapsed: true,
          title: "Sales and Inventory Reports",
          content: "Sales and Inventory Training Video",
          link: "https://wieserp.s3.amazonaws.com/Training_videos/Manager/Sales+_+Inventory+Reporting.mp4",
        },
        {
          id: 16,
          isCollapsed: true,
          title: "RMA Orders",
          content: "RMA Orders Training Video",
          link: "https://wieserp.s3.amazonaws.com/Training_videos/Manager/RMA+_+Orders.mp4",
        },
        {
          id: 17,
          isCollapsed: true,
          title: "Inventory Management",
          content: "Inventory Management Training Video",
          link: "https://wieserp.s3.amazonaws.com/Training_videos/Manager/Inventory+Management.mp4",
        },
      ],
    },
    {
      category: "IBO Manager",
      data: [
        {
          id: 9,
          isCollapsed: true,
          title: "Administration",
          content: "Administration Training Video",
          link: "https://wieserp.s3.amazonaws.com/Training_videos/IBO+Manager/Administration.mp4",
        },
      ],
    },
  ];

  const filter = Data.filter((item) => Object.keys(item).length !== 0);

  const onChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    setAutoExpandParent(true);

    const filtered = filter.filter(
      (item) =>
        item.category.toLowerCase().includes(value.toLowerCase()) ||
        item.data.some(
          (subItem) =>
            subItem.title.toLowerCase().includes(value.toLowerCase()) ||
            subItem.content.toLowerCase().includes(value.toLowerCase())
        )
    );
    setFilteredData(filtered);
  };

  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const handleOpenIframe = (value) => {
    setIframeVisible(true);
    setIframeData(value);
  };

  const handleCloseIframe = (value) => {
    setIframeVisible(false);
  };

  const renderTreeNodes = (data) =>
    data.map((item) => {
      if (item.data) {
        return (
          <TreeNode
            className="mx-3"
            style={{ fontSize: "18px", marginLeft: "20%", width: "auto" }}
            title={item.category}
            key={item.category}
            ref={(node) => treeNodesRef.current.push(node)}
          >
            {item.data?.map((subItem) => (
              <TreeNode
                className="mx-1"
                key={subItem.id}
                title={
                  <div>
                    <p
                      style={{ margin: 0, padding: 5, fontSize: "18px" }}
                      onClick={() => handleOpenIframe(subItem)}
                    >
                      <i className="fa fa-file-video-o"></i> &nbsp;
                      {subItem.title}
                    </p>
                  </div>
                }
              />
            ))}
          </TreeNode>
        );
      }
      return <TreeNode title={item.title} key={item.id} />;
    });

  useEffect(() => {
    const expandedKeys = filter.reduce((keys, item) => {
      if (
        item.category.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.data.some(
          (subItem) =>
            subItem.title.toLowerCase().includes(searchValue.toLowerCase()) ||
            subItem.content.toLowerCase().includes(searchValue.toLowerCase())
        )
      ) {
        return [...keys, item.category];
      }
      return keys;
    }, []);
    setExpandedKeys(expandedKeys);
    scrollToClosestMatch();
  }, [searchValue]);

  const scrollToClosestMatch = () => {
    if (treeRef.current) {
      const nodes = treeNodesRef.current;
      let minDistance = Number.MAX_VALUE;
      let closestNode = null;
      nodes.forEach((node) => {
        const text =
          node.props.title.props.children[0].props.children.toLowerCase();
        const distance = text.includes(searchValue.toLowerCase())
          ? text.indexOf(searchValue.toLowerCase())
          : Number.MAX_VALUE;
        if (distance < minDistance) {
          minDistance = distance;
          closestNode = node;
        }
      });

      if (closestNode && closestNode.dom) {
        closestNode.dom.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  return (
    <div>
      <section className="section mb-5 pt-1 pb-4 support_banner">
        <div className="container ">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xl-7 text-center desc">
              <div className=" mb-1 text-white">
                <Search
                  placeholder="Search"
                  onChange={onChange}
                  onPressEnter={(e) => e.preventDefault()}
                  size="large"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="d-flex">
        <Tree
          ref={treeRef}
          switcherIcon={<DownOutlined style={{ fontSize: "1.2rem" }} />}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          onExpand={onExpand}
        >
          {renderTreeNodes(filteredData.length > 0 ? filteredData : filter)}
        </Tree>
        {iframeVisible && (
          <div
            className="position-relative"
            style={{
              position: "fixed",
              top: 0,
              zIndex: 999,
              left: "5%",
            }}
          >
            {iframeData.content}
            <br />
            <iframe
              width={720}
              height={408}
              src={iframeData.link}
              title={iframeData.content}
              allowfullscreen
            />
            &nbsp;&nbsp;
            <i
              onClick={() => handleCloseIframe()}
              className="fa fa-times-circle position-absolute image-size"
            ></i>
          </div>
        )}
      </div>
    </div>
  );
}

export default TrainingVideo;
