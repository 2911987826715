import { Table, Tag, Button, Select, Input, message, Empty, Spin } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import { useEffect, useState } from "react";
import {
  detailed_sales_report_manager,
  detailed_sales_export_manager,
  detailed_sales_report_ibo,
  detailed_sales_export_ibo_marketer,
} from "../../../store/actions/DeviceActions";
import { get_ibo_marketlist } from "../../../store/actions/documentActions";
import { connect, useDispatch } from "react-redux";
import "antd/dist/antd.min.css";
import _ from "lodash";
import axiosInstance from "../../../services/AxiosAdmin";

const { Option } = Select;

const SalesByMarkets = () => {
  const [data, setData] = useState();
  const [staus, setStaus] = useState();
  const [loading, setLoading] = useState(false);
  const [onboarding, setonboarding] = useState([]);
  const [officeList, setOfficeList] = useState([]);
  const [btnloading, setbtnLoading] = useState(false);
  const [loadingEPbtn, setLoadingEPbtn] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const [selectedItems, setselactedItems] = useState([]);
  const [fetching, setfetching] = useState(false);
  const [marketer, setMarketer] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData(tableParams.pagination);
    dispatch(get_ibo_marketlist())
      .then((res) => {
        console.log(res);
        setMarketer(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const columns = [
    {
      title: "IBOs / Markets",
      dataIndex: "ibo_market_name",
      key: "ibo_market_name",
      sorter: true,
      // render: (name, record) => (
      //   <Link
      //     to={{
      //       pathname: "/salesofficereport",
      //       urlpath: `/api/v1/sales_summary_reports/office?ibo_marketer_id=${record.ibo_market_id}`,
      //     }}
      //   >
      //     {name}
      //   </Link>
      // ),
    },
    {
      title: "Today",
      dataIndex: "today",
      key: "today",
      sorter: true,
    },
    {
      title: "WTD",
      dataIndex: "WTD",
      key: "WTD",
      sorter: true,
    },
    {
      title: "MTD",
      dataIndex: "MTD",
      key: "MTD",
      sorter: true,
    },
    {
      title: "7 Days",
      dataIndex: "last_7_days",
      key: "last_7_days",
      sorter: true,
    },
    {
      title: "30 Days",
      dataIndex: "last_30_days",
      key: "last_30_days",
      sorter: true,
    },
  ];
  const getRandomuserParams = (params) => ({
    results: params.pagination?.pageSize,
    page: params.pagination?.current,
    ...params,
  });
  const fetchData = (params) => {
    console.log(params);
    setLoading(true);
    dispatch(detailed_sales_report_ibo(params))
      .then((res) => {
        setLoading(false);
        console.log(res);
        console.log(res.data.items.data);
        setData(res.data.items.data);
        setLoading(false);
        setbtnLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.items.total,
            current: res.data.items.current,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    console.log("page", pagination);
    console.log("filter", filters);
    console.log("sorter", sorter);
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    setTimeout(() => {
      searchonboardingreport(" ", {
        pagination,
        filters,
        ...sorter,
      });
    }, 100);
  };
  const onChangeSelect = (value) => {
    console.log(`selected ${value}`);
    setStaus(value);
  };

  // const renderoffice = () => {
  //   return _.map(officeList.data, (office, index) => {
  //     console.log(office);
  //     // agent.map((genderData) => {
  //     return (
  //       <Option value={office.office_id} key={index}>
  //         {office.office_number}
  //       </Option>
  //     );
  //     // });
  //   });
  // };
  const searchonboardingreport = (reset, params) => {
    setLoading(true);
    setbtnLoading(true);
    // var sort_by = sortOrder;
    console.log(reset, params);
    var market = selectedItems.value;

    var sort = params?.order === undefined ? "" : params?.field;
    var sort_by =
      params?.order === "ascend"
        ? "ASC"
        : params?.order === undefined
        ? ""
        : "DESC";

    var page = reset === "reset" ? 1 : params?.pagination?.current ?? 1;
    fetchData({
      current: page,
      sort,
      sort_by,
      market,
    });
    //   .then((res) => {
    //     console.log(res.data.data);
    //     setData(res.data.data.data);
    //     setLoading(false);
    //     setbtnLoading(false);
    //     setTableParams({
    //       ...tableParams,
    //       pagination: {
    //         ...tableParams.pagination,
    //         total: res.data.items.total,
    //         current: res.data.items.current_page,
    //         // 200 is mock data, you should read it from server
    //         // total: data.totalCount,
    //       },
    //     });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     setLoading(false);
    //     setbtnLoading(false);
    //   });
  };
  const renderOffice = () => {
    return marketer.map((master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  };
  const clearSearch = () => {
    setStaus("");
    fetchData(tableParams.pagination);
    setselactedItems([]);
  };
  const getexportbtn = () => {
    setLoading(true);
    setLoadingEPbtn(true);
    // const office_id = staus;
    var market = selectedItems.value;
    console.log(market);
    dispatch(detailed_sales_export_ibo_marketer(market))
      .then((res) => {
        setLoading(false);
        console.log(res);
        setLoadingEPbtn(false);
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setLoadingEPbtn(false);
      });
  };
  const onchangeRole = (selectedItems) => {
    console.log(selectedItems);
    setselactedItems(selectedItems);
  };
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">
              {" "}
              By IBOs/Markets{" "}
              {tableParams.pagination.total &&
                `(${tableParams.pagination.total})`}
            </h4>
            <div className="col-sm-2 text-end">
              <Button
                type="primary"
                className="mr5"
                loading={loadingEPbtn}
                onClick={getexportbtn}
              >
                <UploadOutlined style={{ fontSize: "14px" }} /> Export Report
              </Button>
            </div>
          </div>
          <div className="row mt-2" style={{ padding: "15px" }}>
            <div className="col-4">
              <label for="exampleFormControlInput1">IBOs / Markets</label>
              <Select
                showSearch
                style={{ width: "100%" }}
                labelInValue
                placeholder="Select Option"
                defaultActiveFirstOption={false}
                value={selectedItems}
                onChange={onchangeRole}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {renderOffice()}
              </Select>
            </div>

            <div className="col-4 mt-6">
              <Button
                type="primary"
                className="mr5"
                loading={btnloading}
                onClick={() => searchonboardingreport("reset")}
              >
                Search
              </Button>
              <Button
                type="default"
                style={{ marginLeft: "10px" }}
                onClick={() => clearSearch()}
              >
                Clear
              </Button>
            </div>
          </div>
          <div className="card-body">
            <Table
              columns={columns}
              rowKey={(record) => record.key}
              dataSource={data}
              pagination={tableParams.pagination}
              loading={loading}
              onChange={handleTableChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default SalesByMarkets;
